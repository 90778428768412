.host {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
}

.main {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    background-color: transparent;


}

.headerTopBarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;

    p {
        font-family: var(--app-text-main-font), serif;
        color: var(--app-color-gray-dark);
    }

}

.copyright {
    margin-top: 0px;
    text-align: center;
    font-family: 'Montserrat', serif;
    font-size: 12px;
    color: var(--app-color-gray-light);
}