@use "../../../../../styles//tools//truncate.scss" as textTransf;

.host {
  display: flex;
  justify-content: center;
  align-items: center;
  width: inherit;
  
}

.target {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 10% 0 10%;
  width: 500px;
}

.startContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.placeholderContainer {
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardContainer {
  height: var(--app-step-container-height);
  width: var(--app-step-container-width);
  border-radius: 8px;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 0;

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    height: var(--app-after-height);
  }

  &[app-card-type="start"] {
    margin-bottom: var(--app-after-height);
    font-family: var(--app-text-main-font);
    font-variation-settings: "wght" 200;

    &::before {
      --app-mark-radius: 16px;
      --app-mark-fill-radius: 8px;
      display: block;
      content: " ";
      background-color: var(--app-color-gray-light);
      width: var(--app-mark-radius);
      height: var(--app-mark-radius);
      border-radius: var(--app-mark-radius);
      border: var(--app-mark-fill-radius) solid #f1f1f1;

      position: absolute;
      top: calc(100% - var(--app-mark-radius));
      left: calc(50% - var(--app-mark-fill-radius));
      bottom: calc(-1 * var(--app-mark-fill-radius));
    }

    &::after {
      display: block;
      content: " ";
      background-image: url("./annotations/verticalDownArrow.svg");
      background-repeat: no-repeat;
      background-position: calc(50% + 8px);
      background-size: 26px 56px;
      position: absolute;
      top: 100%;
      bottom: -100%;
    }
  }

  &:focus-within,
  &:hover {
    font-variation-settings: "wght" 900;
    transition: font-variation-settings 250ms ease;
  }
}

.svg {
  width: 24px;
  height: 24px;
  margin: 0px 0px 7px 10px;
}

.paragraph {
  @include textTransf.textEllipsed(3);
  margin: auto 0;
  //color: var(--app-color-gray-dark);
  text-align: center;
  padding: 0 10px 0 10px;
  //font-variation-settings: "wght" 200;
  font-family: var(--app-text-main-font);
  font-size: 18px;
}

.firstStepContainer {
  margin: 2px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0 150px;
}

.rankedStepsContainer {
  --comp-border-right-offset: 50%;
  --comp-border-left-offset: 50%;
  --app-mark-radius: 16px;
  --comp-left-offset: calc(var(--comp-border-right-offset) + var(--app-mark-radius));
  --comp-right-offset: calc(var(--comp-border-right-offset) + var(--app-mark-radius));

  --comp-border-left-to-center-screen-length: 50px;
  --comp-border-right-to-center-screen-length: 50px;

  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0 150px;
  //padding: 0 10%;

  position: relative;

  &[app-is-last-rank="true"] {
    &::after {
      display: none;
    }
  }
}

.stepWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 15px 5px;

  .stepContainer[app-step-is-final="true"] {
    &::before {
      display: block;
      content: "";
      background-image: url("./annotations/finalStep.svg");
      background-repeat: no-repeat;
      background-position-x: left;
      background-position-y: bottom;
      background-size: 50px 50px;
      position: absolute;
      left: -17px;
      bottom: -33px;
      height: 39px;
      width: 20%;
      z-index: 1;
      transform: scale(0.8);
    }
  }

  &[app-item-position="left"][app-has-multiple-parents="true"] {
    &::after {
      display: block;
      content: "";
      background-image: url("./annotations/parentingLeft.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: top;
      background-size: 100% 100%;
      position: absolute;
      top: -25px;
      left: 0px;
      bottom: 90%;
      right: 0px;
      height: 25px;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      border-top: 3px solid var(--app-color-gray-light);
      top: -25px;
      left: calc(50% + 5px);
      right: -50px;
      z-index: 1;
      width: var(--comp-border-left-to-center-screen-length);
      height: 25px;
    }
  }

  &[app-item-position="center"][app-has-multiple-parents="true"] {
    &::after {
      display: block;
      content: "";
      background-image: url("./annotations/parentingCenter.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: top;
      background-size: 100% 100%;
      position: absolute;
      top: -25px;
      left: 0px;
      bottom: 90%;
      right: 0px;
      height: 25px;
      z-index: 1;
    }

    // &::before {
    //   content: "";
    //   position: absolute;
    //   border-top: 3px solid var(--app-color-gray-light);
    //   top: -25px;
    //   z-index: 1;
    //   width: 100%;
    //   height: 25px;
    // }
  }

  &[app-item-position="right"][app-has-multiple-parents="true"] {
    &::after {
      display: block;
      content: "";
      background-image: url("./annotations/parentingRight.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: top;
      background-size: 100% 100%;
      position: absolute;
      top: -25px;
      left: 0px;
      bottom: 90%;
      right: 0px;
      height: 25px;
      z-index: 1;
    }

    &::before {
      content: "";
      position: absolute;
      border-top: 3px solid var(--app-color-gray-light);
      top: -25px;
      right: calc(50% + 5px);
      left: -50px;
      z-index: 1;
      // width: var(--comp-border-right-offset);
      height: 25px;
    }
  }

  &[app-item-position="left"]:not([app-has-multiple-parents="true"]) {
    &::after {
      display: block;
      content: "";
      background-image: url("./annotations/itemLeft.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: top;
      background-size: 100% 100%;
      position: absolute;
      top: -25px;
      left: 0px;
      bottom: 90%;
      right: 0px;
      height: 25px;
    }

    &::before {
      content: "";
      position: absolute;
      border-top: 3px solid var(--app-color-gray-light);
      top: -25px;
      left: calc(50% + 5px);
      right: -50px;
      z-index: 1;
      width: var(--comp-border-left-to-center-screen-length);
      height: 25px;
    }
  }

  &[app-item-position="right"]:not([app-has-multiple-parents="true"]) {
    &::after {
      display: block;
      content: "";
      background-image: url("./annotations/itemRight.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: top;
      background-size: 100% 100%;
      position: absolute;
      top: -25px;
      left: 0px;
      bottom: 90%;
      right: 0px;
      height: 25px;
    }

    &::before {
      content: "";
      position: absolute;
      border-top: 3px solid var(--app-color-gray-light);
      top: -25px;
      right: calc(50% + 5px);
      left: -100px;
      z-index: 1;
      //width: var(--comp-border-right-to-center-screen-length);
      height: 25px;
    }
  }

  &[app-item-position="center"]:not([app-has-multiple-parents="true"]) {
    &::after {
      display: block;
      content: "";
      background-image: url("./annotations/itemCenter.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: top;
      //background-position: calc(50% + 8px);
      background-size: 100% 100%;
      position: absolute;
      top: -25px;
      left: 0px;
      bottom: 90%;
      right: 0px;
      height: 25px;
    }
  }
}

.stepContainer {
  cursor: pointer;
  z-index: 1;
  position: relative;
  margin-top: 0px;
  height: var(--app-step-container-height);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px 0 #0000001a;
  padding: 12px;
  gap: 5px;
  font-family: var(--app-text-main-font), serif;
  min-width: 200px;

  &[app-annotation="toLeft"][app-descendants="true"] {
    &::after {
      display: block;
      content: "";
      background-image: url("./annotations/circleLeft.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: top;
      background-size: 100% 100%;

      position: absolute;
      left: 0px;
      bottom: -28.5px;
      right: calc(-4% - 8px);
      height: 50px;
    }

    &::before {
      content: "";
      position: absolute;
      border-bottom: 3px solid var(--app-color-gray-light);
      bottom: -27.5px;
      right: 50%;
      z-index: 20;
      width: var(--comp-border-right-to-center-screen-length);
      height: 5px;
    }
  }

  &[app-annotation="toRight"] {
    &::after {
      display: block;
      content: "";
      background-image: url("./annotations/circleRight.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: top;
      background-size: 100% 100%;

      position: absolute;
      left: 0px;
      bottom: -28.5px;
      right: calc(-4% - 8px);
      height: 50px;
    }

    &::before {
      content: "";
      position: absolute;
      border-bottom: 3px solid var(--app-color-gray-light);
      bottom: -27.5px;
      left: calc(50% + 25px);
      z-index: 20;
      width: var(--comp-border-left-to-center-screen-length);
      height: 5px;
    }
  }

  &[app-annotation="toRight"][app-next-step-is-final-in-row='true'] {
    &::after {
      display: block;
      content: "";
      background-image: url("./annotations/circleRight.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: top;
      background-size: 100% 100%;

      position: absolute;
      left: 0px;
      bottom: -28.5px;
      right: calc(-4% - 8px);
      height: 50px;
    }

    &::before {
      content: "";
      position: absolute;
      border-bottom: 3px solid var(--app-color-gray-light);
      bottom: -27.5px;
      left: calc(50% + 25px);
      z-index: 20;
      width: 90%;
      height: 5px;
    }
  }

  &[app-annotation="inCenter"] {
    &::after {
      display: block;
      content: "";
      background-image: url("./annotations/circleCenter.svg");
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: top;
      background-size: 100% 100%;

      position: absolute;
      left: 0px;
      bottom: -28.5px;
      right: 0px; //calc(-4% - 8px);
      height: 50px;
    }
  }

  &[app-next-step-is-final="true"] {
    &::before {
      display: none;
    }
  }

  .cardContainer [app-card-type="start"] {
    font-family: var(--app-text-main-font);
  }

  .titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
  }

  .durationContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;

    .durationText {
      margin: auto 0 auto 0;
      color: #4a4a4a;
      font-size: 1.05em;
      text-align: center;
      padding: 0;
    }
  }

  &:hover {
    font-family: var(--app-text-main-font);
    font-variation-settings: "wght" 900;
    transition: font-variation-settings 250ms ease;

    .image {
      stroke-width: 3px;
    }
  }
}

.image {
  width: 12px;
  height: 12px;
  margin: 0px 0px 0px 0px;
}

.bottomAnnotation {
  height: 20px;
  background-color: yellow;
  width: 100%;
}

.tree {
  padding: 5px;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid red;
  //border:0;
  cursor: default;
  font-family: var(--app-text-main-font);
  color: var(--app-color-gray-dark);
  font-weight: bold;
  //font-size: 18px;
  p {
    font-family: var(--app-text-main-font);
    font-size: 12px;
    text-align: center;
    color: var(--app-color-gray-dark);

}
}

.step {
  cursor: pointer;
  padding: 5px;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid green;
}