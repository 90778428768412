.multiselectWithSearch {
    width: 100%;
    margin: 10px 0;
    border-radius: 4px;
    margin-top: 2px;
}
.smoMultiselectParent {
    max-height: 300px;
    top: 100%;
    width: 100%;
    overflow: auto;
    margin-top: 2px;
}
.selectMenuItem {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    
}
.smoMultiselectDuration {
    max-height: 200px;
    max-width: 100%;
    overflow: auto;
}
.smoMultiselectPathway {
    max-height: 200px;
    top: 100%;
    width: 100%;
    overflow: auto;
    margin-top: 2px;
}
.inputClassName {
    margin: 0;
    margin-left: 10px;
    font-family: var(--app-text-main-font);
    font-size: 1.2em;
    line-height: 20px;
    color: var(--app-color-gray-light);
    border: none;
    outline: none;
    align-self: center;
    padding: 0;
    width: 100%;
    text-align: left;
}
.smoMultiselectInputContainerPathways{
    flex:1
}
.leftIconItemContainerClassName{
    background-color: transparent;
    img{
        color:var(--app-color-gray-dark) ;
        filter: invert(53%) sepia(21%) saturate(66%) hue-rotate(198deg) brightness(76%) contrast(58%);
    }
   
}

.renderNewComponentSelectWithSearchInput {
    padding-left: 10px;
}
.renderNewComponentSelectWithSearchMOL {
    width: 100%;
    margin-top: 2px;
}
.renderNewComponentSelectWithSearchMOLI {
    padding-left: 10px;
}

.renderNewComponentSelectWithSearchContainer {
    outline: none;
    width: 100%;
    border: 0;
}
.renderNewComponentSelectWithSearchInput {
    padding-left: 10px;
}
.renderNewComponentSelectWithSearchMOL {
    width: 100%;
    margin-top: 2px;
}
.renderNewComponentSelectWithSearchMOLI {
    padding-left: 10px;
}
.elementTextInput {
    height: 57px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    width: 100%;
    padding-left: 20px;
    outline: none;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-dark);
}

.peoplePositionsSelect {
    border: 1px solid var(--app-color-gray-lighter);     
    position: relative;
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 10px 0 10px;
    font-family: var(--app-text-main-font);
}
.peoplePositionsSelectInput {
    pointer-events: none;
    font-family: var(--app-text-main-font);
    
}
.peoplePositionsSelectMenuOverlay {
    display: flex;
    flex-direction: column;
    margin: 5px auto;
    max-height: 250px;
    width: auto;
    min-width: 100%;
    top: 100%;
}
.peoplePositionsSelectMenuOverlayItem {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-left: 40px;
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
}


