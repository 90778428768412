.host {
    position: relative;
    display: flex;
    flex-direction: column;
}
.inputAndTagsWrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    &[app-tag-emplacement="top"] {
        flex-direction: column;
    }
    &[app-tag-emplacement="bottom"] {
        flex-direction: column-reverse;
    }
}
.itemLeftIcon {
    margin-left: 10px;
    margin-right: 5px;
    height: 17px;
}
.toggleMenu {
    width: 50px;
}
.inputContainer {
    &[app-renedered-elements="toggle-only"] {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: initial;
    }
}
