.tile-video {
  position: relative;
  width: 480px;
  height: 270px;
}

@media only screen and (max-width: 600px) {
  .tile-video {
    width: 240px;
    height: 135px;
  }
}

.self-view {
  width: 480px;
  height: 270px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .self-view {
    width: 240px;
    height: 135px;
  }
}

.no-video {
  background-color: var(--dark-blue-border);
}

.no-video video {
  visibility: hidden;
}

/* Hard-code the self-view size if there's no one else in the call, just to make it align with the info-box */
.self-view.alone {
  width: 480px;
  height: 270px;
}

@media only screen and (max-width: 600px) {
  .self-view.alone {
    width: 240px;
    height: 135px;
  }
}

/* Put screen share front and center in our grid when there is one active */
.tile-screenshare {
  grid-column: 1 / -1;
  grid-row: 1;
}

.containerCssClasses {
  height: 80vh; 
  width: 50%;
}

.containerCssClassesMobile {
  height: 40vh; 
  width: 100%;
}
.alone {
  height: 40vh;
}