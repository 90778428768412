/* checkbox  */
@import "../../../../../styles//breakpoints.scss";
.host {
  --comp-checkbox-border-color: var(--app-color-gray-light);
  --comp-checkbox-background-color: var(--app-color-white-lightest);
  --comp-checkbox-disabled-border-color: var(--app-color-gray-lightest);
  --comp-checkbox-disabled-background-color: var(--app-color-gray-lightest);
  --comp-checkbox-checked-background-color: var(--app-color-gray-dark);
  --comp-checkbox-checked-stroke: var(--app-color-white-lightest);
  --comp-checkbox-text-color: var(--app-color-gray-dark);
  --comp-checkbox-disabled-text-color: var(--app-color-gray-light);

  display: grid;
  grid-template-columns: min-content auto;
  column-gap: 10px;
  width: auto;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
}
.checkmarkContainer {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
}
.input {
  opacity: 0;
  position: absolute;
  width: 0px;
  height: 0px;  
}
.checkboxControl {
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  .input + & {
    border: 1px solid var(--comp-checkbox-border-color);
    border-radius: 2px;
    background: var(--comp-checkbox-background-color);
  }
  /* error attribute is above input.checked because is not clear
   if should be red bordered even on checked */
  .host[app-checkbox-error="error"] & {
    border: 3px solid var(--app-color-red-error);
    border-radius: 2px;
  }
  .input:checked + & {
    border: 1px solid var(--comp-checkbox-checked-background-color);
    border-radius: 2px;
    background: var(--comp-checkbox-checked-background-color);
  }
  .input[disabled] + & {
    cursor: default;
    border: 1px solid var(--comp-checkbox-disabled-border-color);
    border-radius: 2px;
    background: var(--comp-checkbox-disabled-background-color);
  }
  .input[disabled]:checked + & {
    cursor: default;
    border: 1px solid var(--comp-checkbox-disabled-border-color);
    border-radius: 2px;
    background: var(--comp-checkbox-disabled-background-color);
  }
  .host:focus-within & {
    box-shadow: 0px 0px 5px var(--app-color-text-tertiary);
    outline: none;
  }
}
.checkboxControlIcon {
  width: 14px;
  margin: auto;
  display: none;
  .input:checked + span & {
    display: block;
   // stroke: var(--comp-checkbox-checked-stroke);
    stroke-width: 1px;
    color:var(--comp-checkbox-checked-stroke)
  }
  .input[disabled]:checked + span & {
    display: block;
  //  stroke: var(--app-checkbox-disabled-text-color);
    stroke-width: 2px;
    fill:var(--app-checkbox-disabled-text-color)
  }
}

.checkboxLabel {
  place-self: start;
  font-size: 15px;
  color: var(--comp-checkbox-text-color);
  cursor: pointer;

  font-family: var(--app-text-main-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  padding-top: 0px;
  color: var(--app-color-gray-light);

  .checkboxDisabled & {
    cursor: default;
    color: var(--comp-checkbox-disabled-text-color);
  }
  @media (app-viewport-small) {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

/* checkbox container */
.container {
  position: relative;
}
.list {
  margin-top: 0.25rem;
}
.listItem {
  margin-top: 0.25rem;
}
