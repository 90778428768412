/* .App {
  font-family: sans-serif;
  text-align: center;
} */
html,


.dndflow {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: inherit;
  height: 700px;
}

.reactflow-wrapper {
  flex-grow: 1;
  
}

.dndflow aside {
  background: #fff;
  border-left: 1px solid #eee;
  font-size: 12px;
  padding: 10px;
  max-width: 300px;
}
.right-side-bar {
  max-width: 250px;
  width: 20%;
}

.dndflow .dndnode,
.dndflow aside .description {
  margin-bottom: 0;
}
.dndflow aside .title {
  margin: 0;
  font-size: 24px;
  text-align: left;
}

.dndflow aside .subtitle {
  margin-bottom: 100px;
  font-size: 12px;
  text-align: left;
}
.dndflow .dndnode {
  align-items: left;
  border: 1px solid #1a192b;
  border-radius: 2px;
  cursor: grab;
  display: flex;
  height: 20px;
  justify-content: center;
  padding: 4px;
}

.dndflow .dndnode.input {
  border-color: #0041d0;
  height: 30px;
}

.dndflow .dndnode.output {
  border-color: #ff0072;
}
