.firstDiv_T {
    position: relative;
    display: flex;
    height: 96%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 2% 10% 2% 10%;
}
.centered_T {
    display: flex;
    justify-content: center;
    align-items: center;
}
.header_T {
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 2em;
    font-weight: 500;
    margin: 0;
    padding: 0 0 0 10px;
    text-align: center;
}
.contentContainer_T {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 2ex 0 0 0;
    gap: 2ex;
}
.tutorialVideoContainerStyle_T {
    // display: flex;
    // flex: 1;

    //  justify-content: center;
    // align-items: stretch;
    //aspect-ratio: 1/1;
    //  max-height: 200px;
    width: 100%;
    height: 50%;
}
.reactPlayerStyle_T {
    border-radius: 16px;
    overflow: hidden;
}
.tutorialTextContainerStyle_T {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.formContainer_T {
    margin: 5px 0 0px 0;
    position: relative;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-evenly;
    align-items: stretch;
}
.textAreaStyle_T {
    box-sizing: border-box;
    //height: 113px;
    width: 100%;
    border: 2px solid var(--app-color-gray-lighter);
    border-radius: 8px;
    background-color: #ffffff;
    color: var(--app-color-gray-light);
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    line-height: 1.3rem;
    resize: none;
    position: relative;
    padding: 10px 10px 10px 10px;
    outline: none;
}
.textAreaStyle_T:focus {
    border: 3px solid var(--app-color-gray-lighter);
}
.textAreaPlacehorder_T {
    position: absolute;
    top: 10%;
    left: 20px;
    right: 0;
    user-select: none;
    pointer-events: none;
    display: block;
    color: var(--app-color-gray-light);
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    line-height: 1.3rem;
}
.textAreaErrorMessage_T {
    margin: 10px 0 0 0;
    text-align: center;
    vertical-align: middle;
    color: var(--app-color-error);
    font-family: var(--app-text-main-font);
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 0.8rem;
}
