.tutorialRectangle{
  z-index: 500;
  cursor: grab;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #4a4a4a75;
  display: flex;
  justify-content: center;
  align-items: center;
}