.host {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: var(--app-text-main-font);
  margin: 10px 10px 10px 0px;
  height: 40px;
  overflow: hidden;
  color: var(--app-color-white-lightest);
  background-color: var(--app-color-gray-dark);
  &[comp-variant="light"] {
    border: 1px solid var(--app-color-gray-lighter);
    background-color: var(--app-color-white-lightest);
    color: var(--app-color-gray-dark);
    stroke: var(--app-color-gray-dark);
  }
  &[comp-variant="clear"] {
    border: 0;
    background-color: var(--app-color-white-lightest);
    color: var(--app-color-gray-dark);
    stroke: var(--app-color-gray-dark);
  }
  &[comp-state="disabled"] {
    background-color: rgb(232, 228, 228);
    border: 0;
  }
}

.loaderContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 32px;
  max-height: 80%;
  margin: 10px 0px 0 5px;
  .host[comp-loading="loading"] & {
    transform: translateX(-5px);
  }
}
.target {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 32px;
    height: 32px;
  }
}
.titleContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  //max-height: 80%;
  height: 30px;
  margin: 0 10px 0 10px;
  p {
    height: 20px;
    font-family: var(--app-text-main-font);
    font-size: 16px;
    font-weight:700;
    letter-spacing: 0;
    line-height: 20px;
  }
}
.iconContainer {
  // .host[comp-loading='loading'] & {
  //   display: none;
  // }
  height: 100%; 
  margin: 0;
  padding: 0;
  display: flex;
  .host[comp-variant="light"] {
    background-color: var(--app-color-white-lightest);
    color: var(--app-color-gray-dark);
    stroke: var(--app-color-gray-dark);
  }
}


.center {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: var(--app-text-main-font);
  font-size: 16px;
}
