.tableContainer {
  width: 100%;
}

.tableHostSimple {
  width: 100%;
  table {
    width: inherit;
    border-spacing: 1;
     border-left: 1px dashed #bbb;
     //border-right: 1px dashed #bbb;
    font-size: 16px;
    font-weight: 700;

    tr {
      // :first-child {
      //   height: 50px;
      // }
      :last-child {
    //    border-right: 0;
       // border-bottom: 1px dashed #bbb;
      }
    }

    th {
      padding: 10px 0 10px 10px;
      color: white;
      background-color: rgb(83, 159, 234);
      background-color: var(--app-color-gray-dark);
      border: 1px dashed #bbb;
    }

    td {
      text-align: left;
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px dashed #bbb;
      border-right: 1px dashed #bbb;
      
    }
  }
  table tr:hover .action {
    transform: scale(1.3);
  }

  table tr:hover td {
    background: rgb(83, 159, 234);
    color: #fbfbfb;
  }
}

.tableHost {
  table {
    width: 100%;
    border-spacing: 0;
    border-top: 1px dashed #bbb;
    border-left: 1px dashed #bbb;
    border-right: 1px dashed #bbb;   
    font-family: var(--app-text-main-font);

    color: black;
    font-weight: 700;

    tr {
      :first-child {
        height: 50px;
      }

      :last-child {
        border-right: 0;
      }
    }

    th {
      :last-child {
        padding: 0 0 0 10px;
        text-align: left;
        min-height: 30px;
        //min-width: 100px;
        color: white;
        background-color: var(--app-color-gray-dark);
        border-right: 1px solid #bbb;
        
      }
    }

    td {
      text-align: left;
      margin: 0;
      padding: 0.5rem;
      border-right: 1px dashed #bbb;
      border-right: 1px dashed #bbb;
    }
  }

  table tr:hover .action {
    transform: scale(1.3);
  }

  table tr:hover td {
    background: red;
    color: #fbfbfb;
  }
}

.tableReport {
  table {
    width: 100%;
    border-spacing: 0;
    font-family: var(--app-text-main-font) sans-serif;

    color: black;
    font-size: 16px;
    font-weight: 700;

    tr {
      :first-child {
        height: 50px;
      }

      :last-child {
        border-right: 0;
      }
    }

    th {
      :last-child {
        padding: 0 0 0 10px;
        text-align: left;
        min-height: 30px;
        height: 60px;
        //min-width: 100px;
        color: white;
        background-color: var(--app-color-gray-dark);
        border-right: 1px solid #bbb;
      }

    }

    td {
      text-align: left;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px dashed var(--app-color-gray-lighter);
      border-right: 1px dashed var(--app-color-gray-lighter);

    }
  }

  
}
.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.listContainer {
  cursor: pointer;

  &:hover {
    background-color: var(--app-color-blue);
    color: #fbfbfb;

    hr {
      color: var(--app-color-blue);
    }
  }

}
.footer {
  //min-width: 50px;
  text-align: left;
}