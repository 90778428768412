body {
    padding: 0;
    margin: 0;
  }
  
  .App {
    height: 100vh;
    width: 100%;
  }
  
  .circle {
    background: #d83b01;
    border-radius: 50%;
    color: #fff;
    height: 2.5em;
    position: relative;
    width: 2.5em;
  
    border: 1px solid transparent;
  }
  
  .circleText {
    text-align: center;
    height: 50%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }

  .fieldTypeInput {
    ::placeholder {
      font-family: var(--app-text-main-font), serif;
      font-size: 16px;
      font-weight: 700;
      color: var(--app-color-error);
    }
  
    width: 96%;
    border: 0.5px dashed var(--app-color-gray-dark);
    
    font-family: var(--app-text-main-font),
    serif;
    font-size: 16px;
    color: var(--app-color-gray-dark);
    width: 800px;
    padding: 10px;
  }
  
  .selectText {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
  }




 
.inputField {
  font-family: var(--app-text-main-font);
  font-size: 16px;
  color: var(--app-color-gray-dark);
  //border-radius: 2px;
  border: 1px solid var(--app-color-gray-lighter);
  width: 100%;
  outline: none;
  height: 56px;
  margin-top: 5px;
  margin-left: 0;
  padding: 0 10px 0 10px;
}

.industryMOLI {
  padding: 5px;
  //border-radius: 5px;
}

.multiselectComponent {
  border: 1px solid var(--app-color-gray-lighter);
  display: flex;
  margin-top: 10px;
  //border-radius: 4px;
  width: 100%;
  height: 56px;
  
}

.dropdownClassContainer {
  height: 50px;
  border: 1px solid var(--app-color-gray-lighter);
  display: flex;
  margin-top: 10px;
  margin-left: 0px;
  border-radius: 4px;
  width: 290px;
  color: transparent;
}
