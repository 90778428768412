.host {
  position: relative;
  display: flex;
  align-items: center;
  box-shadow: none;
  font-family: var(--app-text-main-font);
  font-size: 1.1em;
  color: var(--app-color-gray-light);
  outline: none;
  text-align: left;
  margin: 0px 0 0 10px;
  padding: 0;
  height: 49px;
  max-width: 100%;
  width: 100%;
  min-width: clamp(10%, 150px, 100%);

  &[app-variant-has-error="error"] {
    border: 1px solid var(--app-color-error);
  }
}

.toggleMenu {
  outline: none;
  border: none;
  margin: 0px 5px 0px 5px;
  font-family: var(--app-text-main-font);
  font-size: 16px;
  color: var(--app-color-gray-dark);
  align-self: center;
  padding: 0;
  width: 100%;
  text-align: left;

  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  vertical-align: middle;
  text-align: center;
  height: 49px;
}

.toggleMenuControlsIcons {
  display: inline-block;
  margin-left: 10px;
  width: 12px;
  height: 12px;
  stroke-width: 0;
  color: var(--app-color-gray-light)
}

.toggleLeftIconContainer {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggleLeftIcon {
  width: 20px;
  height: 20px;
}

.menuOverlayList {
  background-color: #ffffff;
  position: absolute;
  z-index: 1;
  top: 32px;
  list-style: none;
  padding-left: 0;
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.07);
  border-radius: 0;
}

.menuOverlayListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px 5px 10px;
  width: auto;
  color: var(--app-color-gray-dark);
  background-color: transparent;
  // border-radius: 10px;
  &:hover {
    background-color: var(--app-color-gray-lighter);
    color: var(--app-color-gray-light);
  }

  &:focus {
    background-color: var(--app-color-gray-dark);
    color: var(--app-color-gray-light);
  }
}

.itemContainer {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.itemIcon {
  display: inline-block;
  padding: 10px 10px 0px 10px;
}

