.firstDiv_I {
    position: relative;
    display: flex;
    height: 96%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 2% 10% 2% 10%;
}
.logoImg_I {
    position: absolute;
    left: -60px;
    top: 1.5%;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
}
.headerContainer_I {
    margin: 0px 0 0px 0;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: stretch;
}
.header_I {
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 2em;
    //  line-height: 2em;
    margin: 0;
    padding: 0 0 0 10px;
    text-align: left;
}
.subheadingContainer_I {
    align-self: stretch;
    font-family: var(--app-text-main-font);
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 16px;
    padding-left: 10px;
    justify-content: center;
    align-items: center;

    .padH50_I {
        padding: 0 50px 0 50px !important;
    }
    .centered_I {
        text-align: "center";
        justify-content: center;
        align-items: center;
    }

    p {
        margin: 0px 0 16px 0;
        color: var(--app-color-gray-light);
        font-family: var(--app-text-main-font);
        font-size: 1rem;
        line-height: 1.3rem;
        text-align: left;
        padding: 0 0 0 0;
    }
}
.justifiedLeft_I {
    text-align: left;
    justify-content: flex-start;
    align-items: center;
}
.videoContainer_I {
    display: block;
    flex: 1;
    width: 100%;
    height: 100%;
    min-height: 200px;
    position: relative;
    aspect-ratio: 16 / 9;
}
.reactPlayerStyle_I {
    border-radius: 16px;
    overflow: hidden;
}
.checkboxContainer_I {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 1ex 0 1ex 0;
    min-height: 50px;
}
.checkboxOnboarding_I {
    display: block;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    margin: 0.75ex 0 0.75ex 0;
}
.checkboxOnboarding_I [data-reach-custom-checkbox-container][data-state="checked"]:after {
    display: flex;
    width: calc(45% - 4px);
    height: calc(90% - 6px);
    border: solid #fff;
    border-width: 0 0.25em 0.25em 0;
    -webkit-transform: translateY(-65%) translateX(-50%) rotate(45deg);
    transform: translateY(-65%) translateX(-50%) rotate(45deg);
}
.checkboxLabelOnboarding_I {
    flex-shrink: 1;
    flex-grow: 1;
    cursor: pointer;
    word-wrap: break-word;
    max-width: 100%;
    width: 100%;
    color: var(--app-color-gray-light);
    font-family: var(--app-text-main-font);
    font-size: 1.1em;
    font-weight: 500;
    letter-spacing: 0;
    margin: 0;
    padding: 0;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap:1em;
}
.checkboxChild_I {
    align-self: center;
    color: var(--app-color-gray-dark);
    font-size: 16px;
    padding: 0;
    margin: 0;
}
.buttonWrapper_I {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.buttonContainer_I {
    position: relative;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 30px;
}

.tutorialStyle_I {
    z-index: 500;
    cursor: grab;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #4a4a4a75;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
