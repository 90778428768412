@mixin extendWith($class) {
    @extend .#{$class};
}

.host {
    width: 100%;
    height: 100%;
    padding: 0 10%;
    margin: 0;
    padding-top: 0px;
}
.target {
    @extend .flex-row;
    @include extendWith(flex-row);
    @include extendWith(flex-between);
    background: var(--app-color-white-lightest);
    box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.1);
    height: 100vh;
    padding-top: 30px;
    .column {
        flex: 1.5;
        align-self: flex-start;
        position: relative;
        .columnHeader {
            @include extendWith(flex-row);
            align-self: flex-start;
            .backArCont {
                @include extendWith(flex-centered);
                position: relative;
                height: 45px;
                aspect-ratio: 1/1;

                .backAr {
                    cursor: pointer;
                    height: 100%;
                    text-align: center;
                    margin: auto;
                }
            }
            .titleCont {
                margin: auto;
                align-self: stretch;
                text-align: center;
                width: 100%;
                .title {
                    color: var(--app-color-gray-dark);
                    font-family: var(--app-text-main-font);
                    font-size: 2em;
                    margin: 0;
                    padding: 0;
                }
            }
        }
        .optionContainer {
            @include extendWith(flex-row);
            position: absolute;
            top: 150%;
            right: 35%;
            justify-content: center;
            gap: 10px;
            align-self: center;

            cursor: pointer;
            font-family: var(--app-text-main-font);
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            svg {
                width: 16px;
                height: 16px;
            }
        }
    }
    .content {
        flex: 4;
        align-self: flex-start; 
        padding-right: 20px;
        
    }
}

.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.flex-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flex-around {
    justify-content: space-around;
    align-items: center;
}
.flex-between {
    justify-content: space-between;
    align-items: center;
}
.flex-row-between {
    @include extendWith(flex-row);
    @include extendWith(flex-between);
}
.errorContainer {
    display: inline-block;  
    font-family: var(--app-text-main-font);
    font-size: 12px;
    color: var(--app-color-red-error);
    white-space: pre-line;
    word-break: break-all;
    align-self: center;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 10px 0;
}