.tutorialWrapper_XI {
    position: absolute;
    top: 1.5em;
    right: -68.5px;
    height: 62px;
    width: 137px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}
.tutorialContainer_XI {
    margin: 12px;
    cursor: pointer;
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.tutorialImg_XI {
    width: 11.58px;
    height: 15.98px;
}
.tutorialTextContainer_XI {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.tutorialText_XI {
    margin: 0 0 0 0;
    height: 24px;
    width: 61px;
    color: var(--app-color-gray-dark);
    font-family:  var(--app-text-main-font);
    font-size: 14px;
    line-height: 1.3rem;
    text-align: center;
}
.tutorialDuration_XI {
    margin: 0 0 0 0;
    height: 24px;
    width: 61px;
    color: var(--app-color-gray-dark);
    font-family:  var(--app-text-main-font);
    font-size: 14px;
    line-height: 1rem;
    text-align: center;
}
.subheading_VII {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    line-height: 24px;
    color: var(--app-color-gray-light);    
}
.deleteModalContainer {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 2% 7% 2% 7%;
}
.deletePositionContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.deletePosition_title {
    height: 40px;
    width: 217px;
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
}
.deletePosition_description {
    align-self: flex-start;
    height: 20px;
    width: 252px;
    color: var(--app-color-gray-light);
    font-family: var(--app-text-main-font);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
}
.deletePosition_checkboxContainer {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0 10px 0;
}
.deletePosition_buttonContainer {
    svg {
        margin-left: 10px;
        padding: 1px 1px;
        width: 24px;
        height: 24px;
        color: var(--app-color-white-lighter);
    }
}


.warningDeletionContainer {
    font-family: var(--app-text-main-font);
    font-size: 16px;
}

.peoplePositionsSelect {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.peoplePositionsSelectInput {
    padding: 0 0 0 20px;
    font-size: 20px;
    color: var(--app-color-gray-dark);
}

.peoplePositionsSelectMenuOverlay {
    display: flex;
    flex-direction: column;
    margin: 5px auto;
    max-height: 250px;
    width: auto;
    min-width: 100%;
    top: 100%;
}

.peoplePositionsSelectMenuOverlayItem {
    display: flex;

    align-items: center;
    padding: 10px;
    padding-left: 40px;
    font-family: var(--app-text-main-font);
    font-size: 20px;
    color: var(--app-color-gray-dark);
}

