@mixin truncateOneLine($limit: auto, $display: block) {
  max-width: $limit;
  display: $display;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin textEllipsed($lines:3){
  overflow: hidden;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;  
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}