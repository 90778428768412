@mixin responsive-box($height) {
  position: relative;
  &:before {
    content: "";
    display: block;
    padding-top: $height;
  }
}

.checkbox {
  div {    
    align-self: stretch;
    display: flex;
    flex-direction: row;
    width: 100%; //100%;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0px;
  }
  input {
    @include responsive-box(100%);
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  p {
    padding: 3px 0 0 15px;
  }
}
.commitText {
  font-family: var(--app-text-main-font);
  font-size: 16px;
  //font-weight: 700;
  text-align: left;
  input[type="checkbox"]:not(:checked) {
    border-radius: 5px;
    background-color: var(--app-color-error);
  }
  input[type="checkbox"] + .checkbox-label {
    color: blue;
  }
  input[type="checkbox"]:checked + .checkbox-label {
    color: var(--app-color-error);
  }
  input[type="checkbox"]:checked + .checkbox-label:before {
    content: "";
    border: 1px solid #a3adb3;
  }
}
