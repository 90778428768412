
  
  /* Hamburger Icon */
  .mobile-nav {
    display: block;
  }
  
  .hamb-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  /* Mobile Navbar */
  .mobile-navbar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 200%;
    background-color: white;
    z-index: 20;
    justify-content: center;
    text-align: center;
    align-items: center;
    transition: left .5s ease-in-out;
  }
  
  .mobile-navbar-close {
    position: absolute;
    top: 28px;
    right: 28px;
  }
  
  .mobile-navbar-close .hamb-icon:hover {
    color: rgb(255, 22, 22);
  }
  
  .open-nav {
    left: 0;
  }
  .opennav {
    left: 120px;
}

  
  .mobile-navbar-links {
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    text-align: left;
  }
  
  .mobile-navbar-links li a {
    text-decoration: none;
    color: black;
    font-family: var(--app-text-mobile-font), serif;
    font-weight: light;
    font-size: 18px;
    transition: color .3s ease-in-out;
  }
  
  .mobile-navbar-links li a:hover {
    color: #0cc2ea;
    cursor: pointer;
  }
  
  /* Responsive */
  @media screen and (max-width: 900px) {
  
    .navbar-btn,
    .navbar-items {
      display: block;
    }
  
    .mobile-nav {
      display: block;
    }
  }