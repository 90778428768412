.userCard {
    cursor: pointer;
    width: 260px;
    height: 300px;
    background-color: var(--app-color-white-lightest);
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  }

  .userCardNoPhoto {
    cursor: pointer;
    width: 220px;
    height: 220px;
    background-color: var(--app-color-white-lightest);
    border-radius: 8px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  }
  .userCardContainer{
    height: 100%;
  }
  .cls.userCardContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  
  .userCardContainerTop {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  
  .userCardInnerContainer {
    margin-top: 60px;
    height: 80px;
    width: 80px;
    background-color: var(--app-color-gray-lighter);
    border-radius: 50%;
  }
  .avatarUrlImg{
    height: 80px;
    width: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
  .userCardBottomContainer {
    padding: 30px 0;
    width: 100%;
  }
  
  .firstName {
    margin-top: 5px;
    font-family: var(--app-text-main-font);
    font-size: 1.5rem;
    color: var(--app-color-gray-dark);
    text-align: center;
  }
  .fullName {
    font-family: var(--app-text-main-font);
    font-size: 1.5rem;
    color: var(--app-color-gray-dark);
    text-align: center;
  }
  
  .position {
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-light);
    text-align: center;
    margin-top: -10px;
  }

  .email {
    font-family: var(--app-text-main-font);
    font-size: 12px;
    color: var(--app-color-gray-light);
    text-align: center;
    //margin-top: -20px;
    padding: 0 10px 0 10px;
  }

  .simple {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: var(--app-color-white-lightest);
    border-radius: 8px;
    
  }

  .simpleImg{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit:scale-down;
  }

  .simpleNames{
    //margin-left: 20px;
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
    text-align: left;
  }

  .simpleEmail{
    //margin-left: 20px;
    font-family: var(--app-text-main-font);
    font-size: 12px;
    color: var(--app-color-gray-dark);
    text-align: left;
  }

  .title{
    margin-left: 20px;
    font-family: var(--app-text-main-font);
    font-size: 8px;
    color: var(--app-color-gray-dark);
    text-align: left;
  }