.host {
    position: relative;
    display: flex;
    flex-direction: column;
}
.tiles {
    position: relative;
    display: flex;
    flex-direction: row;
}
.customLabel {
    font-size: 12px;
}
.itemLeftIcon {
    margin-left: 10px;
    margin-right: 5px;
    height: 17px;
}
.hostCustomTagDefault {
    display: flex;
    align-items: center;
    padding: 1px 5px;
    padding-left: 10px;
    font-family: var(--app-text-main-font);
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 5px;
    word-break: break-all;
}
.customTagCloseIcon {
    margin-left: 5px;
    width: 12px;
    height: 12px;
}
.customInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex: 1;
    height: 50px;
    &[app-state="active"] {
        outline: none;
    }
    &[app-state="error"] {
        border: 1px solid var(--app-color-red-error);
        border-radius: 4px;
    }
}
.customInput {
    &::placeholder {
        color: var(--app-color-gray-light);
    }
    outline: none;
    border: none;
    margin: 0;
    font-family: var(--app-text-main-font);
    font-size: 1em;
    line-height: 20px;
    color: var(--app-color-gray-light);
    align-self: center;
    padding: 0 5px;
    width: 100%;
    text-align: left;
    flex: 1;
    height: 50px;
}
.customInputI {
    &::placeholder {
        color: var(--app-color-gray-light);
    }
    
    font-family: var(--app-text-main-font);
    font-size: 1em;
    color: var(--app-color-gray-light);
    padding: 0 5px;
    
}
.customToggleMenuInputButton {
    outline: none;
    border: none;
    font-family: var(--app-text-main-font);
    font-size: 1.1em;
    color: var(--app-color-gray-light);
    align-self: center;
    padding: 0 10px;
    text-align: left;
    background-color: transparent;
}
.customToggleMenuInputButtonIcon {
    margin: 0 10px 0 8px;
    transform: rotate(0deg);
    transition: transform 0.6s ease;
    color: var(--app-color-gray-light);

    .customToggleMenuInputButton[app-variant="open"] & {
        transform: rotate(180deg);
        transition: transform 0.6s ease;
    }
    .customToggleMenuInputButton[app-icon="chevron"] & {
        // width:6px;
        height: 12px;
    }
    .customToggleMenuInputButton[app-icon="downArrow"] & {
        width: 10px;
        height: 11px;
    }
}
.customMenuOverlayList {
    position: absolute;
    box-shadow: 0px 0px 30px #0000001a;
    list-style: none;
    padding-left: 0;
    &:focus,
    &:focus-within {
        outline: none;
    }

    z-index: 1;
    overflow-y: auto;
    border-radius: 10px;
    background-color: var(--app-color-white-lightest);
    display: flex;
    flex-direction: column;
    width: auto;
    max-height: 250px;
    transition: transform 0.6s ease;
}
.customMenuOverlayListItem {
    color: var(--app-color-gray-light);
    background-color: transparent;
    transition: transform 0.6s ease;
    display: flex;
    align-items: center;
    &[app-variant="selected"]:not([app-item-type="checkboxes"]) {
        color: var(--app-color-gray-dark);
        background-color: var(--app-color-gray-lighter);
    }
    &[app-variant="highlighted"]:not([app-item-type="checkboxes"]) {
        color: var(--app-color-white-lightest);
        background-color: var(--app-color-gray-dark);
    }
    &[app-variant="highlighted"][app-item-type="checkboxes"] {
        font-weight: 900;
        font-size: 18px;
    }
    &[app-item-type="normal"] {
        justify-content: space-between;
    }
}
.customMenuOverlayListItemCheckbox {
    position: relative;
    display: flex;
    height: 25px;
    width: 25px;
    margin: auto 10px;
    border: 1px solid var(--app-color-gray-selected);
    border-radius: 2px;
    background-color: transparent;
    .customMenuOverlayListItem[app-variant="selected"] & {
        background-color: var(--app-color-gray-selected);
    }
}
.customMenuOverlayListItemChild {
    display: flex;
    width: 100%;
    cursor: pointer;
}
.customMenuOverlayListItemCheckboxCheckedMark {
    display: block;
    margin: auto;
    color: var(--app-color-white-lightest);
    fill: var(--app-color-white-lightest);
}
.customMenuOverlayListItemNormalCheckedMark {
    margin-left: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 0px 10px 0px 8px;
}

.collapsibleHeaderContainer {
    display: flex;
    flex-direction: column;
    &[app-display="none"] {
        display: none;
    }
}
.collapsibleHeaderButton {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    outline: none;
    background-color: var(--app-color-white-lightest);

    font-size: 16px;
    font-family: var(--app-text-main-font);
    color: var(--app-color-gray-dark);
}
.collapsibleHeaderText {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    line-height: 24px;
    color: var(--app-color-gray-dark);
}
.collapsibleHeaderChevron {
    margin: 0 10px 0 8px;
    transform: rotate(0deg);
    transition: transform 0.6s ease;
    color: var(--app-color-gray-light);
    .collapsibleHeaderContainer[app-variant="open"] & {
        transform: rotate(180deg);
        transition: transform 0.6s ease;
    }
}

.customErrorMessage {
    color: var(--app-color-red-error);
    font-family: var(--app-text-main-font);
    font-size: 12px;
}

.inputAndTagsWrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    &[app-tag-emplacement="top"] {
        flex-direction: column;
    }
    &[app-tag-emplacement="bottom"] {
        flex-direction: column-reverse;
    }
}

.emulatedFlexGap {
    padding-top: 10px;
    padding-bottom: 15px;
    display: inline-flex;
    flex-wrap: wrap;
    align-content: center;
    width: inherit;
    align-self: center;
    border: 1px dashed gray;
    //margin: 10px;
  }
