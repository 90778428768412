.inputContainer {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    //height: 56px;
    border: 1px solid;
    border-color: #d2d2d2;
    margin-top: 5px;
}
.inputField {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
    border-radius: 8px;
    border: 1px solid var(--app-color-gray-lighter);
    width: 100%;
    outline: none;
    //height: 56px;
    margin-top: 5px;
    margin-left: 0;
    padding: 0 10px 0 10px;
}
.calendarSVG {
    align-self: center;
    margin: 0 0 0 5px;
    width: 20px;
    //height: 20px;
    //margin-right: 16px;
    color: var(--app-color-gray-unselected);
    fill: var(--app-color-gray-unselected);
    color: #4A4A4A;
    background-color: transparent;
    padding-top: 6px;;
}
