.taskBoardPanelHost {
  display: flex;
  flex-direction: column;
  background-color: rgb(246,244,236);
  border-radius: 7px;
  padding: 0px 0px 20px 0px;
}

.container {
  padding: 15px 25px;

  img {
    padding-right: 10px;
  }
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 24px;
  }
}

.cardTitle {
  font-family: var(--app-text-main-font);
  font-size: 24px;
}