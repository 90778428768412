.selectAndMultiselectHost {
  //width: calc(50% - 20px);
  width: 100%;
  margin-right: 3px;
}

.tiles {
  display: flex;
  flex-direction: row;
}

.fieldLabel {
  color: #828282;
}

.multiselectComponent {
  border: 1px solid var(--app-color-gray-lighter);
  display: flex;
  margin-top: 10px;
  margin-left: 0px;
  border-radius: 4px;
}

.multiselectMenuOverlayList {
  display: flex;
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}

.multiselectMenuOverlayListItem {
  color: #333333!important;
  font-family: var(--app-text-main-font), serif !important;
  font-size: 16px!important;
  height: 40px!important;
  border: transparent!important;
}
.dropdownSelectMenuOverlayList{
  border: 1px solid #E0E0E0;
  border-radius: 4px;
}
.dropdownSelectComponent {
  border: 2px solid var(--app-color-gray-lighter);
  display: flex;
  margin-top: 10px;
  margin-left: 0px;
  border-radius: 4px;
}

.dropdownInput {
  display: flex;
  width: 100%;
  font-family: var(--app-text-main-font), serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--app-color-gray-dark);
  height: 45px;
}

.menuButtonPlaceholder{
  width: 100%;
  text-align: left;
  margin-left: 12px;
}
.dropdownSelectMenuOverlayListItem{
  color: #333333!important;
  font-family: var(--app-text-main-font), serif !important;
  font-size: 16px!important;
  height: 40px!important;
}

.center {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: var(--app-text-main-font);
  font-size: 16px;
}


