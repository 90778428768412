@use "../../../../styles/tools/truncate.scss" as textTransf;
@import "../../../../styles/breakpoints.scss";
  
.host {
    width: 100%;
    text-align: center;
    margin-top: 50px;
}
.icon {
    margin-right: 6px;
    margin-bottom: -2px;
}
.button {
   background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
}
.paragraph { 
    @include textTransf.textEllipsed(2);
    margin: 0;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--app-color-gray-light);
}
.animated {
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
    
    color: var(--app-color-gray-light);
}
@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
} 
@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}