@use "../../../../../styles//tools//truncate.scss" as textTransf;
.host {
    position: relative;
    display: flex;
    flex-direction: column;
}
.label {
    font-size: 12px;
}
.itemLeftIcon {
    margin-left: 10px;
    margin-right: 5px;
    height: 17px;
}
.hostTagDefault {
   
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

   // white-space: nowrap;
    word-break: break-all;

    font-family: var(--app-text-main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 1em;
    color: var(--app-color-gray-selected);

    margin: 8px 4px 0 4px;
    padding: 6px 4px;
   // height: 32px;
   max-width: 100%;
    border-radius: 4px;
    width: fit-content;
    &[app-variant="without-close-icon"] {
        padding-right: 10px;
    }
   
}
.tagParagraph{
     @include textTransf.textEllipsed(2);
    max-width: 100%;
    margin:0;
    color: var(--app-color-gray-dark);
    font-weight: normal;
    font-size: 17px;
}
.tagsContainer {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin: 10px;
}

.tagCloseIcon {
    margin-left: 5px;
    width: 12px;
    height: 12px;
}
.tagXCloseIcon {
    width: 12px;
    height: 12px;
    margin: 6px;
    margin-left: 10px;
}
.tagStepIcon {
    margin: 4px;
    width: 24px;
    height: 24px;
    padding: 4px;
    align-self: flex-start;
}
.tagShadowed {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 8px 10px 8px;
    font-size: 15px;
    line-height: 1.25em;
    box-shadow: 0 3px 10px 0 #0000001a;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 5px;
}
.tagPlated {
    display: flex;
    flex-direction: row;
    align-items: center;

    line-height: 20px;
    box-shadow: 0 3px 10px 0 #0000001a;
    cursor: pointer;
  //  white-space: nowrap;

    font-family: var(--app-text-main-font);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: var(--app-color-gray-selected);

    margin: 8px 4px 0 4px;
    padding: 6px 4px;
  //  height: 32px;
    background: var(--app-color-gray-lighter); // var(--app-color-white-dark);//
    border-radius: 4px;
}
.inputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex: 1;
    height: 50px;
    &[app-variant="responsive"] {
        height: auto;
        min-height: 56px;
    }
    &[app-state="active"] {
        outline: none;
    }
    &[app-state="error"] {
        border: 1px solid var(--app-color-red-error);
        border-radius: 4px;
    }
}
.input {
    &::placeholder {
        color: var(--app-color-gray-light);
    }
    outline: none;
    border: none;
    margin: 0;
    font-family: var(--app-text-main-font);
    font-size: 1em;
    line-height: 20px;
    color: var(--app-color-gray-light);
    align-self: center;
    padding: 0 5px;
    width: 100%;
    text-align: left;
    flex: 1;
    height: 50px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.toggleMenuButton {
    outline: none;
    border: none;
    margin: 0; 
    padding: 0 ;
    font-family: var(--app-text-main-font);
    font-size: 1em;
    color: var(--app-color-gray-light);
    align-self: center;
  
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    vertical-align: middle;
    text-align: center;
    height: 56px;
}
.toggleMenuInputButton {
    outline: none;
    border: none;
    font-family: var(--app-text-main-font);
    font-size: 1.1em;
    color: var(--app-color-gray-light);
    align-self: center;
    padding: 0 10px;
    text-align: left;
    background-color: transparent;
}
.toggleMenuInputButtonIcon {
    margin: 0 10px 0 8px;
    transform: rotate(0deg);
    transition: transform 0.6s ease;
    color: var(--app-color-gray-light);

    .toggleMenuInputButton[app-variant="open"]:not[app-icon="eyeglass"] & {
        transform: rotate(180deg);
        transition: transform 0.6s ease;
    }
    .toggleMenuInputButton[app-icon="chevron"] & {
        // width:6px;
        height: 12px;
    }
    .toggleMenuInputButton[app-icon="downArrow"] & {
        width: 10px;
        height: 11px;
    }
    .toggleMenuInputButton[app-icon="eyeglass"] & {
        transform: rotate(0deg);
        transition: transform 0.6s ease;
        width: 24px;
        height: 24px;
    }
}
.menuOverlayList {
    position: absolute;
    box-shadow: 0px 0px 5px #0000001a;
    list-style: none;
    padding-left: 0;
    &:focus,
    &:focus-within {
        outline: none;
    }

    z-index: 1;
    overflow-y: auto;
    border-radius: 10px;
    background-color: var(--app-color-white-lightest);
    display: flex;
    flex-direction: column;
    width: auto;

    transition: transform 0.6s ease;
    max-height: 250px;
}
.leftIconItemContainer {
    cursor: pointer;
    height: 32px;
    width: 32px;
    background: var(--app-color-white-dark);
    border-radius: 4px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
}
.leftIconItem {
    cursor: pointer;
    display: block;
    margin: auto;
    width: 16px;
    height: 16px;
}
.menuOverlayListItem {
    cursor: pointer;
    color: var(--app-color-gray-light);
    background-color: transparent;
    transition: transform 0.6s ease;
    display: flex;
    align-items: center;
    
    &[app-item-type="normal"] {
        justify-content: space-between;
    }
    &[app-item-separated="unique"] {
        border-bottom: 1px solid var(--app-color-gray-lighter_nuance);
    }
    &[app-item-read-only="readonly"] {
        font-style: italic;
        font-weight: 600;
        color: var(--app-color-gray-unselected);
    }
}
.menuOverlayListItemCheckbox {
    cursor: pointer;
    position: relative;
    display: flex;
    height: 25px;
    width: 25px;
    margin: auto 10px;
    border: 1px solid var(--app-color-gray-selected);
    border-radius: 2px;
    background-color: transparent;
    .menuOverlayListItem[app-variant-selected="selected"] & {
        background-color: var(--app-color-gray-selected);
    }
}
.menuOverlayListItemChild {
    display: flex;
    flex: 1;
    width: 100%;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
}
.menuOverlayListItemCheckboxCheckedMark {
    cursor: pointer;
    display: block;
    margin: auto;
    color: var(--app-color-white-lightest);
    fill: var(--app-color-white-lightest);
}
.menuOverlayListItemNormalCheckedMark {
    margin-left: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 0px 10px 0px 8px;
    color: var(--app-color-gray-unselected);
}

.errorMessage {
    color: var(--app-color-red-error);
    font-family: var(--app-text-main-font);
    font-size: 12px;
}
.customErrorMessage{
    font-family: var(--app-text-main-font);
    font-size: 12px;
    color: var(--app-color-red-error);
    white-space: pre;
    word-break: break-all;
    align-self: left;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 10px 0;
}