.newTaskFormHost {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  font-family: var(--app-text-main-font), serif;
  padding: 20px;

  input[type=text] {
    padding: 0 !important;
  }

  input {
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    color: var(--app-color-gray-dark);
    //border-radius: 8px;
    border: 1px solid var(--app-color-gray-lighter);
    font-family: var(--app-text-main-font), serif !important;
    min-width: 200px;
    width: 100%;
    outline: none;
    text-indent: 20px;
  }

  input::placeholder {
    color: var(--app-color-gray-light);
    font-size: 16px !important;
    font-weight: 700;
    text-indent: 10px;
  }

  label {
    font-family: var(--app-text-main-font), serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--app-color-gray-light);
  }

  input:invalid {
    border: 1px solid var(--app-color-error);
  }

  textarea:invalid {
    border: 1px solid var(--app-color-error);
  }

  textarea {
    min-width: 100%;
    max-width: 100%;
    max-height: 400px;
    min-height: 80px;
    box-sizing: border-box;
    padding-right: 10px;
    font-family: var(--app-text-main-font), serif;
    font-size: 16px;
    font-weight: 700;
    height: 80px;
    color: var(--app-color-gray-dark);
    //border-radius: 8px;
    border: 1px solid var(--app-color-gray-lighter);
    width: 100%;
    outline: none;
    padding-left: 20px;
    padding-top: 20px;
  }

  textarea::placeholder {
    font-family: var(--app-text-main-font), serif;
    color: var(--app-color-gray-light);
    font-size: 16px !important;
  }

  .css-1nwhlt9-Input {
    margin-left: -20px;
  }
}

.buttonsContainer {
  justify-content: center;
  display: flex;
}

.react-datepicker-wrapper {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  font-size: 16px !important;
  font-weight: 700;
}

.datePickerWrapper {
  width: 100%;
  font-size: 16px !important;
  font-weight: 700;
}

.customDatePicker {
  display: flex;
  flex: 1 1 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  height: 56px;
  padding-bottom: 20px;
  padding-top: 10px;
  font-size: 16px !important;
}

.saveButton {
  width: 130px;
}

.calendarSVG {
  align-self: center;
  width: 16px;
  height: 16px;
  margin-right: 16px;
  color: var(--app-color-gray-unselected);
  fill: var(--app-color-gray-unselected);
}