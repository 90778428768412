#field-button{
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #F1F1F1;
  border-radius: 8px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    filter: invert(27%) sepia(0%) saturate(41%) hue-rotate(180deg) brightness(59%) contrast(81%);
  }
  .customTheme {
    color: var(--app-color-gray-dark) !important;
    background-color: white !important;
    font-family:  var(--app-text-main-font), serif !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) !important;
    &.place-top {
      &:after {
        border-top-color: white !important;
        border-top-style: solid !important;
        border-top-width: 6px !important;
      }
    }
  }
}