@use "../../../../../styles/tools//truncate.scss" as textTransf;
.statusCardContainer {
  padding: 10px;
  cursor: pointer;
}

.statusCard {
  background-color: var(--app-color-white-lightest);
  width: 250px;
  height: 100px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.statusTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: -30px;
  margin-top: 10px;
 
   p {
    @include textTransf.textEllipsed(2); 
    width: 100%;  
    padding-left: 10px;
    font-family: var(--app-text-main-font), serif;
    font-size: 1.5rem;
    line-height: 2rem;
    color: var(--app-color-gray-dark);
    text-align: left;
  }
}

.statusDescriptionContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;

  p {
    padding-left: 10px;
    padding-right: 10px;
    font-family: var(--app-text-main-font), serif;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--app-color-gray-light);
    text-align: left;
  }

  img {
    width: 16px;
    height: 16px;
  }
}