.toggleContainer {
    width: 100%;
    height: 98%;
    min-width: 86px;
    //  min-height: 36px;
    background-color: #ffffff;
    cursor: pointer;
    user-select: none;
    border-radius: 4px;

    position: relative;
    border: 1px solid var(--app-color-gray-lighter);
    z-index: -1;
    &::before {
        @extend .backText;
        content: "Yes";
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 4px;
        z-index: 0;
        width: 48%;
        color: #979797;
    }
    &::after {
        @extend .backText;
        content: "No";
        position: absolute;
        top: 4px;
        right: 4px;
        bottom: 4px;
        width: 48%;
        z-index: 0;
        color: #979797;
    }
}

.dialogButton {
    @extend .backText;
    position: absolute;
    z-index: 1;
    margin: 2%;
    left: calc(50% - 2%);
    top: 0.5%;
    bottom: 0.5%;
    transition: all 0.3s ease;

    cursor: pointer;
    background-color: var(--app-color-gray-dark);
    color: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
    width: 48%;
    min-width: unset;
}

.switched {
    background-color: var(--app-color-gray-dark);
    left: 0;
}

.backText {
    font-family: var(--app-text-main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 0.95em;
    display: flex;
    justify-content: center;
    align-items: center;
}
