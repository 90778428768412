.tableContainer {
  width: 100%;
}

.tableHostSimple {
  table {
    width: 100%;
    border-spacing: 0;
    border-top: 1px dashed #bbb;
    border-left: 1px dashed #bbb;
    border-right: 1px dashed #bbb;
    font-size: 14px;
    // font-weight: 700;

    tr {
      :last-child {
        border-right: 0;
      }
    }

    th {
      padding: 0 0 0 10px;
      color: white;
      //background-color: var(--processmaker-table-th-background-color);
      background-color: rgb(83, 159, 234);
      background-color: var(--app-color-gray-dark);
      border-right: 1px dashed #bbb;
    }

    td {
      font-size: 14px;
      // font-weight: 700;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px dashed #bbb;
      border-right: 1px dashed #bbb;

    }
  }
}

.tableHost {
  table {
    width: 100%;
    border-spacing: 0;
    font-family: var(--app-text-main-font);

    color: black;
    // font-weight: 700;

    tr {
      :first-child {
        height: 50px;
      }

      :last-child {
        border-right: 0;
      }
    }

    th {
      :last-child {
        padding: 0 0 0 10px;
        text-align: left;
        min-height: 30px;
        min-width: 100px;
        color: white;
        background-color: var(--app-color-gray-dark);
        border-right: 1px solid #bbb;
      }

    }

    td {
      text-align: left;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px dashed var(--app-color-gray-lighter);
      border-right: 1px dashed var(--app-color-gray-lighter);
    }

    tr:nth-child(even) {
      background-color: var(--app-color-gray-lightest);
    }
  }

  table tr:hover .action {
    transform: scale(1.3);
  }

  table tr:hover td {
    background: rgb(83, 159, 234);
    color: #fbfbfb;
  }
}

.tableReport {
  table {
    width: 100%;
    border-spacing: 0;
    font-family: var(--app-text-main-font) sans-serif;

    color: black;
    font-size: 14px;
    // font-weight: 700;

    tr {
      :first-child {
        height: 50px;
      }

      :last-child {
        border-right: 0;
      }
    }

    th {
      :last-child {
        padding: 0 0 0 10px;
        text-align: left;
        min-height: 30px;
        height: 60px;
        min-width: 100px;
        color: white;
        background-color: var(--app-color-gray-dark);
        border-right: 1px solid #bbb;
      }

    }

    td {
      text-align: left;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px dashed var(--app-color-gray-lighter);
      border-right: 1px dashed var(--app-color-gray-lighter);

    }
  }

  
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.listContainer {
  cursor: pointer;

  &:hover {
    background-color: rgb(83, 159, 234);
    color: #fbfbfb;

    hr {
      color: rgb(83, 159, 234);
    }
  }

}

.pageContainer {
  display: flex;
  align-items: center;
  max-height: 80%;
  margin: 0;
  width: 10px
  p {
    height: 20px;
    font-family: var(--app-text-main-font);
    font-size: 14px;
    font-weight:700;
    letter-spacing: 0;
    line-height: 20px;
  }
}

.filtersContainer {
  width: auto;
  margin: 10px 0 20px 0;
  border: 1px dashed var(--app-color-gray-light);
  padding: 10px;
  height: inherit;
  background-color: white;
  cursor: pointer;
}

.cardsContainer {
  width: auto;
  margin: 10px 0 20px 0;
  padding: 10px;
  height: inherit;
  //background-color: rgb(246, 244, 236);
  cursor: pointer;
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
  width: inherit;
  font-family: var(--app-text-main-font);
}

.avatarUrlImg {
  object-fit: fill;    
  height: 70px;
  width: 70px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid transparent;
  cursor: pointer;
  }