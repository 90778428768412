$container-width: 256px;
$container-height: 62px;

.topRows {
    display: flex;
    justify-content: center;
    
}

.annexedCard {
    width: $container-width;
    max-width: $container-width;
    height: 53px;
    margin: 5px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    background-color: #f1f1f1;
    border: none;
    cursor: pointer;
    text-align: center;
    color: var(--app-color-gray-dark);
    font-size: 12px;
    letter-spacing: 0;
}

.cardContainerBase {
    --app-after-height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid gray;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 3px 10px 0 #0000001a;
    cursor: pointer;
    height: $container-height;
    padding: 12px;
    gap: 5px;
    color: var(--app-color-gray-dark);
}
.topContainerBase {
    --app-after-height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid blue;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 3px 10px 0 #0000001a;
    height: $container-height;
    padding: 12px;
    gap: 5px;
    color: var(--app-color-gray-dark);
}

.defaultParagraph {
    height: 100%;
    width: 100%;
}

.cardContainer [app-card-type="start"] {
    font-family: var(--app-text-main-font);
}

.titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.childrenWrapper {
    position: relative;
    flex: 1 1 auto;
    justify-content: space-between;
    width: auto;
    margin: 10px auto;
}

.childrenContainer {
    --app-svg-stroke: 3px;
    width: 100%;
    padding: 0 70px 0 50px;
    flex: 1;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    position: relative;
    margin-bottom: 10px;

    &[app-both-children-types="true"] {
        &::before {
            display: block;
            content: "";
            position: absolute;
            border-left: var(--app-svg-stroke) solid var(--app-color-gray-lighter);
            border-bottom: var(--app-svg-stroke) solid var(--app-color-gray-lighter);
            border-bottom-left-radius: 20px;
            border-top-right-radius: 20px;
            top: -10px;
            left: calc(50% - 2px);
            right: var(--app-right-offset); //70px;
            height: 10px;
        }

        &::after {
            display: block;
            content: "";
            position: absolute;
            border-right: var(--app-svg-stroke) solid var(--app-color-gray-lighter);
            border-bottom: var(--app-svg-stroke) solid var(--app-color-gray-lighter);
            border-bottom-right-radius: 20px;
            top: -10px;
            right: calc(50% - 2px);
            left: 70px;
            height: 10px;
        }
    }

    &[app-managerials-only="true"] {
        &::before {
            display: block;
            content: "";
            position: absolute;
            border-left: var(--app-svg-stroke) solid var(--app-color-gray-lighter);
            border-bottom: var(--app-svg-stroke) solid var(--app-color-gray-lighter);
            border-bottom-left-radius: 20px;
            border-top-right-radius: 20px;
            top: -10px;
            left: calc(50% - 2px);
            right: var(--app-right-offset); //70px;
            height: 10px;
        }

        &::after {
            display: block;
            content: "";
            position: absolute;
            border-right: var(--app-svg-stroke) solid var(--app-color-gray-lighter);
            border-bottom: var(--app-svg-stroke) solid var(--app-color-gray-lighter);
            border-bottom-right-radius: 20px;
            top: -10px;
            right: calc(50% - 2px);
            left: var(--app-left-offset);
            height: 10px;
        }
    }

    &[app-non-managerials-only="true"] {
        &::after {
            display: block;
            content: "";
            position: absolute;
            border-right: var(--app-svg-stroke) solid #d2d2d2;
            border-bottom: var(--app-svg-stroke) solid #d2d2d2;
            border-bottom-right-radius: 20px;
            top: -10px;
            right: calc(50% - 2px);
            height: 10px;
            width: 30%;
        }
    }

    &[app-only-one-managerial="true"] {
        &::after {
            display: block;
            content: "";
            position: absolute;
            border-right: var(--app-svg-stroke) solid #d2d2d2;
            border-bottom: none;
            border-bottom-right-radius: 0px;
            top: -10px;
            right: calc(50% - 1px);
            left: 0px;
            height: 10px;
        }
    }
}

.departmentsContainer {
    margin: 0 10px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    //font-family: var(--app-text-main-font);
    font-weight: 400;
}

.bottomDepartmentsContainer {
    width: 100%;
    --app-margin-top: 60px;
    margin: var(--app-margin-top) auto;
    position: relative;
}

.bottomMarginContainer {
    position: relative;
    display: flex;
    height: 30px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    border-bottom: 2px solid #d2d2d2;
    border-left: 2px solid #d2d2d2;
    border-right: 2px solid #d2d2d2;

    &::before {
        display: block;
        content: "";
        position: absolute;
        top: -13px;
        left: -8px;
        border: 2px solid #d2d2d2;
        border-radius: 10px;
        height: 10px;
        width: 10px;
    }

    &::after {
        display: block;
        content: "";
        position: absolute;
        top: -13px;
        right: -8px;
        border: 2px solid #d2d2d2;
        border-radius: 10px;
        height: 10px;
        width: 10px;
    }
}

.departmentNameContainer {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 0;
    margin: 0;

    p {
        margin: 0;
        padding-top: 30px;
        color: var(--app-color-gray-dark);
        font-family: var(--app-text-main-font);
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
        text-align: center;
    }

    &::before {
        display: block;
        content: "";
        position: absolute;
        top: 0px;
        left: calc(50% - 2px);
        right: calc(50% + 2px);
        border-left: 2px solid #d2d2d2;
        height: 20px;
    }

    &::after {
        display: block;
        content: "";
        position: absolute;
        top: 18px;
        left: calc(50% - 8px);
        right: calc(50% + 8px);
        border: 2px solid #d2d2d2;
        border-radius: 10px;
        height: 10px;
        width: 10px;
    }
}

.tree {
    padding: 5px;
    border-radius: 8px;
    display: inline-block;
    border: 1px solid red;
    min-width: 50px;
}


.userPanelHost {
    margin-top: 50px;
    display: flex;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
  align-content: flex-start;
  }
  
  .userPanelRows {
    display: flex;
    flex-direction: column;
    background-color: rgb(246,244,236);
    border-radius: 7px;
    padding: 0px 20px 00px 20px;
    font-family: var(--app-text-main-font);
    font-size: 16px;
  }
  
  