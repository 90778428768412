@custom-media --app-viewport-xsmall (min-width: 320px);
@custom-media --app-viewport-small (min-width: 640px);
@custom-media --app-viewport-small-max (max-width: 640px);
@custom-media --app-viewport-medium (min-width: 960px);
@custom-media --app-viewport-medium-max (max-width: 960px);
@custom-media --app-viewport-large (min-width: 1280px);
@custom-media --app-viewport-xlarge (min-width: 1680px);
@custom-media --app-viewport-xxlarge (min-width: 1920px);

:root {
    --break-xs: 320px;
}
