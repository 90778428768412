.menuBar {
    display: flex;
    align-items: center;
    font-family: var(--app-text-main-font);
    font-weight: 500;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0;
    flex-direction: column;
    z-index: 1;
}

.nav {
    display: flex;
    align-items: center;
    font-family: var(--app-text-main-font);
    font-weight: 500;
    width: 100%;
    //height: 80px;
    flex-direction: row;
}

.nav .menu {
    width: 25px;
    margin: 0 15px;
    margin-right: 0px;
    cursor: pointer;
}

.nav .ul {
    flex: 1;
    text-align: right;
    align-items: center;
    align-self: center;
    justify-content: right;
    justify-items: center;
    display: flex;
}

.nav .ul .li {
    display: inline-block;
    list-style: none;
    //margin: 0 10px;
    font-size: 16px;
    font-weight: 600;
}


.nav .ul .li .a {
    text-decoration: none;
    color: rgba(255, 254, 254, 0.997);
}

.nav .ul .li .a:hover {
    text-decoration: underline;
}

.button {
    background: #efefef;
    height: 30px;
    width: 60px;
    border-radius: 20px;
    border: 0;
    outline: 0;
    cursor: pointer;
}

.button .span {
    display: block;
    background: #999;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    margin-left: 2px;
}

.nav2 {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    width: 82%;
}

.nav2 .menu {
    width: 25px;
    margin: 0 15px;
    margin-right: 0px;
    cursor: pointer;
}

.nav2 .logo {
    width: 160px;
    cursor: pointer;
}

.nav2 .ul {
    flex: 1;
    text-align: right;
}

.nav2 .ul .li {
    display: inline-block;
    list-style: none;
    margin: 0 15px;
}


.nav2 .ul .li .a {
    text-decoration: none;
    color: rgba(255, 254, 254, 0.997);
    color: black;
}

.nav2 .ul .li .a:hover {
    text-decoration: underline;
}

.headerTopBarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;

    p {
        margin-left: 10px;
        font-family: var(--app-text-main-font), serif;
        font-size: 26px;
        color: white;
    }

}

.modulesButton {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    color: var(--app-color-white-lightest);

    font-family: var(--app-text-main-font);
    font-size: 16px;
    font-weight: 600;
}

.pText {
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    text-align: center;
    font-size: 16px;
    margin-top: 50px;
}


.logoUrlImg {
    object-fit:scale-down;
    height: 80px;
    width: 200px;
    margin: 10px 0 10px 5px;
}

.logoUrlImgMobile {
    object-fit: fill;
    height: 60px;
    width: 140px;
    margin: 5px;
}

.avatarContentContainer {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

}
.avatarUrlImg {
object-fit:cover;   
height: 40px;
width: 40px;
border-radius: 50%;
background-color: black;
border: 1px solid gray;
}