.host {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: space-evenly;
    align-content: center;
}

.closeContainer {
    position: absolute;
    top: -19px;
    right: 19px;
}
.closeElement {
    cursor: pointer;
    background-color: transparent;
    border: 0;
}
.closeIcon {
    width: 48px;
    height: 48px;
}
.target {
    flex: 1;
}
.title {
    text-align: center;
    font-family: var(--app-text-main-font);
    font-size: 2em;
    font-weight: bold;
    color: var(--app-color-gray-dark);
    width: 100%;
    word-break: break-word;
    text-transform: capitalize;
}
.subtitle {
    margin-top:-20px;
    margin-bottom:40px;
    text-align: center;
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-light);
    width: 100%;
    word-break: break-word;
    text-transform: capitalize;
}
.elementInputTitle {
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-light);
    margin: 8px auto;
}
.elementTextInput {
    height: 57px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    width: 100%;
    padding-left: 20px;
    outline: none;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-dark);
}
.optionSection {
    margin-top: 5px;
}
.renderBooleansOptions {
    > div {
        width: 155px;
    }
} 
.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;
    position: relative;
}

.deleteButton {
    position: absolute;
    left: 0;

    align-self: flex-start;
    outline: none;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;

    font-family: var(--app-text-main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: var(--app-color-gray-dark);
    margin-left: 0;
    padding-left: 0;
}
.errorContainer {
    font-family: var(--app-text-main-font);
    font-size: 12px;
    color: var(--app-color-red-error);
    white-space: pre;
    word-break: break-all;
  }

  .messageContainer {
    font-family: var(--app-text-main-font);
    font-size: 12px;
    color: gray;
    white-space: pre;
    word-break: break-all;
  }
  .peoplePositionsSelect {
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 8px;    
    padding-left: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.peoplePositionsSelectInput {
}
.peoplePositionsSelectMenuOverlay {
    display: flex;
    flex-direction: column;
    margin: 5px auto;
    max-height: 250px;
    width: auto;
    min-width: 100%;
    top: 100%;
}
.peoplePositionsSelectMenuOverlayItem {
    display: flex;

    align-items: center;
    padding: 10px;
    padding-left: 40px;
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;
    position: relative;
}

.controls2 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 10px;
}

.currencySelectContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 57px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    width: auto;
}

.componentsSelectComponent {
    border: none;
    width: 100%;
    padding-left: 10px;
}
.componentsSelectMenuOverlayList {
    left: 0px;
    right: 0;
    top: 80%;
}
.componentsSelectMenuOverlayListItem {
    padding: 5px;
}
.componentsSearchInput {
    max-width: 80%;
}


.componentsClearControlsClassName {
    padding: 0 10px;
    color: var(--app-color-gray-darker);
}



