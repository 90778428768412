.closeIconContainer {
  position: absolute;
  top: -18px;
  right: 24px;
  img {
    width: 48px;
    height: 48px;
  }
}
.logoutImage {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.logoutText {
  color: var(--app-color-gray-dark);
  font-family: var(--app-text-main-font);
  text-align: center;
  font-size: 16px;
  margin-top: 50px;
}
.logoutButton {
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
}

.modalReturnButton {
  cursor: pointer;
  background-color: transparent;
  font-weight: 500;
  margin-left: auto;
  margin-right: auto;
  color: var(--app-color-gray-dark);
  font-family: var(--app-text-main-font);
  text-align: center;
}
