.fieldrow{
  display: flex;
  align-items: center;
  margin-top: 1px;
   
  resize: horizontal;
  overflow:auto;
  cursor: w-resize;
  &[app-variant-has-error="error"] {
    border: 1px solid var(--app-color-error);
    color: rgb(252, 252, 252)
  }
}
.fieldrowcontainer{
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 3px;
  &[visible="hidden"] {
    display: none;
  }
  
}
.fieldrowinnercontainer{
  width: 40px;
  //height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-top: auto;
  // margin-bottom: auto;
  img{
    width: 20px;
    height: 20px;
    filter: invert(27%) sepia(0%) saturate(41%) hue-rotate(180deg) brightness(59%) contrast(81%);
  }
}

.center {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-family: var(--app-text-main-font);
  font-size: 16px;
}
