$color__back: #ffffff;
$color__back__switch: #9b9b9b;
$color__back--high: lighten($color__back, 20%);
$color__text: #4a4a4a;
$color__text--low: darken($color__text, 50%);
$color__primary: #4a4a4a;

$checkbox__size: 32px;
$switch__size: $checkbox__size;

$checkbox__border__size: 2px;
$checkbox__border__radius__size: 5px;
$checkbox__border__color: var(--app-color-gray-lighter);
$checkbox__border__color--disabled: $color__back--high;

$checkbox__checkmark__size: $checkbox__size - (2 * $checkbox__border__size);
$checkbox__checkmark__size--indeterminate: 2px;
$checkbox__checkmark__color: $color__primary;
$checkbox__checkmark__color--disabled: $color__back--high;

$checkbox__label__color: $color__text;
$checkbox__label__color--disabled: $color__text--low;

// CHECKBOXES

.m-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 3px;

  align-self: stretch;
  display: flex;
  flex-direction: row;
  width: 100%; //100%;
  justify-content: flex-start;
  align-items: center;
  word-wrap: break-word;
  &__checkmark {
    background-color: green;
  }
  &__label {
    flex-shrink: 0;
    flex-grow: 0;
    // padding: 0.5rem 1rem;
    color: $checkbox__label__color;
    cursor: pointer;
    word-wrap: break-word;
    max-width: 100%;
  }
  &__input {
    position: relative;
    flex-shrink: 0;
    width: $checkbox__size;
    height: $checkbox__size;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none; // TODO: manage :focus
    background: $color__back;
    cursor: pointer;
    border: $checkbox__border__size solid $checkbox__border__color;
    border-radius: $checkbox__border__radius__size;
    padding: 0.5rem;
    &::before {
      content: " ";
      position: absolute;
      top: 50%;
      right: 50%;
      bottom: 50%;
      left: 50%;
      transition: all 0.1s;
      background: $checkbox__checkmark__color;
    }

    &:checked,
    &:indeterminate {
      &::before {
        top: -$checkbox__border__size;
        right: -$checkbox__border__size;
        bottom: -$checkbox__border__size;
        left: -$checkbox__border__size;
        content: "\2714"; //"\2713";
        color: var(--app-color-white-lightest);
        font-size: 1.5em;
        line-height: 20px;
        padding: 7px 0 0 0;
        text-align: center;
        margin: 0;
        border-radius: $checkbox__border__radius__size;
        // background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
      }
    }

    &:indeterminate {
      &::before {
        // top: ($checkbox__checkmark__size / 2) -
        // ($checkbox__checkmark__size--indeterminate / 2);
        // bottom: ($checkbox__checkmark__size / 2) -
        // ($checkbox__checkmark__size--indeterminate / 2);
        border: 0px solid $checkbox__border__color;
      }
    }

    &:disabled {
      border-color: $checkbox__border__color--disabled;
      cursor: default;

      &::before {
        background-color: $checkbox__checkmark__color--disabled;
      }

      + .m-checkbox__label {
        color: $checkbox__label__color--disabled;
        cursor: default;
      }
    }
  }

  &--has-error {
    &__input {
      border-color: var(--app-color-error);
      background-color: rgba(var(--app-color-error), 0.2);

      &::before {
        background-color: var(--app-color-error);
      }
    }
  }

  &--switch {
    &__label {
      word-wrap: break-word;
      max-width: 100%;
      &::after {
        content: "off";
        // margin-left: 0.25rem;
      }
    }

    &__input {
      width: (2 * $switch__size) - (2 * $checkbox__border__size);
      height: $switch__size;
      border: $checkbox__border__size solid $checkbox__border__color--disabled;
      border-radius: $checkbox__checkmark__size;
      background: $color__back__switch;
      &:checked,
      &:indeterminate {
        &::before {
          top: $checkbox__border__size;
          right: $checkbox__border__size;
          bottom: $checkbox__border__size;
          left: $checkbox__border__size;
          content: "";
          border-radius: $checkbox__checkmark__size;
        }
      }
      &::before {
        top: $checkbox__border__size;
        right: $switch__size - $checkbox__border__size;
        bottom: $checkbox__border__size;
        left: $checkbox__border__size;
        border-radius: 50%;
        background: $checkbox__checkmark__color--disabled;
      }

      &:checked {
        border-color: $checkbox__border__color;
        background: $checkbox__checkmark__color--disabled;
        + .m-checkbox--switch__label {
          &::after {
            content: "on";
          }
        }
        &::before {
          right: $checkbox__border__size;
          left: $switch__size - $checkbox__border__size;
          background: $checkbox__border__color;
        }
      }
    }
  }
}

.checkboxMainContainer {
  cursor: pointer;
  padding: 0.5em 0.5em 0.5em 0;
  flex: 1;
  border-radius: 5px;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  // width: 100%;
  justify-content: flex-start;
  max-width: 100%;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 5px;

  .checkMarkContainer {
    width: 32px;
    height: 32px;
    min-width: 8px;
    min-height: 8px;
    border-radius: 5px;
    background-color: var(--app-color-white-lightest);
    border: 2px solid var(--app-color-gray-lighter);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
  .checkMark {
    width: 32px;
    height: 32px;
    min-width: 8px;
    min-height: 8px;
    margin: 0;
    padding: 0;
    border-radius: 5px;
    background-color: var(--app-color-white-lightest);
    border: 2px solid var(--app-color-gray-lighter);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .labelContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 1;
    flex-grow: 1;
    // padding: 0.5rem 1rem;
    color: var(--app-color-white-lightest);
    cursor: pointer;
    word-wrap: break-word;
    max-width: 100%;
    min-width: 16px;
    padding: 0 0 0 10px;
  }
  .label {
    flex-shrink: 1;
    flex-grow: 1;
    cursor: pointer;
    word-wrap: break-word;
    max-width: 100%;
    color: #9b9b9b;
    font-family: var(--app-text-main-font);
    font-size: 1.1em;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
    padding: 0;
  }
  .ckeckHeader {
    color: var(--app-color-white-lightest);
    font-size: 1.1em;
    line-height: 20px;
    padding: 0 0 0 0;
    text-align: center;
    margin: 0;
  }
}
