.host {
  flex: 1; 
  min-width: 200px;
  width: 200px;
}

.topButton {
  // height: 20px;
  width: 100%;
  padding: 0;
  margin: 0;
  p {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    color: var(--app-color-white-lightest);
  }
}
.topButtonIcon {
  width: 24px;
  height: 24px;
}

.resetButton {
  align-self: center;
  width: 90%;
  padding: 0;
  margin: 0;
  justify-content: center;
  p {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
    color: var(--app-color-white-lightest);
  }
}
.resetButtonIcon {
  width: 24px;
  height: 24px;
}

.filterTitleContainser {
  padding-bottom: 20px;
}
.filterTitle {
  font-family: var(--app-text-main-font);
  font-size: 1.5rem;
  line-height: 1.5rem;
  color: var(--app-color-gray-light);
}
.filterCategoryContainer {
  position: relative;
  padding-bottom: 5px;
}
.filterCategory {
  color: var(--app-color-gray-light);

  font-family: var(--app-text-main-font);
  font-size: 1.3rem;
  line-height: 1.3rem;
  color: var(--app-color-gray-dark);
  margin-bottom: 12px;
}
.filterCategoryLoaderContainer {
  position: absolute;
  top: 0px;
  right: 5px;
  display: flex;
  width: 20px;
  justify-content: flex-end;
  align-items: center;
}
.checkboxClassname {
  min-width: 18px;
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 0.5px;
  margin-left: 5px;
}
.checkboxClassname[data-reach-custom-checkbox-container]:after,
.checkboxClassname[data-reach-custom-checkbox-container]:before {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 18px;
  height: 18px;
}
.checkboxClassname[data-reach-custom-checkbox-container]:focus-within,
.checkboxClassname[data-reach-custom-checkbox-container][data-focus] {
  box-shadow: 0 0 4px 1px #d2d2d2;
  border-radius: 0.2em;
  outline: none;
}
.checkboxClassname[data-reach-custom-checkbox-container][data-state="checked"]:after {
  display: block;
  width: calc(50% - 5px);
  height: calc(90% - 4px);
  border: solid #fff;
  border-width: 0 0.225em 0.225em 0;
  transform: translateY(-65%) translateX(-50%) rotate(45deg);
}

.checkboxLabelClassname {
  font-family: var(--app-text-main-font);
  font-size: 1rem;
  line-height: 1.3rem;
  color: var(--app-color-gray-dark);
}
