#field-row-container{
  label{
    color:#828282;
  }
}
.field-row{
  display: flex;
  align-items: center;
  //margin-top: 1px;
  border: 0px solid var(--app-color-gray-light);  
  &[app-variant-has-error="error"] {
    border: 1px solid var(--app-color-error);
  }
  .field-row-container{
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 3px;
    &[visible="hidden"] {
      display: none;
    }
    .field-row-inner-container{
      width: 40px;
      height: 40px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: auto;
      margin-bottom: auto;
      img{
        width: 20px;
        height: 20px;
        filter: invert(27%) sepia(0%) saturate(41%) hue-rotate(180deg) brightness(59%) contrast(81%);
      }
    }
  }
}