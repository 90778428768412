.host {
    display: flex;
    flex-direction: column;  
    padding: 0 10px 0 10px ;  
}
.leftColumn {
    position: fixed;
    left: 0;
    top: 20px;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    min-width: 150px;
    overflow: hidden;
}
.leftColumnHeader {
    display: flex;
    flex: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.backButtonContainer {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    margin-left: 30px;
    width: 25px;
    height: 25px;
}
.backIco {
    width: 24px;
    height: 24px;
}
.titleContainer {
    display: flex;
    text-align: center;
    align-items: center;
    align-self: center;
    p {
        //margin-left: 10px;
        font-family: var(--app-text-main-font);
        font-size: 2em;
        font-weight: bold;
        line-height: 1.2em;
        text-align: center;
        color: var(--app-color-gray-dark);

    }
}
.deleteButtonContainer {
    margin-bottom: 25%;
    p {
        margin: 0 0 0 54px;
        text-align: left;
        color: var(--app-color-gray-dark);
        cursor: pointer;
    }
    .deleteIco {
        margin-bottom: -5px;
        margin-right: 10px;
    }
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    overflow-y: auto;
    margin: 10px 5px 20px 5px;
}

.saveButtonContainer {
    margin-top: 20px;
    display: flex;
    margin-bottom: 0px;
    align-self: center;
    padding-top: 10px;
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;
    position: relative;
}

.controls2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-top: 20px;
    margin-bottom: 0px;
    align-self: stretch;
    padding-top: 10px;
}
