.selectAndMultiselectHost {
  width: calc(50% - 20px);
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  padding-left: 10px;
  align-content: center;
  margin-top: 5px;
  min-height: 55px;
  font-family: var(--app-text-main-font), serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--app-color-gray-dark);

  &::placeholder {
    color: var(--app-color-gray-light);
  }
}

.selectAndMultiselectHostTiles {
  width: calc(50% - 20px);
  border: 0px solid #D2D2D2;
  border-radius: 8px;
  padding-left: 10px;
  align-content: center;
  margin-top: 5px;
  min-height: 55px;
  font-family: var(--app-text-main-font), serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--app-color-gray-dark);

  &::placeholder {
    color: var(--app-color-gray-light);
  }
}

.section {
  display: flex;
  flex-direction: column;
}

.accordionContainer {
  overflow: hidden;
  margin: 0 0 0px 0;
  font-family: var(--app-text-main-font), serif;
  font-size: 18px;
  font-weight: 700;
  background-color: #FFFFFF;
  color: var(--app-color-gray-dark);
  align-items: center;
  border: 1px dashed var(--app-color-gray-light);
  border-radius: 8px;
  outline: none;
  transition: background-color 0.6s ease;

  &[app-variant-has-error="error"] {
    border: 1px solid var(--app-color-error);
  }
}

.active {
  background-color: #FFFFFF;
}

.active .title {
  font-family: var(--app-text-main-font), serif;
  font-size: 22px;
  font-weight: 700;
  color: white;
  background-color: var(--app-color-gray-dark);
  width: 100%;

}

.customTitleInput {
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  padding-left: 10px;
  align-content: center;
  margin-top: 5px;
  margin-bottom: auto;
  height: 37px;
  font-family: var(--app-text-main-font), serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--app-color-gray-dark);

  &::placeholder {
    color: var(--app-color-gray-light);
  }
}

.icon {
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
  transition: transform 0.6s ease;
}

.loaderContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 32px;
  max-height: 80%;
  margin: 0 0px 0 5px;

  .host[comp-loading="loading"] & {
    transform: translateX(-5px);
  }
}

.iconsContainer {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.content {
  background-color: white;
  text-align: left;
  overflow: auto;
  transition: max-height 0.6s ease;
}

.fieldTypeInput {
  ::placeholder {
    font-family: var(--app-text-main-font), serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--app-color-error);
  }

  width: 100%;
  padding-left: 5px;
  border: none;
  height: 37px;
  font-family: var(--app-text-main-font),
  serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--app-color-gray-dark);
}

.fieldTypeID {

  width: 100%;
  padding-left: 5px;
  border: 1px solid var(--app-color-gray-light);
  height: 37px;
  font-family: var(--app-text-main-font),
    serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--app-color-gray-dark);
}

.fieldMapInput {
  ::placeholder {
    font-family: var(--app-text-main-font), serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--app-color-error);
  }

  width: 100%;
  padding-left: 5px;
  margin-bottom: 10px;
  height: 37px;
  font-family: var(--app-text-main-font),
  serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--app-color-gray-dark);
}

.fieldTypeDate {
  ::placeholder {
    font-family: var(--app-text-main-font), serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--app-color-error);
  }

  width: 100%;
  padding-left: 5px;
  padding-top: 2px;
  border: 2px solid var(--app-color-gray-lighter);
  height: 44px;
  font-family: var(--app-text-main-font),
  serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--app-color-gray-dark);
}


.labelInput {
  width: 100%;
  border: 0px;
  font-family: var(--app-text-main-font),
    serif;
  font-size: 16px;
  color: var(--app-color-gray-light);
}

input {
  outline: none;
}

.fieldRowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  height: 50px;
  flex: 1 1;
}

.accordion:hover,
.title {
  font-family: var(--app-text-main-font), serif;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  text-align: left;
  margin-top: -4px;
  width: 100%;
  padding: 20px;
  border-radius: 8px 8px 0 0;
  border: 1px dashed var(--app-color-gray-lighter);
}

.emulatedFlexGap>* {
  margin-left: 10px;
  margin-right: 10px;
}

.emulatedFlexGap {
  padding-top: 10px;
  padding-bottom: 15px;
  display: inline-flex;
  flex-wrap: wrap;
  align-content: center;
  width: 100%;
}

.regularAccordion>* {
  margin: 12px 0 0 12px;
}

.regularAccordion {
  padding-top: 10px;
  padding-bottom: 15px;

  margin: -12px 0 0 -12px;
  width: 100%;
}

.singleInputContainer {
  border: none;
  height: auto;

  input {
    cursor: pointer;
    text-align: left;
    padding-left: 15px;
    font-family: var(--app-text-main-font), serif;
    color: #4A4A4A;
    font-size: 16px;
    font-weight: 700;
    background-color: transparent;

  }
}

.dateInputContainer {
  border: 1px black;
  height: auto;
  margin-top: -20px;

  input {
    cursor: pointer;
    text-align: left;
    padding-left: 15px;
    font-family: var(--app-text-main-font), serif;
    color: #4A4A4A;
    font-size: 16px;
    font-weight: 700;
    background-color: transparent;

  }
}

.inputContainerClassName {
  input {
    cursor: pointer;
    text-align: left;
    padding-left: 15px;
    font-family: var(--app-text-main-font), serif;
    color: #4A4A4A;
    font-size: 16px;
    font-weight: 700;

  }
}

.phoneIInput {
  outline: none !important;
  width: 100% !important;
  //border: none !important;
  height: 40px !important;
  padding-top: 0 !important;
  padding-left: 80px !important;
  padding-bottom: 0 !important;
  border-radius: 0px;
  
}

.currencySelectContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 37px !important;
  border: none;
  border-radius: 4px;
  width: 100%;
}

.currencySymbol {
  height: 37px !important;
  cursor: grab;
}

.currencySelectComponent {

  border: none;

}

.currencySelectMenuOverlayList {
  top: 80%;
}

.currencySelectMenuOverlayListItem {
  padding: 5px;
}

.currencySearchInput {
  max-width: 0%;
  align-items: center;
  color: var(--app-color-gray-dark);
  height: 90%;
}

.currencyClearControlsClassName {
  padding: 0 10px;
  display: none;
  color: var(--app-color-gray-dark);
}

.currencyInputContainerClassName {
  justify-content: flex-start;
}

.currencyInput {
  width: 100%;
  border: none;
  font-family: var(--app-text-main-font), serif;
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
  height: 37px !important;
  color: var(--app-color-gray-dark);
}

/* people select */
.peopleContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}

.peopleBooleansOptions {
  >div {
    width: 165px;
  }
}

.peoplePositionSelectContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid var(--app-color-gray-lighter);
  border-radius: 4px;
  min-height: 57px;
  height: auto;
  cursor: pointer;
}

.peoplePositionsSelect {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.peoplePositionsSelectInput {
  height: 37px;
  color: var(--app-color-gray-dark);
  font-family: var(--app-text-main-font);
  font-size: 16px;
  font-weight: 700;
}

.peoplePositionsSelectMenuOverlay {
  display: flex;
  flex-direction: column;
  margin: 5px auto;
  max-height: 250px;
  width: auto;
  min-width: 100%;
  top: 100%;

}

.peoplePositionsSelectMenuOverlayItem {
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 40px;
  font-family: var(--app-text-main-font);
  font-size: 16px;
  font-weight: 700;
  color: var(--app-color-gray-dark);

}

.tileCards {
  width: 100px;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 40px;
  font-family: var(--app-text-main-font);
  font-size: 16px;
  font-weight: 700;
  color: var(--app-color-gray-dark);

}

/* Curency select */
.currencySelectContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 57px;
  border: 1px solid var(--app-color-gray-lighter);
  border-radius: 4px;
  width: 100%;
}

.currencySymbol {
  font-family: var(--app-text-main-font);
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  color: var(--app-color-gray-dark);
  padding: 0 10px;
}

.currencySelectComponent {
  border: none;
}

.currencySelectMenuOverlayList {
  left: 0px;
  right: 0;
  top: 80%;
}

.currencySelectMenuOverlayListItem {
  padding: 5px;
}

.currencySearchInput {
  max-width: 80%;
}

.currencyClearControlsClassName {
  padding: 0 10px;
  color: var(--app-color-gray-darker);
}

.unfocusableWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 57px;
  border: 1px solid var(--app-color-gray-lighter);
  border-radius: 4px;
  width: 100%;

  &[app-variant="error"] {
    border: 1px solid var(--app-color-red-error);
  }

  &[app-container-variant="noBorder"] {
    border: none;
  }
}

.menuOverlayList {
  left: 0;
  right: 0;
  margin-top: 5px;
  border: 0;
  border-radius: 0;
  background-color: var(--app-color-gray-lightest);

}

.menuOverlayListItem {
  padding-left: 15px;
  background-color: var(--app-color-white-lightest);
  box-shadow: 0;
  font-size: 16px;
  font-weight: 700;
  color: var(--app-color-gray-dark);
  border: 1px dashed var(--app-color-gray-lighter);
  margin: 0 10px 0 10px;
  width: 90%;
}

.menuOverlayListItemInput {
  justify-content: flex-start;
  font-family: var(--app-text-main-font), serif;
}

.menuOverlayListItemInputContainerClassName {
  width: 100%;
  border: none;
  font-size: 16px;
  font-weight: 700;
  padding-left: 10px;
}

.openSelectWithSearchHost {
  border: 0;
  border-radius: 0;
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: row;
  padding: 10px 0 10px 0;
}

.openSelectWithSearch {
  border: 2px solid var(--app-color-gray-lighter);

  label {
    font-size: 16px;
    font-weight: 700;
    color: var(--app-color-gray-dark);
  }
}

.dropdownClassContainer {
  width: 100%;
  height: 37px;
  display: flex;
  margin-left: 0px;
  border: 0;
}

.dropdownHeightClassContainer {
  width: 100%;
  height: 100px;
  display: flex;
  margin-left: 0px;
  border: 0;
}

.fieldTypeTextarea {
  ::placeholder {
    font-family: var(--app-text-main-font), serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--app-color-error);
  }

  width: inherit;
  padding: 0 10px 0 10px;
  border: none;
  height: 100px;
  font-family: var(--app-text-main-font),
  serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--app-color-gray-dark);
}

.fullLengthTextarea {
  ::placeholder {
    font-family: var(--app-text-main-font), serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--app-color-error);
  }

  width: inherit;
  padding: 0 10px 0 10px;
  border: 1px solid var(--app-color-gray-light);
  min-height: 100px;
  min-width: 100%;
  font-family: var(--app-text-main-font),
  serif;
  font-size: 16px;
  font-weight: 700;
  color: var(--app-color-gray-dark);
}

.filtersContainer {
  width: inherit;
  margin-bottom: 10px;
  border: 1px dashed var(--app-color-gray-lighter);
  padding: 10px 10px 20px 10px;
}

.dropdownSelectComponent {
  border: 2px solid var(--app-color-gray-lighter);
  display: flex;
  margin-left: 0px;
  border-radius: 4px;
}

.itemsTable {
  width: inherit;

  table {
    width: 100%;
    border-spacing: 0;
    border-top: 1px solid #bbb;
    border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;

    tr {
      :first-child {
        font-size: 16px;
        font-weight: 700;

      }

      :last-child {
        border-right: 0;
      }
    }

    th {
      :first-child {
        font-size: 18px;
        text-align: center;
      }

      text-align: center;
      color: white;
      background-color: var(--app-color-gray-dark);
      border-right: 1px solid #bbb;
    }

    td {
      text-align: center;
      font-size: 18px;
      margin: 0;
      padding: 10px 10px 10px 10px;
      border-bottom: 1px solid #bbb;
      border-right: 1px solid #bbb;
    }
  }

  table tr:hover td {
    background: red;
    color: #fbfbfb;
    cursor: grab;
  }

  table tr:last-child:hover>td {
    background: transparent;
    color: var(--app-color-gray-dark);
  }
}

.customMenuOverlayListItemCheckboxCheckedMark {
  display: block;
  margin: auto;
  color: var(--app-color-white-lightest);
  fill: var(--app-color-white-lightest);
}

.customMenuOverlayListItemNormalCheckedMark {
  margin-left: 8px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin: 0px 10px 0px 8px;
}

.checkbox {
  margin: 8px 2px 0 0;

  :checked {
    accent-color: red;
    background-color: red;
  }
}

.itemsContainer {
  width: 100%;

  table {
    width: inherit;
    border-spacing: 1;
    border-left: 1px dashed #bbb;
    font-size: 16px;
    align-content: left;

    tr {
     
      :last-child {}
    }

    th {
      :first-child {
        height: 40px;
        padding: 10px 0 10px 0;

       }
      padding: 0 0 0 10px;
      color: white;
      background-color: rgb(83, 159, 234);
      background-color: var(--app-color-gray-dark);
      border: 1px dashed #bbb;
    }

    td {
      text-align: left;
      font-size: 16px;
      
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px dashed #bbb;
      border-right: 1px dashed #bbb;

    }
  }
  // table {
  //   width: 100%;
  //   border-spacing: 0;
  //   border-left: 1px solid #bbb;

  //   tr {
  //     :first-child {
  //       font-size: 14px;
  //       font-weight: 700;

  //     }
  //   }

  //   th {
  //     :first-child {
  //       font-size: 14px;
  //       text-align: left;
        
  //     }

  //     padding: 5px 10px 5px 10px;
  //     text-align: center;
  //     color: white;
  //     color: black;
  //     background-color: var(--app-color-dark-green);
  //     background-color: var(--app-color-blue);
  //     background-color: #F6F4EC;

  //     background-color: var(--app-color-gray-dark);
  //     color: white;
  //     border: 1px solid #bbb;
  //   }

  //   td {
  //     text-align: left;
  //     font-size: 14px;
  //     margin: 0;
  //     padding: 5px 10px 5px 10px;
  //     border-bottom: 1px solid #bbb;
  //     border-right: 1px solid #bbb;

  //   }
  // }

  table tr:hover td {
    background: rgb(83, 159, 234);
    color: #fbfbfb;
    cursor: grab;
  }

  // table tr:last-child:hover>td {
  //   background: transparent;
  //   color: var(--app-color-gray-dark);
  // }
}

.listContainer {

  table {
    width: 100%;
    border-spacing: 0;
    border-left: 1px solid #bbb;

    tr {
      :first-child {
        font-size: 16px;
        font-weight: 700;

      }
    }

    th {
      :first-child {
        font-size: 18px;
        text-align: left;
      }

      padding: 5px 10px 5px 10px;
      text-align: center;
      color: white;
      background-color: var(--app-color-gray-dark);
      border: 1px solid #bbb;
    }

    td {
      text-align: left;
      font-size: 18px;
      margin: 0;
      padding: 5px 10px 5px 10px;
      border-bottom: 1px solid #bbb;
      border-right: 1px solid #bbb;

    }
  }

  table tr:hover td {
    background: rgb(83, 159, 234);
    color: #fbfbfb;
    cursor: grab;
  }

  table tr:last-child:hover>td {
    background: transparent;
    color: var(--app-color-gray-dark);
  }
}

.tableHostMobile {
  width: inherit;

  table {
    width: 100%;
    border-spacing: 0;
    border-top: 1px solid #bbb;
    border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;

    tr {
      :first-child {
        font-size: 16px;
        font-weight: 700;

      }

      :last-child {
        border-right: 0;
      }
    }

    th {
      :first-child {
        font-size: 18px;
        text-align: left;
      }

      padding: 10px 0px 10px 4px;
      text-align: center;
      color: white;
      background-color: var(--app-color-gray-dark);
      border-right: 1px solid #bbb;
    }

    td {
      text-align: left;
      font-size: 18px;
      margin: 0;
      padding: 10px 0px 10px 4px;
      border-bottom: 1px solid #bbb;
      border-right: 1px solid #bbb;
    }
  }

  table tr:hover td {
    background: rgb(83, 159, 234);
    color: #fbfbfb;
    cursor: grab;
  }

  table tr:last-child:hover>td {
    background: transparent;
    color: var(--app-color-gray-dark);
  }
}


.tableHostSimple {
  width: 100%;

  table {
    width: inherit;
    border-spacing: 1;
    border-left: 1px dashed #bbb;
    font-size: 16px;
    font-weight: 700;

    tr {

      :last-child {}
    }

    th {
      padding: 0 0 0 10px;
      color: white;
      background-color: rgb(83, 159, 234);
      background-color: var(--app-color-gray-dark);
      border: 1px dashed #bbb;
    }

    td {
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px dashed #bbb;
      border-right: 1px dashed #bbb;

    }
  }

  table tr:hover .action {
    transform: scale(1.3);
  }

  table tr:hover td {
    background: rgb(83, 159, 234);
    color: #fbfbfb;
  }
}


.mobileTable_ii {
  width: inherit;

  table {
    width: 100%;
    border-spacing: 0;
    border-top: 1px solid #bbb;
    border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;

    tr {
      :first-child {
        font-size: 16px;
        font-weight: 700;

      }

      :last-child {
        border-right: 0;
      }
    }

    th {
      :first-child {
        font-size: 18px;
        text-align: center;

      }

      text-align: center;
      color: white;
      background-color: var(--app-color-gray-dark);
      border-right: 1px solid #bbb;
    }

    td {
      text-align: center;
      font-size: 18px;
      margin: 0;
      padding: 10px 10px 10px 10px;
      border-bottom: 1px solid #bbb;
      border-right: 1px solid #bbb;
    }
  }


  table tr:last-child:hover>td {
    background: transparent;
    color: var(--app-color-gray-dark);
  }
}

.centerDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
}

.calendar {
  width: 20px;
  height: 20px;
}

.actionsContainer {
  width: inherit;
}

.wallet {
  margin-top: 20px;
  width: 30%;
  min-width: 200px;
  margin-bottom: 10px;
  border: 1px dashed var(--app-color-gray-lighter);
  padding: 20px 10px 20px 10px;
  background-color: rgb(246, 244, 236);
  height: auto;
}

.swiperslide {
  width: 25%;
}

.ratings {}



.checkbox-wrapper-21 .control {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 18px;
}

.checkbox-wrapper-21 .control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.checkbox-wrapper-21 .control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
}

.checkbox-wrapper-21 .control:hover input~.control__indicator,
.checkbox-wrapper-21 .control input:focus~.control__indicator {
  background: #ccc;
}

.checkbox-wrapper-21 .control input:checked~.control__indicator {
  background: #2aa1c0;
}

.checkbox-wrapper-21 .control:hover input:not([disabled]):checked~.control__indicator,
.checkbox-wrapper-21 .control input:checked:focus~.control__indicator {
  background: #0e647d;
}

.checkbox-wrapper-21 .control input:disabled~.control__indicator {
  background: #e6e6e6;
  opacity: 0.6;
  pointer-events: none;
}

.checkbox-wrapper-21 .control__indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-wrapper-21 .control input:checked~.control__indicator:after {
  display: block;
}

.checkbox-wrapper-21 .control--checkbox .control__indicator:after {
  left: 8px;
  top: 4px;
  width: 3px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.checkbox-wrapper-21 .control--checkbox input:disabled~.control__indicator:after {
  border-color: #7b7b7b;
}

.dashboardCard {
  padding: 10px;
  background-color: var(--app-color-white-lightest);
  cursor: pointer;
  width: 30%;
  min-width: 160px;
  border-radius: 8px;
  border: 1px dashed var(--app-color-gray-light);
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  font-family: var(--app-text-main-font);
  
  p {
    text-align: center;
    font-size: 26px;
    color: var(--app-color-gray-dark);
  
  }
}
