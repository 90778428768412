.subheadingContainer {
  align-self: stretch;
  font-family: var(--app-text-main-font);
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 16px;
  padding-left: 10px;
  justify-content: center;
  align-items: center;

  .padH50 {
    padding: 0 50px 0 50px !important;
  }
  .centered {
    text-align: "center";
    justify-content: center;
    align-items: center;
  }

  p {
    margin: 0px 0 16px 0;
    color: #9b9b9b;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    line-height: 1.3rem;
    text-align: left;
    padding: 0 0 0 0;
  }
}
.justified-left {
  text-align: left;
  justify-content: flex-start;
  align-items: center;
}
.subheadingContainerSlim {
  align-self: stretch;
  font-family: var(--app-text-main-font);
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 0px;
  text-align: left;
  justify-content: center;
  align-items: center;

  .padH50 {
    padding: 0 50px 0 50px !important;
  }
  .centered {
    text-align: "center";
    justify-content: center;
    align-items: center;
  }

  p {
    margin: 0px 0 16px 0;
    color: #9b9b9b;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    line-height: 1.3rem;
    text-align: left;
    padding: 0 0 0 0;
  }
}
.modal-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  background-color: var(--app-color-white-lightest);
  border-radius: 16px;
  width: 70%;
  vertical-align: center;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
}
.heading {
  font-family: "GT-Medium";
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #4a4a4a;
  padding-bottom: 15px;
}

.subheading {
  font-family: var(--app-text-main-font);
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #9b9b9b;
  padding-bottom: 15px;
}

.commit-text {
  font-family: var(--app-text-main-font);
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: #4a4a4a;
}

.btn-primary:disabled {
  color: var(--app-color-white-lightest);
  background-color: #9b9b9b;
  border-color: #9b9b9b;
}

.custom-checkbox {
  width: 32px !important;
  height: 32px !important;
  border: 2px solid var(--app-color-gray-lighter) !important;
  border-radius: 4px !important;
}

.modal-wrapper:after #root {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
}

.css-1pahdxg-control {
  border-radius: 8px !important;
  height: 50px !important;
  width: 400px !important;
}
.css-yk16xz-control {
  border-radius: 8px !important;
  height: 50px !important;
  width: 400px !important;
}
.css-1wa3eu0-placeholder {
  margin-left: 14px !important;
  font-family: var(--app-text-main-font) !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #999999 !important;
}
.css-1uccc91-singleValue {
  margin-left: 14px !important;
  font-family: var(--app-text-main-font) !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.skip-btn-text {
  font-family: var(--app-text-main-font);
  font-size: 16px;
  line-height: 20px;
  color: #9b9b9b;
}
.btn-link {
  color: #4a4a4a;
}

.input-invite-style {
  max-width: 220px;
  height: 60px;
  border: var(--app-color-gray-lighter) solid 1px;
  border-radius: 8px;
  padding: 20px;
  font-family: var(--app-text-main-font);
  font-size: 16px;
  line-height: 20px;
  color: #4a4a4a;
  text-align: left;
}

.add-colleague-button {
  border: 0px;
  background-color: #f1f1f1;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
  height: 32px;
}

.invite-colleagues-via-import-container {
  border: 1px solid var(--app-color-gray-lighter);
  border-radius: 8px;
}
.invite-colleagues-via-import-dragndrop-container {
  border: 1px dashed var(--app-color-gray-lighter);
  border-radius: 8px;
}
.bluredBackground {
  filter: blur(2x);
}

.firstDiv_III {
  position: relative;
  display: flex;
  height: 96%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin: 2% 10% 2% 10%;
  min-width: 774px;
}

.logoImg_III {
  position: absolute;
  left: -60px;
  top: 7px;
  right: 0;
  bottom: 0;
  width: 40px;
  height: 40px;
}


.headerContainer_III {
  margin: 0px 0 0px 0;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
}
.header_III {
  color: var(--app-color-gray-dark);
  font-family: var(--app-text-main-font);
  font-size: 2em;
  //  line-height: 2em;
  margin: 0;
  padding: 0 0 0 10px;
  text-align: left;
}

.subheadingContainer_III {
  align-self: stretch;
  font-family: var(--app-text-main-font);
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 16px 0 30px 10px;
  
  .padH50 {
      padding: 0 50px 0 50px !important;
  }
  .centered {
      text-align: "center";
      justify-content: center;
      align-items: center;
  }

  p {
      margin: 0px 0 16px 0;
      color: var(--app-color-gray-light);
      font-family: var(--app-text-main-font);
      font-size:20px;
      line-height: 1.3rem;
      text-align: left;
      padding: 0 0 0 0;
  }
}

.justifiedLeft_III {
  text-align: left;
  justify-content: flex-start;
  align-items: center;
}


.subheading_III {
  font-family: var(--app-text-main-font);
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--app-color-gray-light);
  padding-bottom: 15px;
}
.updateCompanyDataFieldsContainer_III{
  min-width: 600px;
  padding: 0px 30px;
}

.buttonWrapper_III {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  //margin: 40px 0 0 0;
}
.backToPreviousButtonContainer_III {
  cursor: pointer;
  align-self: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
      opacity: 0.5;
      color: var(--app-color-gray-light);
      width: 22px;
      height: 22px;
      margin-right: 10px;
  }
  p {
      color: var(--app-color-gray-light);
      font-family: var(--app-text-main-font);
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
  }
}

.buttonContainer_III {
  flex: 1;
  position: relative;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.skipThisStepContainer_III {
  cursor: pointer;
  align-self: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.skipThisStepText_III {
  color: var(--app-color-gray-light);
  font-family: var(--app-text-main-font);
  font-size: 16px;
  line-height: 1.3rem;
  min-width: 110px;
}

