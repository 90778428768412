@use "../../../../styles/tools/truncate.scss" as textTransf;
@import "../../../../styles/breakpoints.scss";

.menuEntryHost {
  user-select: none;
  border-radius: 28px 28px 0px 0px;
  position: relative;
  margin-right: -30px;
  background-color: transparent;

  & a:hover {
    background-color: #ddd;
  }
}

.hovered {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: var(--app-color-white-lightest);
}

.racord {
  position: absolute;
  top: 50%;
  left: -28px;
  right: 0;
  bottom: -10px;
  background-size: 100% 50%;
  height: 35px;
  width: calc(100% + 28px);
  z-index: 2;
}

.menuButton {
  cursor: pointer;
  position: relative;
  outline: none;
  border: none;
  background-color: transparent;
  color: var(--app-color-gray-dark);
  padding: 16px;
  border-radius: 2px 2px 0px 0px;
  width: 56px;

  .menuEntryHost[app-button-variant="hovered"] & {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    background-color: var(--app-color-white-lightest);
  }

  .svg {
    width: 24px;
    height: 24px;
  }
}

.modulesEntryHost {
  user-select: none;
  border-radius: 28px 28px 0px 0px;
  position: relative;
  display: inline-block;
  background-color: transparent;

  & a:hover {
    background-color: #ddd;
  }
}

.mobileEntryHost {
  user-select: none;
  border-radius: 28px 28px 0px 0px;
  background-color: transparent;

  & a:hover {
    background-color: #ddd;
  }
}

.racordM {
  position: absolute;
  background-size: 100% 150%;
  background: radial-gradient(circle at top left, transparent 28px, white 0) no-repeat top left;
  background-color: var(--app-color-white-lightest);
  height: 70px;
  width: calc(100%);
}

.modulesButton {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  color: inherit;
  min-width: 80px;
  font-family: var(--app-text-main-font);
  font-size: 18px;
  font-weight: bolder;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .modulesEntryHost[app-button-variant="hovered"] & {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    background-color: var(--app-color-white-lightest);
    color: var(--app-color-gray-dark);
    height: 50px;
  }

  padding-left: 10px;
  padding-right: 10px;
  font-family: var(--app-text-open-sans);
  font-size: 18px;
  font-weight: bolder;
  letter-spacing: -1px;
}


.dashboardButton {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  color: inherit;
  font-family: var(--app-text-main-font);
  font-weight: bolder;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 0.25em;
    text-decoration-thickness: 0.01em;
    color: white;
  }

  padding-left: 10px;
  font-family: var(--app-text-open-sans);
  font-size: 18px;
  font-weight: bolder;

}

.menuButton {
  background-color: transparent;

  .modulesEntryHost[app-button-variant="hovered"] & {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    background-color: var(--app-color-white-lightest);
    color: var(--app-color-gray-dark);
    height: 45px;
  }
}

.cartButton {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: transparent;
  color: var(--app-color-white-lightest);
  min-width: 80px;
  font-family: var(--app-text-main-font);
  font-size: 16px;
  font-weight: 600;
  width: 50px;
  margin-bottom: 15px;
}


.modulesButtonBordered {
  cursor: pointer;
  outline: none;
  border-radius: 5px 5px 5px 5px;
  border: 1px solid var(--app-color-gray-lighter);
  background-color: transparent;
  color: var(--app-color-white-lightest);
  color: black;
  min-width: 80px;
  height: 40px;
  font-family: var(--app-text-main-font);
  font-size: 1rem;
  margin-left: 15px;

  .modulesEntryHost[app-button-variant="hovered"] & {
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    background-color: var(--app-color-white-lightest);
    color: var(--app-color-gray-lighter);
  }
}

.circle {
  position: absolute;
  top: -55%;
  height: 20px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--app-color-white-lightest);
  min-width: 110px;
}

.circleH {
  position: absolute;
  top: -55%;
  height: 20px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--app-color-white-lightest);
  min-width: 80px;
}

.menuMessageContainer {
  padding-top: 10px;
  margin-top: 13px;
  position: absolute;
  min-width: 180px;
  overflow: auto;
  border-radius: 8px 0 8px 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

  .modulesEntryHost[app-button-variant="hovered"] & {
    display: block;
  }

  & a {
    font-family: var(--app-text-main-font);
    color: black;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    display: block;
    pointer-events: all;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
}

.modulesContainer {
  display: none;
  padding-top: 10px;
  margin-top: 13px;
  position: absolute;
  color: var(--app-color-gray-dark);
  background-color: var(--app-color-white-lightest);
  min-width: 180px;
  overflow: auto;
  border-radius: 8px 0 8px 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  right: 0;

  .modulesEntryHost[app-button-variant="hovered"] & {
    display: block;
    z-index: auto;
  }

  & a {
    font-family: var(--app-text-main-font);
    color: black;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    display: block;
    pointer-events: all;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
  }

}

.notificationsContainer {
  display: none;

  margin-top: -3px;
  position: absolute;
  background-color: var(--app-color-white-lightest);
  min-width: 230px;
  overflow: auto;
  border-radius: 8px 0 8px 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 10;
  min-height: 90vh;

  .menuEntryHost[app-button-variant="hovered"] & {
    display: block;
  }

  & a {
    font-family: var(--app-text-main-font);
    font-weight: 600;
    color: black;
    font-size: 16px;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    pointer-events: all;
  }
}

.notificationsUnread {
  @include textTransf.textEllipsed(2);
  position: absolute;
  border-radius: 50%;
  top: 5px;
  right: 5px;
  background-color: red;

  height: 20px;
  min-width: 20px;
  max-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  &[app-is-loading="true"] {
    background-color: var(--app-color-white-lightest);
  }

  p {
    @include textTransf.textEllipsed(1);
    padding: 0px 5px;
    margin: 0;
    font-family: var(--app-text-main-font);
    font-size: 1em;
    color: var(--app-color-white-lightest);
    text-align: center;
  }
}

.settingsContainer {
  display: none;
  padding-top: 20px;
  margin-top: -3px;
  position: absolute;
  background-color: var(--app-color-white-lightest);
  min-width: 230px;
  overflow: auto;
  border-radius: 8px 0 8px 8px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  right: 0;
  z-index: 1;

  .menuEntryHost[app-button-variant="hovered"] & {
    display: block;
  }

  & a {
    font-family: var(--app-text-main-font);
    color: black;
    font-size: 16px;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    pointer-events: all;
  }
}

.renderNotificationsContainer {
  padding: 10px;
  width: 50vh;
}

.titleContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.title {
  font-family: var(--app-text-main-font);
  font-weight: bold;
  font-size: 24px;
  color: var(--app-color-gray-dark);

}

.notification {
  --textColor: var(--app-color-gray-dark);

  &[app-is-unread="true"] {
    --backgroundColor: var(--app-color-gray-lighter);

  }

  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  background-color: var(--backgroundColor);
  border-radius: 4px;
  margin: 4px;
  padding: 1px;
  width: -webkit-fill-available;

  p {
    margin-left: 8px;
    font-family: var(--app-text-main-font);

    font-size: 14px;

    color: var(--textColor);
    min-width: 462px;

    @media (--app-viewport-small) {
      min-width: 230px;
    }

    @media (--app-viewport-small-max) {
      min-width: 162px;
    }
  }

  a {
    cursor: pointer;
    outline: none;
    border: none;
    display: inline;
    text-decoration: underline;
    padding: 0;
    color: var(--textColor);
    pointer-events: all;
    font-size: 14px;

    &:hover {
      background-color: transparent;
    }
  }

  svg {
    margin: 15.5px;
    color: var(--textColor);
    width: 22px;
    height: 14px;
  }
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px;
  padding-top: 16px;
}

.paginationButton {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  margin: 6px;
}

.paginationMark {
  width: 16px;
  height: 16px;
  border: 3px solid var(--app-color-gray-lighter);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &[app-current-selected="true"] {
    border: 3px solid var(--app-color-gray-dark);
  }

  div {
    width: 6px;
    height: 6px;
    background-color: var(--app-color-gray-dark);
    border-radius: 50%;
  }
}

.toogleContainer {
  width: 100%;
  justify-content: space-evenly;
  display: flex;
  //flex-direction: row-reverse;
  //align-items: center;
}

.toogleLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  padding: 10px;

  span {
    padding-right: 10px;
    margin-left: 8px;
    font-family: var(--app-text-main-font);
    font-size: 16px;

    color: var(--app-color-gray-dark);
  }
}

.readAllButtonContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 24px 0px 6px;
}

.readAllButton {
  cursor: pointer;
  outline: none;
  border: none;
  background-color: var(--app-color-gray-dark);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: max-content;
  border-radius: 4px;

  p {
    font-family: var(--app-text-main-font);
    font-size: 14px;
    color: var(--app-color-white-lightest);
    margin: 0;
    margin-left: 6px;
    padding: 6px;
  }

  svg {
    width: 16px;
    height: 16px;
    color: var(--app-color-white-lightest);
  }
}

.logoutButton {
  font-family: var(--app-text-main-font);
  cursor: pointer;
  display: flex;
  outline: none;
  border: none;
  background-color: transparent;
  width: 100%;

  &:hover {
    //box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    background-color: var(--app-color-gray-lighter);
  }

  p {
    font-family: var(--app-text-main-font);
    font-size: 14px;
    text-decoration: none;
    text-align: left;
    padding-left: 5px;
  }

}

.racordA {
  position: absolute;
  top: 40px;
  background-size: 100% 150%;
  //background: radial-gradient(circle at top left, transparent 28px, white 0) no-repeat top left;
  background-color: var(--app-color-white-lightest);
  height: 100px;
  width: calc(100%);
}

.circleA {
  position: absolute;
  top: -25%;
  height: 20px;
  border-radius: 8px 8px 0px 0px;
  background-color: var(--app-color-white-lightest);
  min-width: 80px;
}