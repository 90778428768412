.host {
    position: relative;
    box-shadow: none;
    max-width: 100%;
    width: 100%;

    font-family: var(--app-text-main-font);
    font-size: 1.1em;
    color: var(--app-color-gray-light);
    border: 1px solid var(--app-color-gray-lighter); //#d2d2d2 ;
    border-radius: 0px;
    outline: none;
    text-align: left;
    margin: 0;
    // padding: 0 0px 0 10px;
}
.toggleMenuControlsIcons {
    display: inline-block;
    margin-left: 10px;
    width: 12px;
    height: 12px;
    stroke-width: 0;
    color: var(--app-color-gray-light);
}
.toggleMenuButton {
    outline: none;
    border: none;
    margin: 0px 5px;
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-light);
    align-self: center;
    padding: 0;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    text-align: center;
    height: 56px;
    /*   background-color: aqua; */
}
.inputContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    flex: 1;
    height: 50px;
}
.input {
    outline: none;
    border: none;
    margin: 0px 5px;
    font-family: var(--app-text-main-font);
    font-size: 1em;
    color: var(--app-color-gray-light);
    align-self: center;
    padding: 0;
    width: 100%;
    text-align: left;
    flex: 1;
    height: 50px;
}
.menuOverlayList{
    &[app-is-visible='false']{
        display:none;
    }
}
.menuOverlayListItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
}
.currencySymbol {
    font-family: var(--app-text-main-font);
    font-style: normal;
    font-weight: bold;
    color: var(--app-color-gray-dark);
    padding: 0 10px;
    margin: 5px 0;
}
