.some-custom-checkbox [data-reach-custom-checkbox-container]{
  min-width: 14px;
  min-height: 14px;
}

.some-custom-checkbox [data-reach-custom-checkbox-container][data-state="checked"]:after {
  display: block;
  width: calc(45% - 2px);
  height: calc(90% - 4px);
  border: solid #fff;
  border-width: 0 0.125em 0.125em 0;
  -webkit-transform: translateY(-65%) translateX(-50%) rotate(45deg);
  transform: translateY(-65%) translateX(-50%) rotate(45deg);
}
.some-custom-checkbox [data-reach-custom-checkbox-container][data-state="checked"]:before,
.some-custom-checkbox [data-reach-custom-checkbox-container][data-state="mixed"]:before {
  border-color: var(--app-color-gray-lighter);
  background-color: var(--app-color-gray-dark);
  border-radius: 0.2em;
}
.some-custom-checkbox [data-reach-custom-checkbox-container]:before {
  width: 110%;
  height: 110%;
  border: 1px solid #b8b8b8;
  border-radius: 0.2em;
  background-color: #fff;
}
.some-custom-checkbox [data-reach-custom-checkbox-container]:after,
.some-custom-checkbox [data-reach-custom-checkbox-container]:before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: "";
}
.some-custom-checkbox {
  margin: 0; // 1ex;
  padding: 0; //1ex;
  display: flex;
  flex: 0;
  height: auto;
  min-width: 14px;
  min-height: 14px;
}

[data-reach-custom-checkbox-input]:focus {
  border-radius: 0.2em;
  outline: none !important;
  border: none !important;
}

.label {
  flex-shrink: 1;
  flex-grow: 1;
  cursor: pointer;
  word-wrap: break-word;
  max-width: 100%;
  width: 100%;
  color: var(--app-color-gray-light);
  font-family: var(--app-text-main-font), serif;
  font-size: 1.1em;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75ex;
}
[data-reach-custom-checkbox-input] {
  position: absolute !important;
  top: 1ex !important;
  left: 1ex !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0 !important;
  z-index: 1 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 0.2em;
  cursor: pointer;
}

[data-reach-custom-checkbox-container] {
  display: inline-block;
  position: relative;
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 0.2em;
  margin: 1ex 0.75ex 1ex 1ex;
  cursor: pointer;
}

[data-reach-custom-checkbox-container]:focus-within,
[data-reach-custom-checkbox-container][data-focus] {
  box-shadow: 0 0 4px 1px var(--app-color-gray-lighter);
  outline: -webkit-focus-ring-color auto 5px;
  border-radius: 0.2em;
}
