@use "../../../../styles/tools//truncate.scss" as textTransf;

.pinnedCardHost {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  width: 255px;
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 20px 20px 20px;
}

.topContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    @include textTransf.textEllipsed(2);
    width: 180px;
    font-family: var(--app-text-main-font);
    font-size: 1.5em;
    line-height: 1.5em;
    color: var(--app-color-gray-dark);
    margin: 0 5px;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  img {
    width: 16px;
    height: 16px;
  }
}
.pinContainer {
  cursor: pointer;
  align-self: flex-start;
}
.pinLoadingContainer {
  margin-top: 10px;
  cursor: initial;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.pinSvg {
  margin-top: 8px;
  align-self: center;
  color: var(--app-color-gray-dark);
  width: 20px;
  height: 20px;
}
.bottomContainer {
  display: flex;
  flex-direction: row;

  img {
    width: 16px;
    height: 16px;
    padding-right: 5px;
  }

  p {
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    line-height: 1rem;
    color: var(--app-color-gray-light);
    padding: 0;
    margin: 0;
    text-overflow: ellipsis;
    word-break: break-word;
  }
}
.firstItemcContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 30px;
  img {
    width: 16px;
    height: 16px;
    padding-right: 5px;
  }

  p {
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    line-height: 1rem;
    color: var(--app-color-gray-light);
    padding: 0;
    margin: 0;
    text-overflow: ellipsis;
    word-break: break-word;
  }
}
.secondItemcContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 30px;

  img {
    width: 16px;
    height: 16px;
    padding-right: 5px;
  }

  p {
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    line-height: 1rem;
    color: var(--app-color-gray-light);
    padding: 0;
    margin: 0;
    text-overflow: ellipsis;
    word-break: break-word;
  }
}
.descriptionTextContainer {
  margin: 0 0 1em 0;
  overflow: hidden;
  @include textTransf.textEllipsed(3);
  // display: -webkit-box;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
}
.description {
  margin: 0;
  font-family: var(--app-text-main-font);
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--app-color-gray-light);
}
