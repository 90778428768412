.host {
    display: flex;
    flex-direction: column;
    padding: 10px;
    justify-content: flex-start;
    align-content: center;
    &[app-variant-opened="true"] {
        transform: translateY(0);
        opacity: 1;
        transition:  var(--app-modal-transition);
    }
    transition: var(--app-modal-transition);
    transform: translateY(-100vh);
    z-index: 1;
    filter: none;
    margin: auto;
    background-color: var(--app-color-white-lightest);
    position: relative;
     
}
@media (min-width: 600px) {
    .host {
      min-width: 300px;
     }
  }
.closeContainer {
    display: flex;
    width: 100%;
    justify-content: right;
    

}
.closeElement {
    cursor: pointer;
    background-color: transparent;
    border: 0;
}
.closeIcon {
    width: 48px;
    height: 48px;
}
.imageContainer {
    display: flex;
    //width: 50%;
    justify-content: left;
    

}
.transparent {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    animation: fadeIn 0.5s;
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -o-animation: fadeIn 0.5s;
    -ms-animation: fadeIn 0.5s;
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }