.host {
  @extend .flex-centered;
}

.target {
  @extend .flex-centered;
}

.title {
  font-family: var(--app-text-main-font);
  font-size: 16px;
  color: var(--app-color-gray-light);
}

.requiredTitle {
  font-family: var(--app-text-main-font);
  font-size: 16px;
  color: var(--app-color-red-error);
  white-space: pre;
}

.errorContainer {
  font-family: var(--app-text-main-font);
  font-size: 12px;
  color: var(--app-color-red-error);
  white-space: pre;
  word-break: break-all;
}

.renderTitle {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-height: 30px;
}

.tooltipContainer {
  position: relative;
  flex: 0;
}

.icon {
  cursor: pointer;
  margin: 5px 0 0 20px;
  width: 18px;
  height: 18px;
  stroke: var(--app-color-gray-light);
  color: var(--app-color-gray-light);
  fill: var(--app-color-gray-light);
  opacity: 0.6;

  &:focus,
  &:focus-within {
    outline: none;
  }
}

.tooltip {
  opacity: 1 !important;
  box-shadow: 2px 2px 2px 2px #00000017 !important;
  border-radius: 8px !important;
  max-width: 180px !important;
  word-wrap: break-word;
  hyphens: auto;
  text-align: left;
  text-justify: inter-word;
  font-weight: 600;
  outline: none;
}

.renderDoubleSelect {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
}

.firstSelectContainer {
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: flex-start;
}

.secondSelectContainer {
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: flex-end;
}

.select {
  border: none;
  max-width: 110px;
}

.selectMenu {
  max-height: 200px;
  overflow-y: auto;
  width: auto;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-around {
  justify-content: space-around;
  align-items: center;
}

.flex-between {
  justify-content: space-between;
  align-items: center;
}

/* FieldWrapper */
.fieldWrapperActive {
  min-width: 260px;
  flex: 1;
  width: 100%;
  padding: 0 2.5% 0 5px;
  margin: 0px;
  border: none;
  border-radius: 4px;

  &[app-field-wrapper-variant="ungrouped"] {
    margin: 20px 0;
  }

  &[app-field-wrapper-active="active"] {
    border: 1px solid var(--app-color-gray-lighter);
    padding: 10px;
  }

  &[app-variant-has-error="true"] {
    border: 1px solid var(--app-color-red-error);
    padding: 10px;
  }

  .titleContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

  .checkMarkContainer {
    //  margin: 8px 8px 0 0;
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  .nonEditChildrenContainer {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

  .editParagraphContainer {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    width: 100%;
    /* backgroundColor: #FFFFF;  */
    border-radius: 8px;

    p {
      box-sizing: border-box;
      width: 100%;
      border: none;
      border-radius: 8px;
      font-family: var(--app-text-main-font);
      font-size: 30px;
      font-weight: 500;
      position: relative;
      padding: 0px 10px 0px 0px;
    }
  }
}

.fieldWrapperInactive {
  width: 100%;
  min-width: 260px;
  margin: 0px;
  padding: 0px;
  border: none;
  border: 1px solid var(--app-color-gray-lighter);
  border-radius: 4px;
  cursor: pointer;

  &[app-variant-inactive-ungrouped="true"] {
    margin: 20px 0;
  }

  &[app-variant-inactive-default-highlighted="true"] {
    padding: 10px;
    border: 1px solid var(--app-color-gray-lighter);
  }

  &[app-variant-inactive-with-error="true"] {
    border: 1px solid var(--app-color-red-error);
  }

  &[app-variant-inactive-read-only="true"] {
    cursor: text;
  }
}

.fieldWrapperInactiveImageContainer {
  display: flex;
  flex: 1;
  justify-content: space-around;
  padding: 20px;
}

.fieldWrapperInactiveImage {
  margin-right: auto;
  width: 18px;
  height: 18px;
}

.fieldWrapperInactiveSVG {
  width: 10px;
  height: 11px;
}

.fieldWrapperInactiveSpinnerContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.fieldWrapperInactiveText {
  font-family: var(--app-text-main-font);
  font-size: 17px;
  color: var(--app-color-gray-dark);

  &[app-variant-palceholder="true"] {
    color: var(--app-color-gray-dark);
  }
}

/* FieldWrapper */

/* ContainerWrapper */
.containerWrapper {
  flex: 1;
  width: 100%;
  padding: 0 5px 0 5px;
  margin: 0px;
  border: none;
  border-radius: "4px";

  &[app-variant-ungruouped="true"] {
    margin: 20px 0;
  }

  &[app-variant-bordered="true"] {
    border: 1px solid var(--app-color-gray-lighter);
  }
}

.containerWrapperTitleContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.containerWrapperAckButton {
  outline: none;
  border: none;
  background-color: transparent;
  // margin: 8px 8px 0 0;
  display: flex;
  width: 32px;
  height: 32px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  svg {
    width: 32px;
    height: 32px;
  }
}

.containerWrapperChildrenContainer {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  &[app-variant-columned="true"] {
    flex-direction: column;
  }
}

/* ContainerWrapper */

/* RenderYesNoSwitcher */
.renderYesNoSwitcherWrapper {
  flex: 1;
  width: 100%;
  padding: 0 5px 0 5px;
  margin: 0px;
  border: none;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 10px;

  &[app-variant-ungrouped="true"] {
    margin: 20px 0;
  }

  &[app-variant-bordered="true"] {
    border: 1px solid var(--app-color-gray-lighter);
  }
}

.renderYesNoSwitcherTitleContainer {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  max-width: 75%;
}

.renderYesNoSwitcherAckButton {
  outline: none;
  border: none;
  background-color: transparent;
  // margin: 8px 8px 0 0;
  display: flex;
  width: 32px;
  height: 32px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  svg {
    width: 32px;
    height: 32px;
  }
}

.renderYesNoSwitcherToggleContainer {
  max-width: 100px;
  margin-right: 20px;
  position: relative;
  z-index: 0;
}

/* RenderYesNoSwitcher */

/* RenderDynamicTextInput */
.renderDynamicTextInputWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
  background-color: var(--app-color-white-lightest);
  border-radius: 8px;
}

.renderDynamicTextInputTextarea {
  box-sizing: border-box;
  height: auto;
  max-height: 100px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: var(--app-color-white-lightest);
  font-family: var(--app-text-main-font);
  font-size: 1.1em;
  line-height: 1.3rem;
  font-weight: 500;
  resize: none;
  position: relative;
  padding: 10px;
  color: var(--app-color-gray-light);
}

.renderDinamicTextInpuLabel {
  position: absolute;

  left: 10px;
  right: 0;
  user-select: none;
  pointer-events: none;
  display: block;
  color: var(--app-color-gray-light);
  font-family: var(--app-text-main-font);
  font-size: 1rem;
  line-height: 1.3rem;
}

/* RenderDynamicTextInput */

/* RenderYesNoOptions */

.renderYesNoOptionsWrapper {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.renderYesNoOptionsWrapperYesContainer {
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
  text-align: center;
  width: 75px;
  height: 40px;
  border-radius: 20px;
  margin: 4px;
  background-color: var(--app-color-gray-lighter);

  &[app-variant-selected="true"] {
    background-color: var(--app-color-gray-dark);
  }
}

.renderYesNoOptionsWrapperText {
  font-family: var(--app-text-main-font);
  font-size: 16px;
  color: white;
}

.renderYesNoOptionsWrapperSVG {
  margin: 0 5px 0 5px;
  width: 15.38px;
  height: 15.38px;
}

.renderYesNoOptionsWrapperNoContainer {
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 75px;
  height: 40px;
  border-radius: 20px;
  margin: 4px;
  background-color: var(--app-color-gray-lighter);

  &[app-variant-selected="true"] {
    background-color: var(--app-color-gray-dark);
  }
}

/* RenderYesNoOptions */

/* RenderAddBulletField */
.renderAddBulletFieldWrapper {
  cursor: text;
  margin: 10px 0px 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
}

.renderAddBulletFieldInputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
  background-color: var(--app-color-white-lightest);
  border-radius: 8px;
}

.renderAddBulletFieldAnnotationWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.renderAddBulletFieldSaveButton {
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0 5px 0 5px;
  border-radius: 4px;
  background-color: var(--app-color-gray-dark);
  height: 32px;
  width: 79px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    align-self: center;
    color: var(--app-color-white-lightest);
  }

  p {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-white-lightest);
    padding-right: 10px;
  }
}

.renderAddBulletFieldInputStyle {
  outline: none;
  flex: 1;
  font-family: var(--app-text-main-font);
  font-size: 1em;
  line-height: 20px;
  color: var(--app-color-gray-dark);
  background-color: var(--app-color-white-lightest);
  border-radius: 8px;
  border: 0px;
  padding-left: 5px;

  &::placeholder {
    font-style: italic;
  }
}

.renderAddBulletFieldCancelbutton {
  outline: none;
  border: none;
  cursor: pointer;
  margin: 0 5px 0 25px;
  padding: 5px;
  border-radius: 4px;
  background-color: var(--app-color-gray-dark);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* RenderAddBulletField */

/* RenderBulletEntry */
.renderBulletEntryWrapper {
  flex: 1;
  cursor: text;
  margin: 3px 0px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
}

.renderBulletEntryContainer {
  height: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: var(--app-color-white-lightest);
  border-radius: 8px;

  &[app-variant-show-buttons="true"] {
    background-color: var(--app-color-gray-lighter_nuance4);
  }
}

.renderBulletEntryLabelContainer {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
}

.renderBulletEntryTextareaContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
  background-color: var(--app-color-white-lightest);
  border-radius: 8px;
}

.renderBulletEntryItemNumber {
  align-self: flex-start;
  font-family: var(--app-text-main-font);
  font-size: 1.1rem;
  color: var(--app-color-gray-dark);
  background-color: var(--app-color-white-lightest);
  outline: none;
  border-radius: 8px;
  border: 0px;
  padding: 0;

  &[app-variant-show-buttons="true"] {
    padding: 5px 0 0 5px;
    color: var(--app-color-gray-dark);
    background-color: var(--app-color-gray-lighter_nuance4);
  }
}

.renderBulletEntryTextarea {
  resize: none;
  height: unset;
  flex: 1;
  font-family: var(--app-text-main-font);
  font-size: 1.1rem;
  outline: none;
  border-radius: 8px;
  border: 0px;
  overflow-wrap: break-word;
}

.renderBulletEntryTextareaLabel {
  position: absolute;
  top: 35%;
  left: 10px;
  right: 0;
  user-select: none;
  pointer-events: none;
  display: block;
  color: var(--app-color-gray-light);
  font-family: var(--app-text-main-font);
  font-size: 1rem;
  line-height: 1.3rem;
}

.renderBulletEntryAnnotationWrapper {
  flex: 0.2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.renderBulletEntrySaveButton {
  padding: 0 5px 0 5px;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--app-color-white-lightest);
  height: 32px;
  width: 79px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    align-self: center;
  }

  p {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
    padding-right: 10px;
  }
}

.renderBulletEntryCancelButton {
  margin: 0 5px 0 25px;
  cursor: pointer;
  border-radius: 4px;
  border: 0px;
  padding: 5px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* RenderBulletEntry */
.selectMenuOverlay {
  max-height: 200px;
  overflow-y: auto;
  width: auto;
}

.multiSelectWithSearch {
  width: 100%;
}

.noBorder {
  width: 100%;
  border: none;
}

.renderBooleansOptions {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-white-lightest);
  }

  >div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    cursor: pointer;
    text-align: center;
    width: 115px;
    height: 40px;
    border-radius: 20px;
    margin: 4px;
    background-color: var(--app-color-gray-unselected);

    &[cont-variant="selected"] {
      background-color: var(--app-color-gray-selected);
    }
  }

  svg {
    margin: 2px 5px 0px 5px;
    width: 15.38px;
    height: 15.38px;
    filter: invert(0%) sepia(100%) saturate(0%) hue-rotate(50deg) brightness(300%) contrast(150%);
  }
}

.renderFieldOfComponent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  border: 1px solid #d2d2d2;
  border-radius: 4px;
  margin: 2px 5px 0 5px;

  >div:first-child {
    width: 40px;
    height: 40px;
    margin: 0 0 0 5px;
    background-color: var(--app-color-white-dark);
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin: 2px;
      filter: invert(0%) sepia(0%) saturate(5000%) hue-rotate(50deg) brightness(100%) contrast(50%);

      &[img-visibility="visible"] {
        // background-color: var(--app-color-error);
        filter: invert(30%) sepia(10%) saturate(0%) hue-rotate(50deg) brightness(30%) contrast(80%);
      }
    }
  }

  p {
    font-family: var(--app-text-main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    &[p-type="mandatory"] {
      font-weight: 500;
      color: var(--app-color-gray-light);
    }

    &[p-mandatory="active"] {
      color: var(--app-color-gray-dark);
    }

    &[p-visibility="visible"] {
      color: var(--app-color-gray-dark);
    }

    &[p-visibility="notvisible"] {
      color: var(--app-color-gray-light);
    }
  }

  .optionsContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    .mandatoryContainer {
      margin: 0 20px 0 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        align-self: center;
        margin: 1px 5px 0 0;
        max-width: 16px;
        max-height: 16px;
        border-radius: 16px;
        filter: invert(30%) sepia(10%) saturate(0%) hue-rotate(50deg) brightness(70%) contrast(80%);
      }

      .notMandatory {
        margin: 1px 5px 0 0;
        width: 16px;
        height: 16px;
        border-radius: 16px;
        border: 1px solid #d2d2d2;
      }
    }

    .visibilityContainer {
      margin: 0 20px 0 5px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      svg {
        margin: 1px 5px 0 0;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.renderComponent {
  background-color: var(--app-color-white-lightest);
  width: auto;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  border-top-left-radius: 8px;
  border-top-right-radius: 35px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 5px 0 5px;
  padding: 10px;
}

.cw_withValidation {
  flex: 1;
  width: 100%;
  padding: 0 5px 0 5px;
  margin: 0px;
  border: none;
  border-radius: 4px;

  &[app-variant-ungrouped="ungrouped"] {
    margin: 20px 0;
  }

  &[app-variant-bordered="bordered"] {
    border: 1px solid #d2d2d2;
    padding: 8px;
  }

  .header {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &[app-variant-with-ack-button="withAckButton"],
  &[app-variant-hasError="error"] {
    .header {
      padding: 5px 0;
    }
  }

  &[app-variant-has-error="true"] {
    border: 1px solid var(--app-color-error);

    padding: 5px;
  }

  .acknowledgeContainer {
    display: flex;
    width: 32px;
    height: 32px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .acknowledgeIcon {
    width: 32px;
    height: 32px;
  }

  .childrenContainer {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

  &[app-variant-columned="columned"] .childrenContainer {
    flex-direction: column;
  }
}

.doubleSelectFirstSelectContainer {
  border: none;
  align-items: center;
  display: flex;
  justify-content: center;
}

.doubleSelectFirstSelectMOL {
  width: 100%;
  margin-top: 2px;
  top: 100%;
}

.doubleSelectFirstSelectMOLI {
  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  display: flex;
  align-items: center;
  justify-content: center;
}

.doubleSelectFirstSelectInputContainer {
  height: 35px;
}

.doubleSelectFirstSelectInput {
  width: auto;
  max-width: 85px;
}

.annotationWrapper {
  margin: 10px 0 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .saveButton {
    padding: 0 5px 0 5px;
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--app-color-gray-dark);
    color: var(--app-color-white-dark);
    height: 32px;
    width: 79px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .cancelButton {
    margin: 0 10px 0 10px;
    padding: 0 5px 0 5px;
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--app-color-white-dark);
    color: var(--app-color-gray-dark);
    height: 32px;
    width: 79px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .removeButton {
    margin: 0 10px 0 10px;
    padding: 0 5px 0 5px;
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--app-color-white-dark);
    color: var(--app-color-gray-dark);
    height: 32px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .shareWithButton {
    padding: 0 5px 0 5px;
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--app-color-gray-dark);
    color: var(--app-color-white-dark);
    height: 32px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

/* RenderListFramedEntry */
.renderListFramedEntryActiveWrapper {
  flex: 1;
  width: "100%";
}

.renderListFramedEntryActiveItem {
  padding: 0 0 0 2.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
}

.renderListFramedEntryRenderAddBulletFieldWrapper {
  padding: 0 0 0 2.5%;
}

.renderListFramedEntryEmptyItem {
  box-sizing: border-box;
  width: 100%;
  border: none;
  border-radius: 8px;
  color: var(--app-color-gray-light);
  font-family: var(--app-text-main-font);
  font-size: 1.1em;
  font-weight: 500;
  position: relative;
  padding: 0px 10px 0px 0px;
}

.renderListFramedEntryInactiveWrapper {
  width: 100%;
  padding: 0px;
  border: none;
  border-radius: 4px;
}

.renderListFramedEntryInactiveItemContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  margin: 0;
}

.renderListFramedEntryInactiveItem {
  box-sizing: border-box;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: var(--app-color-white-lightest);
  color: var(--app-color-gray-dark);
  font-family: var(--app-text-main-font);
  font-size: 1.1em;
  line-height: 1.3rem;
  font-weight: 500;
  resize: none;
  position: relative;
  padding: 10px 10px 10px 10px;
  margin: 0;
}

/* RenderListFramedEntry */

/* ButtonWithTooltipRender */
.buttonWithTooltipRenderWrapper {
  position: relative;
}

.buttonWithTooltipRenderRemoveButton {
  margin: 0 10px 0 10px;
  padding: 0 5px 0 5px;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--app-color-white-dark);
  color: var(--app-color-gray-dark);
  height: 32px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.buttonWithTooltipRenderRemoveButtonIcon {
  width: 16px;
  height: 16px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  align-self: center;
}

.buttonWithTooltipRenderParagraph {
  color: var(--app-color-gray-dark);
  font-family: var(--app-text-main-font);
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  vertical-align: middle;
  padding-right: 10px;
}

/* ButtonWithTooltipRender */

/* Render Position People */
.renderPositionsPeopleActiveWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding-bottom: 20px;
}

.renderPositionsPeopleActiveContainer {
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  width: 100%;
  overflow-x: auto;
}

.renderPositionsPeopleActiveChunksContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: flex-start;
  margin: 0 0 0 20px;
}

.renderPositionsPeopleActiveChunkWrapper {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: flex-start;
}

.renderPositionsPeopleActiveChunkContainer {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: #d2d2d2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.renderPositionsPeopleActiveChunkContainer {
  margin: 0;
  padding: 0;
  font-family: var(--app-text-main-font);
  font-weight: 900;
  font-size: 1.7em;
  color: var(--app-color-white-lightest);
}

.renderPositionsPeopleActiveChunkItemCheckmark {
  margin: 0;
  padding: 0;
  font-family: var(--app-text-main-font);
  font-weight: 900;
  font-size: 1.5em;
  color: var(--app-color-white-lightest);
}

.renderPositionsPeopleActiveChunkItemParagraph {
  color: var(--app-color-gray-dark);
  font-family: var(--app-text-main-font);
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  vertical-align: middle;
  margin-left: 10px;
}

.renderPositionsPeopleActiveAddEmployeeContainer {
  padding: 0 0 0 15px;
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  align-items: center;
}

.renderPositionsPeopleActiveAddEmployeeMultiselectContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  border: 1px solid var(--app-color-gray-lighter);
  //border-radius: 8px;
  padding: 5px;
}

.renderPositionsPeopleActiveAddEmployeeMultiSelectWithSearchInput {
  height: auto;
}

.renderPositionsPeopleActiveAddEmployeeMultiSelectWithSearch {
  width: 100%;
}

.renderPositionsPeopleActiveAddEmployeeMenuOverlay {
  width: 100%;
  align-items: stretch;
  top: 100%;
}

.renderPositionsPeopleActiveAddEmployeeMenuItem {
  width: auto;
  padding: 10px;
  cursor: pointer;
  align-items: stretch;
  font-size: 17px;
  font-family: var(--app-text-main-font);
}

.renderPositionsPeopleActiveAddEmployeeControls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: stretch;
  align-items: center;
}

.renderPositionsPeopleActiveAddEmployeeAnnotationsWrapper {
  margin: 10px 0 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.renderPositionsPeopleActiveAddEmployeeSaveButton {
  padding: 0 5px 0 5px;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--app-color-gray-dark);
  color: var(--app-color-white-dark);
  height: 32px;
  width: 79px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    align-self: center;
  }

  p {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-white-lightest);
    padding-right: 10px;
  }
}

.renderPositionsPeopleActiveAddEmployeeCancelButton {
  margin: 0 10px 0 10px;
  padding: 0 5px 0 5px;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--app-color-white-dark);
  color: var(--app-color-gray-dark);
  height: 32px;
  width: 79px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  p {
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 1em;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    vertical-align: middle;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.renderPositionsPeopleActiveDefaultControlsContainer {
  // padding: 0 0 0 2.5%;
  display: flex;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.renderPositionsPeopleActiveDefaultControlsAnnotationWrapper {
  margin: 10px 0 10px 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.renderPositionsPeopleActiveDefaultControlsSaveButton {
  padding: 0 5px 0 5px;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--app-color-white-dark);
  color: var(--app-color-white-dark);
  height: 32px;
  width: 79px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    align-self: center;
    color: var(--app-color-gray-dark);
  }

  p {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
    padding-right: 10px;
  }
}

.renderPositionsPeopleActiveDefaultControlsShareWidthContainer {
  margin: 10px 0 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.renderPositionsPeopleActiveDefaultControlsShareWidthButton {
  padding: 0 5px 0 5px;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--app-color-gray-dark);
  color: var(--app-color-white-dark);
  height: 32px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  img {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    align-self: center;
  }

  p {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-white-lightest);
    padding-right: 10px;
  }
}

.renderPositionsPeopleInactiveContainer {
  padding: 0 0 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  width: 100%;
  overflow-x: auto;
}

.renderPositionsPeopleInactiveChunksContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: flex-start;
  margin: 0 0 0 20px;
}

.renderPositionsPeopleInactiveChunkWrapper {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: flex-start;
}

.renderPositionsPeopleInactiveChunkContainer {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: var(--app-color-gray-lighter);
  display: flex;
  justify-content: center;
  align-items: center;
}

.renderPositionsPeopleInactiveItemCheckmark {
  margin: 0;
  padding: 0;
  font-family: var(--app-text-main-font);
  font-size: 1.7em;
  color: var(--app-color-white-lightest);
}

.renderPositionsPeopleInactiveItem {
  color: var(--app-color-gray-dark);
  font-family: var(--app-text-main-font);
  font-size: 1em;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  vertical-align: middle;
  margin-left: 10px;
}

.renderPositionsPeopleEmptyContainer {
  display: flex;

  div {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background-color: var(--app-color-gray-lighter);
    margin: 0px 0 10px 30px;
  }

  p {
    margin-top: 3px;
    margin-left: 10px;
    font-size: 14px;
  }
}

/* Render Position People */

/* RenderSharedResourcesForPosition */
.renderSharedResourcesForPositionActiveWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  width: 100%;
  gap: 10px;
}

.renderSharedResourcesForPositionActiveContainer {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.renderSharedResourcesForPositionActiveColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  margin: 0 0 0 20px;
  padding: 10px;
  background-color: var(--app-color-gray-lighter_nuance1);
  border-radius: 10px;
}

.renderSharedResourcesForPositionActiveColumnTitle {
  font-family: var(--app-text-main-font);
  color: var(--app-color-gray-light);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  vertical-align: middle;
  margin-left: 10px;
}

.renderSharedResourcesForPositionActiveColumnItem {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  align-self: flex-start;
  width: 100%;
}

.renderSharedResourcesForPositionActiveColumnItemMark {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: #d2d2d2;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
    font-family: var(--app-text-main-font);
    font-size: 1.5em;
    color: var(--app-color-white-lightest);
  }
}

.renderSharedResourcesForPositionActiveColumnItemText {
  flex: 1;

  p {
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 1em;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    vertical-align: middle;
    margin-left: 10px;
  }
}

.renderSharedResourcesForPositionActiveColumnItemCloseButton {
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  align-self: stretch;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 24px;

  img {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    align-self: center;
    margin-top: 5px;
  }
}

.renderSharedResourcesForPositionActiveColumnAddResourcesContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  align-self: stretch;
  padding: 0 0 10px 10px;
  flex-wrap: wrap;
}

.renderSharedResourcesForPositionActiveColumnMultiSelectWrapper {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.renderSharedResourcesForPositionActiveColumnMultiselect {
  width: 100%;
  height: 60px;
}

.renderSharedResourcesForPositionActiveColumnMenuOverlay {
  width: 100%;
  align-items: stretch;
  top: 70%;
}

.renderSharedResourcesForPositionActiveColumnMenuItem {
  width: auto;
  padding: 10px;
  cursor: pointer;
  align-items: stretch;
  border-radius: 0px;
}

.renderSharedResourcesForPositionActiveColumnControls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.renderSharedResourcesForPositionActiveColumnControlsAnnotationWrapper {
  margin: 10px 0 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .cancelButton {
    margin: 0 10px 0 10px;
    padding: 0 5px 0 5px;
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--app-color-white-dark);
    color: var(--app-color-gray-dark);
    height: 32px;
    width: 79px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .removeButton {
    margin: 0 10px 0 10px;
    padding: 0 5px 0 5px;
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--app-color-white-dark);
    color: var(--app-color-gray-dark);
    height: 32px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .shareWithButton {
    padding: 0 5px 0 5px;
    cursor: pointer;
    border-radius: 4px;
    background-color: var(--app-color-gray-dark);
    color: var(--app-color-white-dark);
    height: 32px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.renderSharedResourcesForPositionActiveColumnControlsSaveButton {
  outline: none;
  border: none;
  padding: 0 5px 0 5px;
  cursor: pointer;
  background-color: var(--app-color-gray-dark);
  color: var(--app-color-white-dark);
  height: 32px;
  width: 79px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    align-self: center;
    color: var(--app-color-white-lightest);
  }

  p {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-white-lightest);
    padding-right: 10px;
  }
}

.renderSharedResourcesForPositionActiveColumnControlsCancelButton {
  outline: none;
  border: none;
  margin: 0 10px 0 10px;
  padding: 0 5px 0 5px;
  cursor: pointer;
  background-color: var(--app-color-gray-lighter_nuance1);
  color: var(--app-color-gray-light);
  height: 32px;
  width: 79px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  p {
    font-family: var(--app-text-main-font);
    font-size: 1em;
    font-weight: 500;
    letter-spacing: 0;
    text-align: center;
    vertical-align: middle;

    color: var(--app-color-gray-light);
    padding-left: 10px;
    padding-right: 10px;
  }
}

.renderSharedResourcesForPositionActiveColumnAddContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  align-self: stretch;
  padding: 0;
  flex-wrap: wrap;
}

.renderSharedResourcesForPositionActiveColumnAddContainerAnnotationWrapper {
  margin: 10px 0 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.renderSharedResourcesForPositionActiveColumnAddContainerSaveButton {
  padding: 0 5px 0 5px;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--app-color-gray-lighter_nuance1);
  height: 32px;
  width: 79px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    align-self: center;
    color: var(--app-color-gray-dark);
  }

  p {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
    padding-right: 10px;
  }
}

.renderSharedResourcesForPositionActiveColumnAddContainerCancelButton {
  margin: 0 10px 0 10px;
  padding: 0 5px 0 5px;
  cursor: pointer;
  border-radius: 0px;
  background-color: var(--app-color-white-dark);
  color: var(--app-color-gray-dark);
  height: 32px;
  width: 79px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.renderSharedResourcesForPositionInactiveWrapper {
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  align-content: flex-start;
  width: 100%;
  overflow-x: auto;
}

.renderSharedResourcesForPositionInactiveColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: flex-start;

  margin: 0 0 0 20px;
  padding: 10px;
  background-color: var(--app-color-gray-lighter_nuance1);
  border-radius: 10px;
}

.renderSharedResourcesForPositionInactiveColumnTitle {
  font-family: var(--app-text-main-font);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
  vertical-align: middle;
  margin-left: 10px;
  color: var(--app-color-gray-light);
}

.renderSharedResourcesForPositionInactiveColumnItemWrapper {
  cursor: pointer;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
}

.renderSharedResourcesForPositionInactiveColumnMarkerContainer {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background-color: var(--app-color-gray-lighter);
  display: flex;
  justify-content: center;
  align-items: center;
}

.renderSharedResourcesForPositionInactiveColumnMarker {
  margin: 0;
  padding: 0;
  font-family: var(--app-text-main-font);
  font-size: 1.5em;
  color: var(--app-color-white-lightest);
}

.renderSharedResourcesForPositionInactiveColumnItem {
  font-family: var(--app-text-main-font);
  font-size: 1em;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
  vertical-align: middle;
  margin-left: 10px;
  color: var(--app-color-gray-dark);
}

.renderSharedResourcesForPositionEmptyContainer {
  padding: 10px 0 10px 29px;
  display: flex;

  div {
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background-color: var(--app-color-gray-lighter);
  }

  p {
    margin-top: 3px;
    margin-left: 10px;
    font-size: 16px;
  }
}

/* RenderSharedResourcesForPosition */

/* SimpleContainerWrapper */
.simpleContainerWrapperHost {
  flex: 1;
  width: 100%;
}

.simpleContainerWrapperTitleContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}

.simpleContainerWrapperTitleText {
  font-family: var(--app-text-main-font);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--app-color-gray-unselected);
  margin: 0;
  padding: 0;
}

.simpleContainerWrapperChildrenContainer {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  &[app-variant-columned="true"] {
    flex-direction: column;
  }
}

/* SimpleContainerWrapper */
.test {
  background-color: red;
}