.headerTopBar{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
}
.headerTopBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    padding-right: 10px;
    width: 32px;
    filter: invert(27%) sepia(0%) saturate(41%) hue-rotate(180deg) brightness(59%) contrast(81%);
  }

  p {
    font-family: var(--app-text-main-font), serif;
    font-size: 2rem;
    line-height: 2.5rem;
    color: var(--app-color-gray-dark);
  }

  .testFill {
    fill: black !important;
  }
}
.loadingContainer{
  align-self: center;
  padding-top: 10px;
}