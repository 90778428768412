.host {
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 8px;
    &[app-variant-has-error="error"] {
        border: 1px solid var(--app-color-error);
    }
}
.tagsContainer {
    display: flex;
    flex-wrap: wrap;
}

.toggleMenuControlsIcons {
    display: inline-block;
    margin-left: 10px;
    width: 12px;
    height: 12px;
    stroke-width: 0;
    color: var(--app-color-gray-light);
}
.menuOverlayList {
    position: absolute;
    box-shadow: 0px 0px 30px #0000001a;
    list-style: none;
    padding-left: 0;
    &:focus,
    &:focus-within {
        outline: none;
    }

    z-index: 1;
    overflow-y: auto;
    border-radius: 10px;
    background-color: var(--app-color-white-lightest);
    display: flex;
    flex-direction: column;
    max-height: 250px;
    transition: transform 0.6s ease;
    width: 100%;
    top: 75%;
}
.menuOverlayListItem {
    cursor: pointer;
    color: var(--app-color-gray-light);
    background-color: transparent;
    transition: transform 0.6s ease;
    display: flex;
    align-items: center;
    padding: 10px;
    &[app-variant="selected"]:not([app-item-type="checkboxes"]) {
        color: var(--app-color-gray-dark);
       // background-color: var(--app-color-gray-lighter);
    }
    &[app-variant="highlighted"]:not([app-item-type="checkboxes"]) {
        color: var(--app-color-gray-dark);
        background-color: var(--app-color-gray-lighter);
    }
    &[app-variant="highlighted"][app-item-type="checkboxes"] {
        font-weight: 900;
        font-size: 18px;
    }
    &[app-item-type="normal"] {
        justify-content: space-between;
    }
}

.toggleLeftIconContainer {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.toggleLeftIcon{
    width: 20px;
    height: 20px;
}
