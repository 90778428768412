.hair-check {
  background: white;
  padding: 1rem;
  border-radius: 4px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;

}

.hair-check video {
  height: 80vh;
}

.hair-check label {
  display: block;
  color: var(--dark-grey);
  font-size: 16px;
  margin: 1em 0 0.5em 0;
  line-height: 14px;
}

.hair-check input,
.hair-check select {
  border: 1px solid var(--grey);
  padding: 0.5em;
  border-radius: 4px;
  color: var(--dark-blue);
  width: 100%;
  font-size: 16px;
  

}

.hair-check h1 {
  font-size: 16px;
  text-align: center;
  margin-bottom: 1em;
  color: var(--dark-blue);
}



.cancel-call {
  background: var(--white);
}
