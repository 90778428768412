body {
    padding: 0;
    margin: 0;
  }
  
  .App {
    height: 100vh;
    width: 100%;
  }
  
  .circle {
    // background: #d83b01;
    // border-radius: 50%;
    // color: #fff;
    // height: 2.5em;
    // position: relative;
    //width: 200px;
  
    // border: 1px solid transparent;
    //color: red;
  }
  
  .circleText {
    text-align: center;
    height: 50%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  .fieldTypeInput {
    ::placeholder {
      font-family: var(--app-text-main-font), serif;
      font-size: 16px;
      font-weight: 700;
      color: var(--app-color-error);
    }
  
    width: 96%;
    border: 0;
    height: 37px;
    font-family: var(--app-text-main-font),
    serif;
    font-size: 16px;
    font-weight: 700;
    color: var(--app-color-gray-dark);
  }
  
  .selectText {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
  }




 
.inputField {
  font-family: var(--app-text-main-font);
  font-size: 16px;
  color: var(--app-color-gray-dark);
  //border-radius: 2px;
  border: 1px solid var(--app-color-gray-lighter);
  width: 100%;
  outline: none;
  height: 56px;
  margin-top: 5px;
  margin-left: 0;
  padding: 0 10px 0 10px;
}

.industryMOL {
  padding: 10px;
  left: -5px;
  top: calc(100% - 12px);
}
.industryMOLI {
  padding: 5px;
  //border-radius: 5px;
}

//  /* region Company Name */
// .rowsField {
//   font-family: var(--app-text-main-font);
//   font-size: 16px;
//   color: var(--app-color-gray-dark);
//   border-radius: 2px;
//   width: 100%;
//   outline: none;
//   height: 56px;
//   margin-top: 5px;
//   margin-left: 0;
//   padding: 0 10px 0 10px;
// }
// .inputFieldOverlay {
//   font-family: var(--app-text-main-font);
//   font-size: 16px;
//   color: var(--app-color-gray-dark);
//   border-radius: 2px;
//   //border: 1px solid var(--app-color-gray-lighter);
//   width: 100%;
//   outline: none;
//   height: 56px;
//   margin-top: 5px;
//   margin-left: 0;
//   padding: 0 10px 0 10px;
// }
// /* endregion Company Name */

// /* region DateInputContainer*/
// .foundingDateInputContainer {
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   align-items: stretch;
//   border-radius: 2px;
//   height: 56px;
//   border: 1px solid;
//   border-color: #d2d2d2;
//   margin-top: 5px;
// }
// /* endregion DateInputContainer*/

// /*  region Select Employees */

// .employeesMOL{
//   left: 0px;
//   top: calc(100% - 12px);
//   width: inherit;
// }
// /*  endregion Select Employees */

// /* region Select industry */

// /* endregion Select industry */
