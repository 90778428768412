.firstDiv_VII {
    position: relative;
    display: flex;
    height: 96%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 2% 5% 2% 5%;
}
.logoImg_VII {
    position: absolute;
    left: -60px;
    top: 6px;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
}
.headerContainer_VII {
    margin: 20px 0 0px 0;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: stretch;
    img {
        align-self: center;
        height: 30px;
        width: 32px;
    }
}
.header_VII {
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 2em;
    //  line-height: 2em;
    margin: 0;
    padding: 0 0 0 10px;
    text-align: left;
}
.subheadingContainer_VII {
    align-self: stretch;
    font-family: var(--app-text-main-font);
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 16px;
    padding-left: 10px;
    justify-content: center;
    align-items: center;

    .padH50 {
        padding: 0 50px 0 50px !important;
    }
    .centered {
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    p {
        margin: 0px 0 16px 0;
        color: var(--app-color-gray-dark);
        font-family: var(--app-text-main-font);
        font-size: 1rem;
        line-height: 1.3rem;
        text-align: left;
        padding: 0 0 0 0;
    }
}
.subheading_VII {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--app-color-gray-light);
    padding-bottom: 15px;
}
.justifiedLeft_VII {
    text-align: left;
    justify-content: flex-start;
    align-items: center;
}
.departmentsContainer_VII {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
}

.closeContainer_VII {
    position: absolute;
    top: -19px;
    right: 19px;
}
.closeElement_VII {
    cursor: pointer;
    background-color: transparent;
    border: 0;
}
.closeIcon_VII {
    width: 48px;
    height: 48px;
}

.tutorialWrapper_VII {
    position: absolute;
    top: 1.5em;
    right: -68.5px;
    height: 62px;
    width: 137px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}
.tutorialContainer_VII {
    margin: 12px;
    cursor: pointer;
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.tutorialImg_VII {
    width: 11.58px;
    height: 15.98px;
}
.tutorialTextContainer_VII {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.tutorialText_VII {
    margin: 0 0 0 0;
    height: 24px;
    width: 61px;
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    line-height: 1.3rem;
    text-align: center;
}
.tutorialDuration_VII {
    margin: 0 0 0 0;
    height: 24px;
    width: 61px;
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
}
.tutorialStyle_VII {
    z-index: 1;
    cursor: grab;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #4a4a4a75;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.reactPlayerStyle_VII {
    border-radius: 16px;
    overflow: hidden;
}
.buttonWrapper_VII {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
.backToPreviousButtonContainer_VII {
    cursor: pointer;
    align-self: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
        opacity: 0.5;
        color: var(--app-color-gray-light);
        width: 22px;
        height: 22px;
        margin-right: 10px;
    }
    p {
        min-width: 135px;
        color: var(--app-color-gray-light);
        font-family: var(--app-text-main-font);
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
    }
}
.buttonContainer_VII {
    position: relative;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.skipThisStepContainer_VII {
    cursor: pointer;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    //min-width: 110px;
    margin: auto;
}
.skipThisStepText_VII {
    color: var(--app-color-gray-light);
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    line-height: 1.3rem;
    min-width: 110px;
}

.host_DC {
    cursor: pointer;
    padding: 0px, 3px, 10px, 13px;
    margin: 10px;
}
.wrapperStyle_DC {
    position: relative;    
    height: 50px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
}
.mainContainerStyle_DC {
    margin: 0 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.departmentWrapperStyle_DC {
    max-width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.departmentNameContainer_DC {

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.departmentNameStyle_DC {
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 1.5rem;
    line-height: 1.7rem;
    max-width: 100%;
    flex-wrap: wrap;
    overflow: hidden;
    border: none;
    outline: none;
}
.annotationWrapperStyle_DC {
    height: 60%;
    margin: 0px 5px;
    max-width: 30%;
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.annotationContaierStyle_DC {
    margin: 0 5px 0 5px;
    border-radius: 4px;
    background-color: #ffffff;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.annotationTextStyle_DC {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
    padding-right: 10px;
}
.annotationImageStyle_DC {
    margin: 0 10px 0 10px;
    width: 24px;
    height: 24px;
    opacity: 0.5;
    align-self: center;
}
.cancelButtonStyle_DC {
    height: 100%;
    cursor: pointer;
    border-radius: 4px;
    border: 0px;
    padding: 0px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapperStyle_VII {
    position: relative;
    height: 56px;
    border-radius: 8px;
    background-color: var(--app-color-gray-dark);
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
}
.mainContainerStyle_VII {
    margin: 0 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.departmentWrapperStyle_VII {
    flex: 3;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.departmentColorStyle_VII {
    border: 1px solid #ffffff;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    &[app-variant-add-dep="true"] {
        margin-left: 10px;
    }
}
.departmentInputStyle_VII {
    border: none;
    max-width: 92%;
    outline: none;
    caret-color: #ffffff;
    background-color: var(--app-color-gray-dark);
    margin: 0 0 0 16px;
    height: 45px;
    color: #ffffff;
    font-family: var(--app-text-main-font);
    font-size: 1.5rem;
    line-height: 1.7;
}
.annotationWrapperStyle_VII {
    height: 60%;
    margin: 0px 5px;
    max-width: 30%;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.saveButtonStyle_VII {    
    cursor: pointer;
    border-radius: 4px;
    background-color: #ffffff;
    height: 32px;
    width: 75px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}
.cancelButtonStyle_VII {
    height: 18px;
    cursor: pointer;
    border-radius: 4px;
    border: 0px;
    padding: 0px 15px;
    display: block;
}

.addDepartmentButtonContainer_VII {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.addDepartmentButton_VII {
    cursor: pointer;
    height: 35px;
    border-width: 0;
    border-radius: 4px;
    padding: 0 10px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.addDepartmentButtonIcon_VII {
    width: 16px;
    height: 16px;
    margin: 0 8px 0 8px;
    text-align: center;
    fill: #4a4a4a;
    stroke: 3px;
}
.addDepartmentButtonText_VII {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    line-height: 20px;
    color: var(--app-color-gray-dark);
}

.warningDeletionContainer_VII {
    z-index: 999999;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .warning_VII {
        color: var(--app-color-gray-dark);
        font-family: var(--app-text-main-font);
        font-size: 32px;
        font-weight: 500;
        letter-spacing: 0;
        margin: 0;
        padding: 0;
    }
    .warningAnnotation_VII {
        margin: 10px;
        padding: 0;
        p {
            color: var(--app-color-gray-dark);
            font-family: var(--app-text-main-font);
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            margin: 0;
            padding: 0;
        }
    }
    .warningInformation_VII {
        color: var(--app-color-gray-dark);
        font-family: var(--app-text-main-font);
        font-size: 1.1em;
        font-weight: 500;
        letter-spacing: 0;
        text-align: center;
        max-width: 400px;
    }
    .buttonContainer_VII {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}


//delete department


.deleteModalContainer {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 2% 7% 2% 7%;
}
.deletePositionContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.deletePosition_title {
    height: 40px; 
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
}
.deletePosition_description {
    align-self: flex-start;
    height: 20px;
    width: 252px;
    color: var(--app-color-gray-light);
    font-family: var(--app-text-main-font);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
}
.deletePosition_checkboxContainer {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0 10px 0;
}
.deletePosition_buttonContainer {
    svg {
        margin-left: 10px;
        padding: 1px 1px;
        width: 24px;
        height: 24px;
        color: var(--app-color-white-lighter);
    }
}

.warningDeletionContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: "100%";
}
.warningDeletion_warning {
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    margin: 0;
    padding: 0;
}
.warningDeletion_warningAnnotation {
    margin: 10px;
    padding: 0;

    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin: 0;
    padding: 0;
}
.warningDeletion_warningInformation {
    margin-top: 24px;
    max-width: 286px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        color: var(--app-color-gray-dark);
        font-family: var(--app-text-main-font);
        font-size: 1.1em;
        font-weight: 500;
        letter-spacing: 0;
        text-align: center;
        max-width: 400px;
    }
}
.warningDeletion_buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    svg {
        margin-left: 10px;
        padding: 1px 1px;
        width: 24px;
        height: 24px;
        color: var(--app-color-white-lighter);
    }
}
.loadingContainer {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 0 10px 0 10px;
    min-width: 250px;
    min-height: 250px;
}
.resultOperationWrapper {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 2% 7% 2% 7%;
}
.resultOperationContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.resultContainer {
    width: 70%;
    margin: 5px 0 20px 0;
}
.resultText {
    color: #4a4a4a;
    font-family: var(--app-text-main-font);
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    padding: 0;
    margin: 0;
}
.buttonWrapper_III {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 0 0;
}
