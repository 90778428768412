.host {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 57px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    width: 100%;
}
.dateTimePickerTrigger {
    flex: 1;
    width: auto;
    position: absolute;
    z-index: 99999;
}
.dateAutosizeInput {
    height: 37px;
    margin: auto;
    border: none;
    outline: none;
    width: 100%;
    padding: 1px 2px 1px 10px;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-dark);
}
.errorGeneralText {
    margin: 10px 0 0 0;
    text-align: left;
    color: var(--app-color-error);
    font-family: var(--app-text-main-font);
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 0.8rem;
}