.host {
    display: flex;
    align-items: center;
    
    background-color: var(--processmaker-table-th-background-color);
    font-family: var(--app-text-main-font);    
    font-weight: 500;
    width: 100%;
    height: 50px;
    text-align: center;
    font-family: var(--app-text-main-font), serif;
    font-size: 12px;
    color: var(--app-color-gray-light);
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 5px;;
}

.headerTopBarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;

    p {
        margin-left: 10px;
        font-family: var(--app-text-main-font), serif;
        font-size: 16px;
        color: white;
    }

}


.closeContainer {
    // position: absolute;    
    // right: 15px;
    // bottom:3px;
    
}
.closeElement {
    cursor: pointer;
    background-color: transparent;
    border: 0;
}
.closeIcon {
    width: 26px;
    height: 26px;
}

.transparent {
    background-color: transparent;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  
    animation: fadeIn 0.5s;
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -o-animation: fadeIn 0.5s;
    -ms-animation: fadeIn 0.5s;
  }
