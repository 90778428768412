@use "../../../../../../styles//tools//truncate.scss" as textTransf;
.renderComponent {
    background-color: var(--app-color-white-lightest);
    width: auto;
    //box-shadow: 0 5px 20px 0 #0000001a;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 8px 5px 8px 5px;
    padding: 10px;
    &[app-variant="error"] {
        border: 1px solid var(--app-color-red-error);
    }
    border: 1px dashed var(--app-color-gray-light);
}
.wrapper {
    height: auto;
    max-height: 150px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.mainContainer {
    flex: 1;
    height: auto;
}
.firstRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
}
.pLabel {
    @include textTransf.textEllipsed(3);
    padding: 0 5px;
    margin: 0;
    font-family: var(--app-text-main-font);
    font-size: 1.2em;
    line-height: 1.2em;
    color: var(--app-color-gray-dark);
    text-align: left;
    cursor: pointer;
    text-decoration: none;
}
.pLabel:hover {
    text-decoration: underline;
}
.pLabelTestingOnly {
    padding: 0 0 0 10px;
    margin: 0;
    font-family: var(--app-text-main-font);
    font-weight: 400;
    font-size: 0.5em;
    color: var(--app-color-gray-light);
    text-align: right;
}
.icoContainer {
    margin-right: 10px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.secondRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 16px 0 12px 0;
    &[app-spacing-variant="narrow"] {
        margin: 4px 0 12px 0;
    }
}
.childrenWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.notifIcon {
    width: 16px;
    height: 16px;
}
.labels {
    padding-left: 5px;
    font-family: var(--app-text-main-font);
    font-size: 0.75em;
    line-height: 0.75em;
    color: var(--app-color-gray-light);
    text-align: left;
}
.compIconContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    margin-right: 10px;
    .compIco {
        width: 20px;
        height: 20px;
        margin-bottom: 10px;
    }
}
.rotatingSvg {
    width: 16px;
    height: 16px;
    transform: rotate(0deg);
    transition: transform 0.6s ease;
    .renderComponent[comp-in-edit-mode="true"] & {
        transform: rotate(-180deg);
        transition: transform 0.6s ease;
    }
}
/*  render field of component */
.renderFieldOfComponent {
    --comp-color-active: var(--app-color-gray-dark);
    --comp-color-inactive: var(--app-color-gray-light);

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    border: 2px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    margin: 2px 5px 2px 5px;
    &[app-variant="read-only"] {
        color: var(--app-color-red-error);
    }
}
.leftIconContainer {
    width: 30px;
    height: 30px;
    margin: 0 5px 0 5px;
    background-color: var(--app-color-white-dark);
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.leftItemImage {
    margin: 2px;
    height: 15px;
    width: 15px;
    filter: invert(0%) sepia(0%) saturate(5000%) hue-rotate(50deg) brightness(100%) contrast(50%);
    .renderFieldOfComponent[app-color="active"] & {
        filter: invert(30%) sepia(10%) saturate(0%) hue-rotate(50deg) brightness(30%) contrast(80%);
    }

    .renderFieldOfComponent[app-color="inactive"] &,
    .renderFieldOfComponent[app-variant="read-only"] & {
        filter: invert(0%) sepia(0%) saturate(5000%) hue-rotate(50deg) brightness(100%) contrast(50%);
    }
}
.inheritedFieldNameContainer {
    display: flex;
    padding-left: 10px;
    width: 160px;
}
.inheritedFieldName {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-family: var(--app-text-main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    &:first-letter {
        text-transform: uppercase;
    }
    .renderFieldOfComponent[app-color="active"] &,
    .renderFieldOfComponent[app-variant="editable"] & {
        color: var(--comp-color-active);
    }
    .renderFieldOfComponent[app-color="inactive"] & {
        color: var(--comp-color-inactive);
    }
    .renderFieldOfComponent[app-color="inactive"][app-variant="editable"] & {
        color: var(--comp-color-inactive);
    }
}
.inheritedFieldMandatoryMark {
    padding-left: 5px;
    font-family: var(--app-text-main-font);
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--app-color-red-error);
}
.optionsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}
.mandatoryContainer {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 95px;
}
.mandatoryIconContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.mandatorySVG {
    align-self: center;
    max-width: 16px;
    max-height: 16px;
    border-radius: 16px;
    filter: invert(30%) sepia(10%) saturate(0%) hue-rotate(50deg) brightness(70%) contrast(80%);
}
.notMandatory {
    width: 16px;
    height: 16px;
    border-radius: 16px;
    border: 1px solid var(--app-color-gray-lighter);
}
.mandatoryText {
    margin-left: 5px;    
    font-size: 10px;
    .renderFieldOfComponent[app-color="active"] &,
    .renderFieldOfComponent[app-variant="editable"] & {
        color: var(--comp-color-active);
    }

    .renderFieldOfComponent[app-variant="read-only"] & {
        color: var(--comp-color-inactive);
        font-style: italic;
    }
    .renderFieldOfComponent[app-color="inactive"] & {
        color: var(--comp-color-inactive);
    }
}

.typeText {
    margin-left: 5px; 
    padding: 10px; 
    font-size: 10px;
    //position: absolute;
    //left: 40%;
}
.visibilityContainer {
    margin: 0;
    padding: 0 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg {
        margin: 1px 5px 0 0;
        width: 16px;
        height: 16px;
    }
}
.renderComponents {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.noContentWrapperInhComponents {
    display: flex;
    width: 98%;
    border: 1px dashed var(--app-color-gray-lighter);
    border-radius: 8px;
    height: 56px;
    justify-content: center;
    align-items: center;
    margin: 1%;
    position: relative;
}

.noContentParagraphInhComponents {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 16px;
    line-height: 32px;
}
.noContentWrapperNewComponents {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.noContentWrapperContainerNewComponents {
    display: flex;
    width: 98%;
    border: 1px dashed var(--app-color-gray-lighter);
    border-radius: 8px;
    height: 56px;
    justify-content: center;
    align-items: center;
    margin: 1%;
}
.noContentContainerNewComponents {
    height: 32px;
    width: 162px;
    border-radius: 4px;
    background-color: var(--app-color-white-dark);
    cursor: pointer;
}
.noContentIconNewComponents {
    margin-right: 4px;
    margin-bottom: -2px;
    width:16px;
    height:16px;
    color:var(--app-color-gray-dark)
}
.noContentParagraphNewComponents {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 16px;
    line-height: 32px;
}

.firstRowControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.deleteNewComponentContainer {
    min-width: 95px;
    align-self: flex-end;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: var(--app-color-white-dark);
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    p {
        font-family: var(--app-text-main-font);
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: var(--app-color-gray-dark);
        padding: 0 10px;
        margin: 3px;
    }
    svg {
        width: 15px;
        height: 16px;
        margin-right: 10px;
    }
}

.multiselectComponent {
    display: flex;
    width: 100%;
}
.multiselectComponentTohhleMenuButton {
    p {
        text-align: left;
    }
}
.multiselectMenuOverlayList {
    display: flex;
}
.multiselectMenuOverlayListItem {
}

.menuOverlayList {
    left: 0;
    right: 0;
    margin-top: 5px;
}
.menuOverlayListItem {
    padding-left: 10px;
}

.renderSearchComponentsHost {
    display: flex;
    flex-direction: column;
}
.showSearchComponentContainer {
    display: table-row;
    &[app-display-variant="none"] {
        display: none;
    }
}
.showSearchComponentSelectWithSearchContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 10px 10px 60px 0 #0000001a;
    //border-radius: 12px;
    margin: 10px;
    position: relative;
}
.showSearchComponentSelectWithSearchAddComponentButton {
    outline: none;
    border: none;
    cursor: pointer;
    background-color: transparent;
    margin: 0px 10px;
    svg {
        width: 22px;
        height: 22px;
    }
}
.renderNewComponentSelectWithSearchContainer {
    border: none;
    outline: none;
}
.renderNewComponentSelectWithSearchInput {
    padding-left: 10px;
}
.renderNewComponentSelectWithSearchMOL {
    width: 100%;
    margin-top: 2px;
}
.renderNewComponentSelectWithSearchMOLI {
    padding-left: 10px;
}

.errorContainer {
    font-family: var(--app-text-main-font);
    font-size: 12px;
    color: var(--app-color-red-error);
    white-space: pre;
    word-break: break-all;
    align-self: center;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 10px 0;
}

.tLabel {
    @include textTransf.textEllipsed(3);
    padding: 0 5px;
    margin: 0;
    font-family: var(--app-text-main-font);
    font-size: 1.2em;
    line-height: 1.2em;
    color: var(--app-color-gray-light);
    text-align: left;
    margin-bottom: 10px;
    margin-top: 10px;
}
.peoplePositionsSelectBorderless {
    border: 0;    
    position: relative;
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 10px 0 10px;
    font-family: var(--app-text-main-font);
}
.peoplePositionsSelect {
    border: 1px solid var(--app-color-gray-lighter);     
    position: relative;
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 10px 0 10px;
    font-family: var(--app-text-main-font);
}
.peoplePositionsSelectInput {
    pointer-events: none;
    font-family: var(--app-text-main-font);
    
}
.peoplePositionsSelectMenuOverlay {
    display: flex;
    flex-direction: column;
    margin: 5px auto;
    max-height: 250px;
    width: auto;
    min-width: 100%;
    top: 100%;
}
.peoplePositionsSelectMenuOverlayItem {
    display: flex;

    align-items: center;
    padding: 10px;
    padding-left: 40px;
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
}

/* Accordion Width */
.listContainer {
    background-color: var(--app-color-white-lightest);
    border-radius: 8px;
    padding: 10px 10px 10px 10px;
    margin: 0 0 20px 0;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.1);
  
    &[app-variant-is-mobile="true"] {
      width: 100%;
      min-width: 100px;
    }
  
    &[app-variant-is-mobile="false"] {
      width: 80%;
      min-width: 94%;
    }
  }

  
.accordionContainer {
    overflow: hidden;
    margin: 0 0 0px 0;
    font-family: var(--app-text-main-font), serif;
    font-size: 18px;
    font-weight: 700;
    background-color: #FFFFFF;
    color: var(--app-color-gray-dark);
    align-items: center;
    border: 1px dashed var(--app-color-gray-light);
    border-radius: 8px;
    outline: none;
    transition: background-color 0.6s ease;
    width: 100%;
    &[app-variant-has-error="error"] {
      border: 1px solid var(--app-color-error);
    }
  }

  .title {
    font-family: var(--app-text-main-font), serif;
    font-size: 22px;
    font-weight: 700;
    color: white;
    background-color: var(--app-color-gray-dark);
    width: 100%;
  
  }
