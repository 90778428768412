.host {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 10px 0px 10px;
}

.leftColumn {
    position: fixed;
    left: 0;
    top: 20px;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    min-width: 150px;
    overflow: hidden;
}

.leftColumnHeader {
    display: flex;
    flex: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.backButtonContainer {
    align-self: flex-start;
    margin-top: 18px;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    margin-left: 30px;
    width: 25px;
    height: 25px;
}

.backIco {
    width: 24px;
    height: 24px;
}

.title {
    text-align: center;
    font-family: var(--app-text-main-font);
    font-size: 2em;
    font-weight: bold;
    color: var(--app-color-gray-dark);
    width: 100%;
    word-break: break-word;
    text-transform: capitalize;
}

.titleContainer {
    display: flex;
    justify-content: flex-start;
    flex: 1;

    p {
        margin-left: 10px;
        font-family: var(--app-text-main-font);
        font-size: 1.2em;
        line-height: 1.2em;
        text-align: left;
        color: var(--app-color-gray-dark);
        word-break: break-all;
    }
}

.componentControlsContainer {
    margin-bottom: 25%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
}

.componentControlsContainerText {
    margin: 0 0 0 34px;
    cursor: pointer;
}

.componentControlsText {
    text-align: left;
    color: var(--app-color-gray-dark);
    // white-space: pre;
    word-break: break-all;
    text-overflow: ellipsis;
}

.componentControlsIcon {
    margin-bottom: -5px;
    margin-right: 10px;
    color: var(--app-color-gray-dark);
    width: 22px;
    height: 22px;
}

.duplicateButtonContainer {
    margin-bottom: 30%;

    p {
        margin: 0 0 0 54px;
        text-align: left;
        color: var(--app-color-gray-dark);
        cursor: pointer;
    }

    .deleteIco {
        margin-bottom: -5px;
        margin-right: 10px;
        color: var(--app-color-gray-dark);
        width: 22px;
        height: 22px;
    }
}

.deleteButtonContainer {
    p {
        margin: 0 0 0 54px;
        text-align: left;
        color: var(--app-color-gray-dark);
        cursor: pointer;
    }

    .deleteIco {
        margin-bottom: -5px;
        margin-right: 10px;
    }
}

.leftColumn {
    position: fixed;
    left: 0;
    top: 20px;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25%;
    min-width: 150px;
    overflow: hidden;
}

.leftColumnHeader {
    display: flex;
    flex: 0;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.backButtonContainer {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    margin-left: 30px;
    width: 25px;
    height: 25px;
}

.backIco {
    width: 24px;
    height: 24px;
}

.titleContainer {
    display: flex;
    justify-content: flex-start;
    flex: 1;

    p {
        margin-left: 10px;
        font-family: var(--app-text-main-font);
        font-size: 1.2em;
        line-height: 1.2em;
        text-align: left;
        color: var(--app-color-gray-dark);
    }
}

.deleteButtonContainer {
    margin-bottom: 25%;

    p {
        margin: 0 0 0 54px;
        text-align: left;
        color: var(--app-color-gray-dark);
        cursor: pointer;
    }

    .deleteIco {
        margin-bottom: -5px;
        margin-right: 10px;
    }
}

.mainColumn {    
    padding: 0 20px 0px 20px;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    max-height: 100vh;
    overflow-y: auto;
    margin: 0px 0px 0px clamp(150px, 25%, 25%);
    width: 75%;
}

.saveButtonContainer {
    margin-top: auto;
    display: flex;
    margin-bottom: 0px;
    align-self: center;
    padding-top: 10px;
}

.selectComponent {
    margin: 10px 10px 10px 0px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 8px;    
    padding-left: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60px;
}

.selectMenuOverlayListComponent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px -20px;
}

.selectMenuOverlayList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px -20px;
}

.selectMenuOverlayListItem {
    padding: 10px 10px 10px 0px;
}

.fieldRow {
    display: flex;
    align-items: center;
    margin-top: 10px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;    
    
    height: 60px;
    &[app-field-row-has-error="error"] {
        border: 1px solid var(--app-color-red-error);
    }
}

.fieldRowContent {
    display: flex;
    width: 70%;
    flex-direction: row;
    padding: 3px;

    &[visible="hidden"] {
        display: none;
    }
}
.fieldRowControls {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: var(--app-color-white-lightest);    
    width: 10%;
}

.typeText {
    font-size: 10px;
    width: 20%;
}
.fieldRowImage {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--app-color-white-dark);

    img {
        width: 20px;
        height: 20px;
        filter: invert(27%) sepia(0%) saturate(41%) hue-rotate(180deg) brightness(59%) contrast(81%);
    }
}



.fieldTypeInput {
    ::placeholder {
        font-family: var(--app-text-main-font);
        font-size: 1rem;
        color: var(--app-color-red-error);
    }

    outline: none;
    border: none;
    padding-left: 10px;
    border: 0px;
    width: 100%;
    height: 37px;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-dark);
}

.customFileUpload {
    outline: none;
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    color: var(--app-color-gray-dark);

    &:hover {
        background-color: #f1f1f1;
    }
}

.filePreview {
    margin: 0 10px;
    display: flex;
    align-items: center;
}

.click-info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0;
    width: 100%;
    border: 1px dashed var(--app-color-gray-lighter);
    border-radius: 8px;

    margin-top: 20px;
    font-family: var(--app-text-main-font);
    color: var(--app-color-gray-light);
    text-align: center;
    font-size: 1rem;
}

.errorContainer {
    font-family: var(--app-text-main-font);
    font-size: 12px;
    color: var(--app-color-red-error);
    white-space: pre;
    word-break: break-all;
}

.warningContainer {
    font-family: var(--app-text-main-font);
    font-size: 12px;
    color: var(--app-color-warning-default);
    text-align: left;
}

.multiSelectContainer {
    width: 100%;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
}

.multiselectComponent {
    width: 100%;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
}

.multiSelectSearchInput {
    margin-left: 10px;
}

.multiSelectMenuOverlayList {
    width: 100%;
    top: calc(100% - 13px);
}

.multiSelectMenuOverlayListItem {
    padding: 10px;
    display: flex;
    align-items: center;
}

.peoplePositionsSelect {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.peoplePositionsSelectInput {
    //display: none;
    padding-left: 20px;
    &::placeholder{
        color:var(--app-color-gray-dark);
    }
}

.peoplePositionsSelectMenuOverlay {
    display: flex;
    flex-direction: column;
    margin: 5px auto;
    max-height: 250px;
    width: auto;
    min-width: 100%;
    top: 100%;
}

.peoplePositionsSelectMenuOverlayItem {
    display: flex;

    align-items: center;
    padding: 10px;
    padding-left: 40px;
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
}


.selectedFieldsContainer {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    text-align: left;
    color: var(--app-color-gray-light);
    word-break: break-all;
}


.manualContainer {    
    align-items: center;
    margin-top: 10px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    p {
        margin: 10px 10px 10px 20px;
        text-align: left;
        color: var(--app-color-gray-light);
        cursor: pointer;
    }
}

.elementTextInput {
    height: 57px;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    width: 100%;
    padding-left: 20px;
    outline: none;
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    color: var(--app-color-gray-dark);
}

.fieldType {
    
    font-family: var(--app-text-main-font);
    font-size: 14px;
    color: var(--app-color-gray-dark);
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;
    position: relative;
}

.controls2 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 10px;
}

