@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');
:root {
  --app-text-input-font-family: var(--app-text-main-font);
  --processmaker-table-th-background-color:  rgb(17, 116, 116);
}
html {
  margin: 0;
  width: 100%;
  height: 100%;
}
.grey-svg-image-filter {
  filter: invert(27%) sepia(0%) saturate(41%) hue-rotate(180deg) brightness(59%) contrast(81%);
}
.error-message {
  color: #eb5757;
  font-size: 12px;
  font-weight: 600;
  font-family: "Quicksand-Regular", serif;
}
body {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Roboto",-apple-system, BlinkMacSystemFont, "Segoe UI",  "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.5px;
}
body>div {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: "Poppins";
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid #4a4a4a;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}
.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  /* 1px solid currentColor; */
  -webkit-text-fill-color: currentColor;
  box-shadow: 0 0 0px 1000px white inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input {
  outline: none;
}
.react-tel-input {
  outline: none;
  border: none;
}
.react-tel-input:active,
  .react-tel-input:focus,
  .react-tel-input:focus-within,
  .react-tel-input.focus-visible {
    outline: none;
    border: none;
  }
.react-tel-input:active,
  .react-tel-input:focus,
  .react-tel-input:focus-within,
  .react-tel-input:focus-visible {
    outline: none;
    border: none;
  }
.react-tel-input .selected-flag .flag {
  transform: scale(1.5);
  margin-left: 10px;
}
.react-tel-input .selected-flag.open:before {
  content: initial;
  display: none;
}
.react-tel-input .flag-dropdown {
  background-color: transparent;
  border: none;
}
.toggle.react-toggle--checked .react-toggle-track {
  background-color: #4a4a4a;
  background-color: var(--app-color-gray-dark);
}
.toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #333333;
  background-color: var(--app-color-gray-selected);
}
.toggle.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #333333;
  background-color: var(--app-color-gray-selected);
}
.react-toggle-track {
  background-color: #9b9b9b;
  background-color: var(--app-color-gray-light);
}
.toggle.react-toggle--checked .react-toggle-thumb {
  border-color: #4a4a4a;
  border-color: var(--app-color-gray-dark);
}
.toggle.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 1px 1px #4a4a4a;
  box-shadow: 0px 0px 1px 1px var(--app-color-gray-dark);
}
.toggle.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 2px 2px #4a4a4a;
  box-shadow: 0px 0px 2px 2px var(--app-color-gray-dark);
}
[data-reach-dialog-overlay] {
  // background-color: #ffffff11;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  z-index: 50;
  z-index: var(--app-modal-backdrop-zIndex);
}
[data-reach-dialog-content] {
  /* width: 20vw;*/
  margin: 10vh auto;
  background: #ffffff;
  background: var(--app-color-white-lightest);
  padding: 2rem;
  outline: none;
}
.blured {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  -moz-filter: blur(5px);
  -ms-filter: blur(5px);
  -o-filter: blur(5px);
  filter: blur(5px);
}
.safariBlured {
  background: #6d6d6d98;
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .safariBlured {
    background: #6d6d6d98; // Make sure this color has an opacity of less than 1
    animation: changeWidth 10s linear infinite;
    //  animation: changeHeight 10s linear infinite;

    //    backdrop-filter: blur(8px); // This be the blur
  }
}
//@import "basics/base.scss";  
:root {
  --reach-dialog: 1;
  /* fonts */
  --app-text-main-font: "Montserrat";
  --app-text-mobile-font: "Montserrat";
  --app-text-font-sports: "Orbitron";
  // --app-text-main-font: "Noto Sans";
  // --app-text-minor-font: "Noto Sans";
  // --app-text-open-sans-font: "Open Sans";
  // --app-text-menu-font: "Inconsolata";
}
/*
 * #VIEWPORT VARIABLES
 *
**********************************************************/
/*
 * Media Query viewport variables
 *
 * Uses Operator `*-lt`(lower-then)` or `*-gt`(greater-then) as suffix.
*/
:root {
    --app-width-side-menu-short: 110px;
    --app-width-side-menu-wide: 230px;
   
   
    --app-pageheader-height: 120px;
    --app-teaser-search-height: 188px;
  }
@media (max-width: 1023px) {
:root {
      --app-pageheader-height: 97px;
  }
    }
/* #SPACINGS (Base is 8px) */
/**********************************************************/
:root {
/* spacings */
--app-spacing-00: 0;
--app-spacing-01: 8px;
--app-spacing-02: 16px;
--app-spacing-03: 24px;
--app-spacing-04: 32px;
--app-spacing-05: 40px;
--app-spacing-06: 48px;
--app-spacing-07: 56px;
--app-spacing-08: 64px;
--app-spacing-10: 80px;
--app-spacing-12: 96px;
--app-spacing-16: 128px;
--app-spacing-20: 160px;

  /*  General page spacing left and right */
  --app-page-spacing-x: var(--app-spacing-04);
}
@media (max-width: 768px) {
:root {
    --app-page-spacing-x: var(--app-spacing-03);
}
  }
:root {

  /* Components Spacings */
  --app-slider-gap-x: var(--app-spacing-04);
}
@media (max-width: 768px) {
:root {
    --app-slider-gap-x: var(--app-spacing-02);
}
  }
:root {
  /* colors */
  --app-color-gray-dark: #4a4a4a;
  --app-color-gray-light: #9b9b9b;
  --app-color-gray-light_nuance: #979797;
  --app-color-gray-light_nuance2: #828282;
  --app-color-gray-lighter: #d2d2d2;
  --app-color-gray-lightest: rgb(240 240 240);

  --app-color-gray-lighter_nuance: #e0e0e0;
  --app-color-gray-lighter_nuance1: #f1f1f1;
  --app-color-gray-lighter_nuance2: #f2f2f2;
  --app-color-gray-lighter_nuance3: #d8d8d8;
  --app-color-gray-lighter_nuance4: #d1d1d1;
  --app-color-gray-lighter_nuance5: #6e6b6b;
  --app-color-gray-unselected: #bdbdbd;

  --app-color-gray-selected: #333333;

  --app-color-white-lightest: #ffffff;

  --app-color-white-dark: #f1f1f1;
  --app-color-red-error: #df1111;;
  --app-color-papaya: rgb(240, 150, 5);
  
  --app-color-warning-default: #ffa008;
  --app-color-danger-default: #dc3535;
  --app-color-success-default: #28a745;
  --app-color-info-default: #353440;

  --app-color-error: #e03030;
  --app-color-success: #30e04d;
  --app-color-attention: #d4e030;
  --app-color-notice: #3042e0;

  ///temp defined as example...update usage  in project if removed

  --app-color-bg-default: #f1f1f1;
  --app-color-bg-dark-transparent: #353440cc;
  --app-color-bg-dark: #353440;
  --app-color-bg-light: #ffffff;
  --app-color-bg-light-transparent: #ffffffcc;

  --app-color-text-default: #000;
  --app-color-text-dark: #353440;
  --app-color-text-darker: #4a4a4a;
  --app-color-text-light: #949494;
  --app-color-text-placeholder-light: #949494;
  --app-color-text-placeholder-dark: #949494;


  --app-color-blue: rgb(83, 159, 234);
  --app-color-dark-green: #00455F;
}
:root { 
  --app-page-menu-user-zIndex: 100; 
  --app-modal-backdrop-zIndex: 50; 
  --app-menu-hoover-zIndex:150;
}