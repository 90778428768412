.host {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    border-radius: 4px;
    width: 100%;
}
.customInputContainer {
    display: flex;
    flex: 1;
}
.customInput {
    text-align: center;
    outline:none;
    border: none;
    height: auto;
    width: 100%;
}
.errorGeneralText {
    margin: 10px 0 0 0;
    text-align: left;
    color: var(--app-color-error);
    font-family: var(--app-text-main-font);
    font-size: 0.8rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 0.8rem;
}