/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  margin-top: auto;
  margin-bottom: auto;
  transition: transform 0.6s ease;
  width: 16px;
  height: 16px;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg);
}
.rotate90 {
  transform: rotate(90deg);
}

.headerTopBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  font-family: 'Montserrat';
  letter-spacing: -1px;
  font-size: 20px;
  font-weight: normal;
  color: #424242;
  user-select: none;
}

.headerTopBarContainerII {
  display: flex;
  flex-direction: row;
  font-family: 'Montserrat';
  letter-spacing: -1px;
  font-size: 20px;
  font-weight: normal;
  color: #424242;
  user-select: none;
}
