#change-password-container {
  background-color: var(--app-color-white-lightest);
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  position: relative;
  flex: auto;

  @media only screen and (max-width: 1068px) {
    .main-container {
      flex-direction: column;
    }
  }

  @media only screen and (min-width: 1068px) {
    .main-container {
      flex-direction: row;
    }
  }
  .message-container{
    display: flex;
    flex-direction: column;
    width: 400px;
    justify-content: space-evenly;
    align-items: center;
    font-size: 1em;
    margin-top: 30px;
    border-radius: 3px;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 2px;
  }
  .first-container {
    margin: 0 0 0 30px;
    width: 534px;
    align-self: flex-start;
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--app-color-white-lightest);
    .logo-wrapper{
      align-self: flex-start;
      margin-top: 15px;
      margin-bottom: 15%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  .second-container {
    background-color: var(--app-color-white-lightest);
    width: 50%;
    padding-left: 40px;

    width: 534px;
    height: 100%;
    flex: auto;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  .second-container-content {
    margin-left: 70px;
  }

  .left-20 {
    padding-left: 15%;
  }

  .logo-container {
    display: flex;
    padding-top: 30px;
  }

  .logo-size {
    width: 25px;
    height: 25px;
  }

  .main-form {
    position: relative;
    top: 20vh;
  }

  .input-style {
    font-family: var(--app-text-main-font), serif !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: var(--app-color-gray-dark) !important;
    width: 400px;
  }

  .login-btn {
    height: 50px;
    width: 100px;
    border-radius: 8px;
    border: 0;
    background-color: var(--app-color-gray-dark);
  }

  .btn-primary:hover {
    background-color: #333333;
  }

  input[type="text"] {
    padding: 20px;
  }

  input[type="password"] {
    padding: 20px;
  }

  .logo-text-style {
    font-family: var(--app-text-main-font), serif;
    font-size: 24px;
    line-height: 24px;
    text-align: left;
    color: var(--app-color-gray-dark);
  }

  .login-text-style {
    font-family: var(--app-text-main-font);
    font-size: 32px;
    line-height: 40px;
    text-align: left;
    color: var(--app-color-gray-dark);
  }

  .alert {
    width: 350px !important;
    margin-top: 30px;
  }

  .terms-and-cond-text-style {
    font-family: var(--app-text-main-font), serif;
    font-size: 16px;
    line-height: 22px;
    color: var(--app-color-gray-light);
  }

  .remember-me {
    font-family: var(--app-text-main-font), serif;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    padding-left: 10px;
    /* padding-top: 2px; */
    min-width: 24px;
    min-height: 24px;
  }

  .bold {
    color: var(--app-color-gray-dark);
  }

  .login-btn span {
    font-family: var(--app-text-main-font), serif;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
  }

  @media (max-width: 836px) {
    .main-container {
      background-color: var(--app-color-white-lightest);
    }
    .need-help-main-container {
      background-color: #f1f1f1 !important;
    }
    .left-20 {
      padding-left: 2%;
    }
  }

  .showHideButton .MuiTypography-body1 {
    font-family: var(--app-text-main-font), serif;
    font-size: 16px;
    color: var(--app-color-gray-light);
    cursor: pointer;
  }
  #my-input {
    font-family: var(--app-text-main-font), serif;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    color: black;
  }
}
.checkbox {
  display: flex;
  flex-direction: row;
  flex: 0;
  justify-content: flex-start;
  align-items: center;
  margin: 0.75ex 0 0.75ex 0;
}
.checkbox [data-reach-custom-checkbox-container][data-state="checked"]:after {
  display: block;
  width: calc(45% - 2px);
  height: calc(90% - 4px);
  border: solid #fff;
  border-width: 0 0.25em 0.25em 0;
  -webkit-transform: translateY(-65%) translateX(-50%) rotate(45deg);
  transform: translateY(-65%) translateX(-50%) rotate(45deg);
}
.labelclassname {
  flex-shrink: 1;
  flex-grow: 1;
  cursor: pointer;
  word-wrap: break-word;
  max-width: 100%;
  min-width: 200px;
  color: var(--app-color-gray-light);
  font-family: var(--app-text-main-font);
  font-size: 1.1em;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75ex;
}
