.firstDiv_III {
    position: relative;
    display: flex;
    height: 96%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 2% 10% 2% 10%;
    min-width: 774px;
}

.logoImg_III {
    position: absolute;
    left: -60px;
    top: 7px;
    right: 0;
    bottom: 0;
    width: 40px;
    height: 40px;
}


.headerContainer_III {
    margin: 0px 0 0px 0;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: stretch;
}
.header_III {
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 2em;
    //  line-height: 2em;
    margin: 0;
    padding: 0 0 0 10px;
    text-align: left;
}

.subheadingContainer_III {
    align-self: stretch;
    font-family: var(--app-text-main-font);
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 16px;
    padding-left: 10px;
    justify-content: center;
    align-items: center;

    .padH50 {
        padding: 0 50px 0 50px !important;
    }
    .centered {
        text-align: "center";
        justify-content: center;
        align-items: center;
    }

    p {
        margin: 0px 0 16px 0;
        color: var(--app-color-gray-dark);
        font-family: var(--app-text-main-font);
        font-size: 1rem;
        line-height: 1.3rem;
        text-align: left;
        padding: 0 0 0 0;
    }
}

.justifiedLeft_III {
    text-align: left;
    justify-content: flex-start;
    align-items: center;
}


.subheading_III {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: var(--app-color-gray-light);
    padding-bottom: 15px;
}
.updateCompanyDataFieldsContainer_III{
    min-width: 600px;
    padding: 0px 30px;
}

.buttonWrapper_III {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin: 40px 0 0 0;
}
.backToPreviousButtonContainer_III {
    cursor: pointer;
    align-self: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
        opacity: 0.5;
        color: var(--app-color-gray-light);
        width: 22px;
        height: 22px;
        margin-right: 10px;
    }
    p {
        color: var(--app-color-gray-light);
        font-family: var(--app-text-main-font);
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
    }
}

.buttonContainer_III {
    flex: 1;
    position: relative;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.skipThisStepContainer_III {
    cursor: pointer;
    align-self: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.skipThisStepText_III {
    color: var(--app-color-gray-light);
    font-family: var(--app-text-main-font);
    font-size: 16px;
    line-height: 1.3rem;
    min-width: 110px;
}

/* tutorial */


.tutorialWrapper_III {
    position: absolute;
    top: 1.5em;
    right: -68.5px;
    height: 62px;
    width: 137px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
}
.tutorialContainer_III {
    margin: 12px;
    cursor: pointer;
    padding: 0 10px 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.tutorialImg_III {
    width: 11.58px;
    height: 15.98px;
}
.tutorialTextContainer_III {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.tutorialText_III {
    margin: 0 0 0 0;
    height: 24px;
    width: 61px;
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    line-height: 1.3rem;
    text-align: center;
}
.tutorialDuration_III {
    margin: 0 0 0 0;
    height: 24px;
    width: 61px;
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
}
.tutorialStyle_III {
    z-index: 1;
    cursor: grab;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #4a4a4a75;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.reactPlayerStyle_III {
    border-radius: 16px;
    overflow: hidden;
}

.new_process_container {
    position: relative;
    display: flex;
    height: 96%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 2% 100px 2% 100px;
    min-width: 774px;
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;
    position: relative;
}

.controls2 {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 10px;
}