.roleInformationWrapper {
    margin: 0 0px 0 10px;
    //position: relative;
    //width: calc(100vw - 10px);
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
     //background-color: red;
    overflow: auto;
}
.leftAside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;

    //height: 100vh;
    padding: 0 20px 0 10px;
    justify-content: space-between;
    // box-shadow: 0 0 40px 0 #0000001a;
    position: fixed;
    left: 0px;
    //width: 200px;
    overflow-y: auto;
}
.navigationalContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.backButton {
    cursor: pointer;
    outline: none;
    background-color: transparent;
    border: none;
    svg {
        width: 24px;
        height: 24px;
    }
}
.positionNameContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    max-width: 150px;
    flex: 1;
    margin: 0 0 0 10px;
    p {
        color: var(--app-color-gray-dark);
        font-family: var(--app-text-main-font);
        font-size: 1.2em;
        font-weight: 500;
        letter-spacing: 0;
    }
}
.summaryContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: 50px;
    margin-bottom: -20px;
    flex: 1;
}
.summaryLinkContainer {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}
.summaryLink {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: transparent;
    &[app-is-selected="true"] {
        background-color: var(--app-color-gray-dark);
    }
}
.summaryLinkText {
    margin-left: 10px;
    color: var(--app-color-gray-light);
    font-family: var(--app-text-main-font);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 20px;
    &[app-sum-is-selected="true"] {
        color: var(--app-color-gray-dark);
    }
}
.optionsContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    align-content: center;
    padding-top: 50px;
    margin: 10px -20px 0 0px;
}

.positionOptionButton {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img {
        width: 27px;
        height: 24px;
        margin-right: 10px;
    }
    svg {
        width: 27px;
        height: 24px;
        margin-right: 10px;
        color: var(--app-color-gray-light);
    }
    p {
        color: var(--app-color-gray-dark);
        font-family: var(--app-text-main-font);
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 20px;
        text-align: left;
    }
}

.saveButtonContainer {
    flex: 1;
    position: relative;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.duplicateModalContainer {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    margin: 2% 7% 2% 7%;
}
.duplicatingPositionContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.duplicatingPosition_title {
    height: 40px;
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
}
.duplicatingPosition_description {
    margin: 10px;
    padding: 0;
}
.duplicatingPosition_paragraph {
    text-align: left;
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    margin: 0;
    padding: 0;
}
.duplicatingPosition_fieldLabel {
    align-self: flex-start;
    color: var(--app-color-gray-light);
    font-family: var(--app-text-main-font);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
}
.duplicatingPosition_annotation {
    font-family: var(--app-text-main-font);
    color: var(--app-color-gray-dark);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin: 0;
    padding: 30px 0 0 0;
    text-align: left;
}
.duplicatingPosition_parent {
    margin: 10px 0px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
}
.duplicatingPosition_newName {
    margin: 10px 0px 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--app-color-gray-lighter);
    border-radius: 4px;
    height: 49px;
    padding-top: 5px;
}
.duplicatingPosition_selectPositionmoli {
    svg {
        color: var(--app-color-white-lighter);
    }
}
.duplicatingPosition_resultOperationContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.duplicatingPosition_resultContainer {
    width: 70%;
    margin: 5px 0 20px 0;
}
.duplicatingPosition_resultText {
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    padding: 0;
    margin: 0;
}
.buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.deleteModalContainer {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 2% 7% 2% 7%;
}
.deletePositionContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.deletePosition_title {
    height: 40px;
    width: 217px;
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
}
.deletePosition_description {
    align-self: flex-start;
    height: 20px;
    width: 252px;
    color: var(--app-color-gray-light);
    font-family: var(--app-text-main-font);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
}
.deletePosition_checkboxContainer {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0 10px 0;
}
.deletePosition_buttonContainer {
    svg {
        margin-left: 10px;
        padding: 1px 1px;
        width: 24px;
        height: 24px;
        color: var(--app-color-white-lighter);
    }
}

.warningDeletionContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: "100%";
}
.warningDeletion_warning {
    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    margin: 0;
    padding: 0;
}
.warningDeletion_warningAnnotation {
    margin: 10px;
    padding: 0;

    color: var(--app-color-gray-dark);
    font-family: var(--app-text-main-font);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin: 0;
    padding: 0;
}
.warningDeletion_warningInformation {
    margin-top: 24px;
    max-width: 286px;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        color: var(--app-color-gray-dark);
        font-family: var(--app-text-main-font);
        font-size: 1.1em;
        font-weight: 500;
        letter-spacing: 0;
        text-align: center;
        max-width: 400px;
    }
}
.warningDeletion_buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    svg {
        margin-left: 10px;
        padding: 1px 1px;
        width: 24px;
        height: 24px;
        color: var(--app-color-white-lighter);
    }
}
.loadingContainer {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 0 10px 0 10px;
    min-width: 250px;
    min-height: 250px;
}
.resultOperationWrapper {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin: 2% 7% 2% 7%;
}
.resultOperationContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.resultContainer {
    width: 70%;
    margin: 5px 0 20px 0;
}
.resultText {
    color: #4a4a4a;
    font-family: var(--app-text-main-font);
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    padding: 0;
    margin: 0;
}
.printableContainer {
    //padding: 0 0 0 10px;
    // margin: 0 0 0 200px;
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-height: 100vh;
}
.scrollableContainer {
    width: 98%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
}
.printArea {
    padding-left: 30px;
    width: 90%;
    max-width: 1210px;
    flex: 1;
    align-self: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
}
.roleInformationTitleText {
    align-self: flex-start;
    font-family: var(--app-text-main-font);
    font-size: 32px;
    color: var(--app-color-gray-dark);
}

.listArea {
    padding-left: 30px;
    width: 90%;
    max-width: 1210px;
    flex: 1;
    align-self: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
}
