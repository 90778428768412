.renderTitle {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-height: 30px;
  .title {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-light);
  }
}
.tooltipContainer {
  position: relative;
  flex: 0;
}
.tooltipIcon {
  outline: none;
  cursor: pointer;
  margin: 5px 0 0 20px;
  width: 18px;
  height: 18px;
}
.tooltip {
  opacity: 1 !important;
  box-shadow: 0px 0px 3px #00000036 !important;
  border-radius: 8px !important;
  max-width: 180px !important;
  word-wrap: break-word;
  hyphens: auto;
  text-align: justify;
  text-justify: inter-word;
  font-weight: 600;
}
.dinamicText {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;

  .textArea {
    box-sizing: border-box;
    height: auto;
    width: 100%;
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: #ffffff;
    font-family: var(--app-text-main-font);
    font-size: 1.1em;
    line-height: 1.3rem;
    font-weight: 500;
    resize: none;
    position: relative;
    padding: 10px 10px 10px 10px;
    max-height: 88vh;
    overflow-y: auto;
  }
  .placeholder {
    position: absolute;
    top: 35%;
    right: 0;
    left: 10px;
    user-select: none;
    pointer-events: none;
    display: block;
    color: var(--app-color-gray-light);
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    line-height: 1.3rem;
  }
}
.addListItemField {
  cursor: text;
  margin: 10px 0px 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
  .container {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: stretch;
    width: 100%;
    background-color: #ffffff;
    border-radius: 8px;
    .annotationWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .saveButton {
        padding: 0 5px 0 5px;
        cursor: pointer;
        border-radius: 4px;
        //  background-color: #f1f1f1;
        height: 32px;
        width: 79px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .saveButtonIco {
          width: 16px;
          height: 16px;
          margin: auto 10px auto 0px;
          align-self: center;
        }
        .saveButtonLabel {
          font-family: var(--app-text-main-font);
          font-size: 16px;
          color: var(--app-color-gray-dark);
          padding-right: 10px;
        }
      }
    }
    .input {
      flex: 1;
      font-family: var(--app-text-main-font);
      font-size: 1.1rem;
      line-height: 20px;
      color: var(--app-color-gray-dark);
      background-color: #ffffff;
      outline: none;
      border-radius: 8px;
      border: 0px;
      padding-left: 5px;
      &::placeholder {
        font-style: italic;
      }
    }
    .cancelButton {
      margin: 0 5px 0 25px;
      cursor: pointer;
      border-radius: 4px;
      border: 0px;
      padding: 5px;
      background-color: #f1f1f1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
.listItem {
  flex: 1;
  cursor: text;
  margin: 2px 0px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
  gap: 5px;
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    border-radius: 8px;
    .labelContainer {
      align-self: flex-start;
      display: flex;
      flex-direction: row;
      align-content: flex-start;
      justify-content: flex-start;
      align-items: flex-star;
      .label {
        align-self: flex-start;
        font-family: var(--app-text-main-font);
        font-size: 1.1rem;
        outline: none;
        border-radius: 8px;
        border: 0px;
        padding: 0;
      }
    }
    .textContainer {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: stretch;
      width: 80%;
      background-color: #ffffff;
      border-radius: 8px;
      .text {
        resize: none;
        padding: 10px 0px 10px0px;
        height: unset;
        margin: 0px 10px;
        flex: 1;
        font-family: var(--app-text-main-font);
        font-size: 1.1em;
        outline: none;
        border-radius: 8px;
        border: 0px;
        overflow-wrap: break-word;
      }
      .label {
        position: absolute;
        top: 35%;
        left: 10px;
        right: 0;
        user-select: none;
        pointer-events: none;
        display: block;
        color: var(--app-color-gray-light);
        font-family: var(--app-text-main-font);
        font-size: 1rem;
        line-height: 1.3rem;
      }
    }
    .annotationWrapper {
      flex: 0.2;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      .saveButton {
        padding: 0 5px 0 5px;
        cursor: pointer;
        border-radius: 4px;
        // background-color: #ffffff;
        height: 32px;
        width: 79px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .saveButtonIco {
          width: 16px;
          height: 16px;
          margin: auto 10px auto 0px;
          align-self: center;
        }
        .saveButtonLabel {
          font-family: var(--app-text-main-font);
          font-size: 16px;
          color: var(--app-color-gray-dark);
          padding-right: 10px;
        }
      }
      .cancelButton {
        margin: 0 5px 0 25px;
        cursor: pointer;
        border-radius: 4px;
        border: 0px;
        padding: 5px;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
.framedListEditing {
  flex: 1;
  width: 100%;
  padding: 0 2.5% 0 5px;
  border: 1px solid var(--app-color-gray-lighter);
  border-radius: 4px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    .saveButton {
      cursor: pointer;
      margin: 8px 8px 0 0;
      display: flex;
      width: 32px;
      height: 32px;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      .buttonIco {
        width: 32px;
        height: 32px;
      }
    }
  }
  .content {
    padding: 0 0 0 2.5%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    height: 80vh;
    overflow-y: auto;
  }
  .addFieldContainer {
    padding: 0 0 0 2.5%;
  }
}
.framedListView {
  flex: 1;
  width: 100%;
  padding: 0px;
  border: none;
  border-radius: 4px;
  .header {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    margin: 0;
    flex-wrap: wrap;
    overflow-y: visible;

    .text {
      box-sizing: border-box;
      width: 100%;
      border: none;
      outline: none;
      border-radius: 8px;
      background-color: #ffffff;
      color: #4a4a4a;
      font-family: var(--app-text-main-font);
      font-size: 1.1em;
      line-height: 1.3rem;
      font-weight: 500;
      resize: none;
      position: relative;
      padding: 10px 0px 10px 10px;
      margin: 0;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      word-wrap: break-word;
      hyphens: auto;
    }
  }
}
.framedTextAreaDinamicText {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
  background-color: var(--app-color-white-lightest);
  border-radius: 8px;
}
.framedTextAreaEditHost {
  flex: 1;
  width: 100%;
  padding: 0 0 0 5px;
  border: none;
  border-radius: 4px;

  &[app-edit-mode="true"] {
    border: 1px solid var(--app-color-gray-lighter);
  }
}

/* RenderAddListItem */
.renderAddListItemWrapper {
  cursor: text;
  margin: 10px 0px 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
}
.renderAddListItemInputContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
  background-color: var(--app-color-white-lightest);
  border-radius: 8px;
}
.renderAddListItemAnnotationWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.renderAddListItemSaveButton {
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0 5px 0 5px;
  border-radius: 4px;
  background-color: var(--app-color-gray-dark);
  height: 32px;
  width: 79px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    align-self: center;
    color: var(--app-color-white-lightest);
  }
  p {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-white-lightest);
    padding-right: 10px;
  }
}
.renderAddListItemInputStyle {
  outline: none;
  flex: 1;
  font-family: var(--app-text-main-font);
  font-size: 1em;
  line-height: 20px;
  color: var(--app-color-gray-dark);
  background-color: var(--app-color-white-lightest);
  border-radius: 8px;
  border: 0px;
  padding-left: 5px;
  resize: none;
  max-height: 124px;
  &::placeholder {
    font-style: italic;
    white-space: pre-line;
    position: relative;
  }
}
.renderAddListItemCancelButton {
  outline: none;
  border: none;
  cursor: pointer;
  margin: 0 5px 0 25px;
  padding: 5px;
  border-radius: 4px;
  background-color: var(--app-color-gray-dark);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
/* RenderAddListItem */

/* RenderListItem */
.renderListItemWrapper {
  cursor: text;
  margin: 3px 0px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
}
.renderListItemContainer {
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: var(--app-color-white-lightest);
  border-radius: 8px;
  padding: 5px 10px;
  &[app-variant-show-buttons="true"] {
    background-color: var(--app-color-gray-lighter_nuance4);
  }
}
.renderListItemLabelContainer {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
}
.renderListItemTextareaContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
  border-radius: 8px;
  height: 100%;
}
.renderListItemItemNumber {
  align-self: flex-start;
  font-family: var(--app-text-main-font);
  font-size: 1.1rem;
  color: var(--app-color-gray-dark);
  background-color: var(--app-color-white-lightest);
  outline: none;
  border-radius: 8px;
  border: 0px;
  padding: 0;
  &[app-variant-show-buttons="true"] {
    padding: 5px 0 0 5px;
    color: var(--app-color-gray-dark);
    background-color: var(--app-color-gray-lighter_nuance4);
  }
}
.renderListItemTextarea {
  resize: none;
  height: unset;
  flex: 1;
  font-family: var(--app-text-main-font);
  font-size: 1.1rem;
  outline: none;
  border-radius: 8px;
  border: 0px;
  overflow-wrap: break-word;
  height: auto;
  min-height: 50px;
  overflow-y: hidden;
  align-self: flex-start;
  &::placeholder{
    user-select: none;
    pointer-events: none;
    display: block;
    color: var(--app-color-gray-light);
    font-family: var(--app-text-main-font);
    font-size: 1rem;
    font-style: italic;
  }
}

.renderListItemAnnotationWrapper {
  flex: 0.2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.renderListItemSaveButton {
  outline: none;
  border: 1px solid var(--app-color-gray-lighter);
  padding: 0 5px 0 5px;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--app-color-white-lightest);
  height: 32px;
  width: 79px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    margin-top: auto;
    margin-bottom: auto;
    align-self: center;
  }
  p {
    font-family: var(--app-text-main-font);
    font-size: 16px;
    color: var(--app-color-gray-dark);
    padding-right: 10px;
  }
}
.renderListItemDeleteButton {
  outline: none;
  border: none;
  margin: 0 5px 0 10px;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
}
/* RenderListItem */
