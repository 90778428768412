
.centeredRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.centeredColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.largeCardLabelStyle {
  cursor: pointer;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  min-width: 150px; /*  height: '50px;  */
  border: 1px solid gray;
  border-radius: 10px;
  padding: 1em;
  text-align: center;
  /* marginBottom: '1rem; */
}
.smallCardLabelStyle {
  cursor: pointer;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  //min-width: 150px; /*  height: '50px;  */
  border: 1px solid gray;
  border-radius: 10px;
  padding:0 1em;
  text-align: center;
  /* marginBottom: '1rem; */

}
.smallCardLabelParagraphStyle {
  color: var(--app-color-gray-dark);
  font-family: var(--app-text-main-font);
  font-size: 1em;
  text-align: center;
  letter-spacing: 0;
//  line-height: 30px;
  padding:  10px 0 10px 0;
  margin: 0px;
}
